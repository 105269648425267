import { useState } from "react";
import axios from "axios";
import xml2js from "xml2js";
import BreadCrumb from "../Shared/BreadCrumb/BreadCrumb";
import { useTranslation } from "react-i18next";
import "./B2B.css";



function B2B() {
  
  

  const { t } = useTranslation();
  
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const [items, setItems] = useState([]);

  const [customerCode, setCustomerCode] = useState("");
  const [reducer, setReducer] = useState("%");
  const [state, setState] = useState("Tumu");

  const renderItems = items.map((item, i) => {
    return (
      <tr key={i}>
        <td>{item.musteri_ref.toString()}</td>
        <td>{item.siparis.toString()}</td>
        <td>{item.yayin.toString()}</td>
        <td>{item.sira.toString()}</td>
        <td>{item.reduktor.toString()}</td>
        <td>{item.adet.toString()}</td>
        <td>{item.teslim_tarihi.toString()}</td>
        <td>{item.durumu.toString()}</td>
        <td>{item.siparisi_acan.toString()}</td>
      </tr>
    );
  });

  const login = function () {
    let xml = `
<soap:Envelope xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/">
    <soap:Body>
        <loginKeyGonder xmlns="http://www.yr.com.tr/">
            <KullaniciAdi>${username}</KullaniciAdi>
            <Sifre>${password}</Sifre>
        </loginKeyGonder>
    </soap:Body>
</soap:Envelope>`;

    axios
      .post("https://wsdl.yr.com.tr/yilmazwebservisleri.asmx", xml, {
        headers: {
          "Content-Type": "text/xml",
        },
      })
      .then((r) => {
        let parseString = xml2js.parseString;
        parseString(r.data, function (err, result) {
          setCustomerCode(
            result["soap:Envelope"]["soap:Body"][0][
              "loginKeyGonderResponse"
            ][0]["loginKeyGonderResult"][0]["diffgr:diffgram"][0][
              "DocumentElement"
            ][0]["KullaniciBilgisi"][0]["IfsUserKod"][0]
          );
        });
      });
  };

  const data = function () {
    let xml = `
    <soap:Envelope xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/">
      <soap:Body>
        <SiparisBilgileriIFS xmlns="http://www.yr.com.tr/">
          <MusteriKodu>${customerCode}</MusteriKodu>
          <Reduktor>${reducer}</Reduktor>
          <Durumu>${state}</Durumu>
          <SecurityKey>yrApps1948*x</SecurityKey>
        </SiparisBilgileriIFS>
      </soap:Body>
    </soap:Envelope>`;

    axios
      .post("https://wsdl.yr.com.tr/yilmazwebservisleri.asmx", xml, {
        headers: {
          "Content-Type": "text/xml",
        },
      })
      .then((r) => {
        let parseString = xml2js.parseString;
        parseString(r.data, function (err, result) {
          let items = [];
          try
          {
          for (
            let i = 0;
            i <
            result["soap:Envelope"]["soap:Body"][0][
              "SiparisBilgileriIFSResponse"
            ][0]["SiparisBilgileriIFSResult"][0]["diffgr:diffgram"][0][
              "DocumentElement"
            ][0]["perinfo"].length;
            i++
          ) {
            const element =
              result["soap:Envelope"]["soap:Body"][0][
                "SiparisBilgileriIFSResponse"
              ][0]["SiparisBilgileriIFSResult"][0]["diffgr:diffgram"][0][
                "DocumentElement"
              ][0]["perinfo"][i];
            const musteri_ref = element["MUSTERI_REF"][0];
            let teslim_tarihi = element["TESLIM_TARIHI"][0];
            teslim_tarihi = teslim_tarihi.split("T")[0];
            teslim_tarihi = `${teslim_tarihi.split("-")[2]}.${
              teslim_tarihi.split("-")[1]
            }.${teslim_tarihi.split("-")[0]}`;

            items.push({
              musteri_ref: musteri_ref,
              siparis: element["SIPARIS"][0],
              yayin: element["YAYIN"][0],
              sira: element["SIRA"][0],
              reduktor: element["REDUKTOR"][0],
              adet: element["ADET"][0],
              teslim_tarihi: teslim_tarihi,
              durumu: element["DURUMU"][0],
              siparisi_acan: element["SIPARISI_ACAN"][0],
            });
          }

        } catch(e) { console.error(e); }

          setItems(items);
        });
      });
  };

  return (
  

    <>
      <BreadCrumb location={t("SIP-TAKIBI-BREAD")} />

      <div className="container-fluid" style={{margin: 'auto', maxWidth: '1170px'}}>
        {(customerCode === "" && (
          <div className="row">
            <h1 style={{marginLeft: '10px'}}>{t("BS-LOGIN")}</h1>

            <div className="d-flex flex-column">
              <div className="col-md-3 col-sm-12 align-self-center">
                <div className="form-group m-1">
                  <label className="d-flex align-self-start">{t("BS-FIL-L-ID")}</label>
                  <input
                    className="form-control"
                    value={username}
                    onChange={(event) => {
                      setUsername(event.target.value);
                    }}
                  />
                </div>

                <div className="form-group m-1">
                  <label className="d-flex align-self-start">{t("BS-FIL-L-PASS")}</label>
                  <input
                    className="form-control"
                    value={password}
                    onChange={(event) => {
                      setPassword(event.target.value);
                    }}
                  />
                </div>

                <button
                  type="button"
                  className="btn btn-primary m-1"
                  onClick={login}
                  style={{marginBottom: '10px'}}
                >
                  {t("BS-FIL-LOGIN")}
                </button>
              </div>
            </div>
          </div>
        )) || (
          <>
            <div className="row">
              <div className="d-flex flex-column">
                <div className="col-md-3 col-sm-12">
                  <div className="form-group m-1">
                    <label className="d-flex">{t("BS-FIL-REDUKTOR")}</label>
                    <input
                      className="form-control"
                      value={reducer}
                      onChange={(event) => {
                        setReducer(event.target.value);
                      }}
                    />
                  </div>

                  <div className="form-group m-1">
                    <label className="d-flex">{t("BS-FIL-S-STATUS")}</label>
                    <select
                      className="form-control"
                      onChange={(event) => {
                        setState(event.target.value);
                      }}
                    >
                      <option value={"Tumu"}>{t("BS-F-TUMU")}</option>
                      <option value={"Siparis Alindi"}>{t("BS-F-S-AL")}</option>
                      <option value={"Parca Eksik"}>{t("BS-F-P-EKSIK")}</option>
                      <option value={"Eksik Yok Planlamada"}>
                      {t("BS-F-EYP")}
                      </option>
                      <option value={"Arge"}>{t("BS-F-ARGE")}</option>
                      <option value={"Sevk Edildi"}>{t("BS-F-SE")}</option>
                      <option value={"Eksik Yok Depoda"}>
                      {t("BS-F-EYD")}
                      </option>
                      <option value={"Montajda"}>{t("BS-F-M")}</option>
                      <option value={"Fabrika Sevk Depoda"}>
                      {t("BS-F-FSD")}
                      </option>
                      <option value={"Depoda"}>{t("BS-F-DEPO")}</option>
                      <option value={"Satis"}>{t("BS-F-SATIS")}</option>
                      <option value={"Planlamada"}>{t("BS-F-PLANLAMA")}</option>
                    </select>
                  </div>

                  <button
                    type="button"
                    className="btn btn-primary m-1 d-flex align-self-start"
                    style={{marginBottom: '10px'}}
                    onClick={data}
                  >
                    {t("BS-FIL-BUTTON")}
                  </button>
                </div>
              </div>

             
            </div>
         
            <div className="row">
            <div className="col-12" style={{ marginLeft: "15px" }}>
                  {items.length} {t("BS-T-KB")}
                </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div id="hw2323" className="table-responsive">
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th>{t("BS-D-M-REF")}</th>
                        <th>{t("BS-D-M-S")}</th>
                        <th>{t("BS-D-M-Y")}</th>
                        <th>{t("BS-D-M-SIRA")}</th>
                        <th>{t("BS-D-M-R")}</th>
                        <th>{t("BS-D-M-ADET")}</th>
                        <th>{t("BS-D-M-TE")}</th>
                        <th>{t("BS-D-M-STATUS")}</th>
                        <th>{t("BS-D-M-SA")}</th>
                      </tr>
                    </thead>
                    <tbody>{renderItems}</tbody>
                  </table>
                </div>
              </div>
            </div>

          </>
        )}
      </div>
    </>
  );
}

export default B2B;
