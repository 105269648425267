import React from 'react'
import {Link} from 'react-router-dom'
import slug from 'slug'
import { useTranslation } from 'react-i18next';

function IconMenuItem(props) {
	const { t } = useTranslation();
	var translateTitle = t(props.title)
	var slugTitle = (slug(translateTitle)) + '-' + props.id
    return (
      
        <div style={{width : props.isDetail ? "100%" : "default"}} className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
			<Link to={`/faydali-bilgiler/${slugTitle}`}>

		
						<div className={"single-service-item"}>
							<div className="service-left-bg"></div>
							<div className="service-icon">
								{<img style={{height:"64px" , width:'64px'}} src={`${'/assets/images/icons/faydalibilgiler/'+slugTitle+".webp"}`}  alt=""/>}
							</div>
							<div className="service-text">
								<h4>{t(props.title)}</h4>
								<p>{t(props.subTitle)}</p>
							</div>
						</div>
			</Link>
					</div>
             
  
    )
}

export default IconMenuItem
