import React ,{useEffect , useState}from 'react'
import VideosLast from './VideosItem/VideosLast';
import BreadCrumb from "../Shared/BreadCrumb/BreadCrumb";
import Slider from "react-slick";
import './Videos.css';
import VideosList from './VideosData';
import { useDispatch } from "react-redux";
import { startVideoPlayer } from "../../pages/actions/layout";
import { useTranslation } from 'react-i18next';
import YoutubePlayer from '../YoutubePlayer';
import { fetchNewsAll, fetchYoutubeLinkAll } from '../../api/agent';
import ProductDocumentDownload from '../Products/ProductDocumentDownload/ProductDocumentDownload';
function Videos() {
  const { t } = useTranslation();
  const [news, setNews] = useState([]);
  const [youtubeLink, setYoutubeLink] = useState([]);


  useEffect(() => {
    const getAllNews = async() => {
      const allNews = await fetchNewsAll();
      var result = allNews.data;
      setNews(result);
     
    }
    const getAllYoutubeLink = async() => {
      const allYoutubeLink  = await fetchYoutubeLinkAll();
      var result = allYoutubeLink.data;
      setYoutubeLink(result);
    }
    getAllNews();
    getAllYoutubeLink();

  }, [])

  function NextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", right:'-20px' , zIndex:'9999999' }}
        onClick={onClick}
      />
    );
  }
  
  function PrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block" , left:"-40px" , zIndex:'9999999'}}
        onClick={onClick}
      />
    );
  }

    var settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        initialSlide: 0,
        nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
        responsive: [
          {
         
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
         

              
            }
          }
        ]
      };

    const dispatch = useDispatch();

    return (
        <>
           
             <BreadCrumb  location={t("NEWS-P-V-T")}/>
            <div className="secpadd">
             

            </div>
           

            <YoutubePlayer/>

            
            
<div className="secpadd pt-0">
            <h2 className="news-header"><span>{t("NEWS-P-V-T")}</span></h2>

            
            <div className="container padding-slick-container">
                
                <Slider {...settings}>
                    {[...youtubeLink].reverse().map((item,youtubeLink) => {
                        return(
                          <div key={item.id} className="padding-slick"  onClick={() =>
                            dispatch(startVideoPlayer(item.videoId))
                        }>
                            
                            <div className="slick-img">
                                <img alt={item.title} src={`https://img.youtube.com/vi/${item.videoId}/sddefault.jpg`}/>
                                <i className="far fa-play-circle fa-4x"></i>
                            </div>
                        
                            <span>{t("YR-VIDEO-"+item.id)}</span>
          
                      
                      </div>
                        )
                    })}
                </Slider>

            </div>
            </div> 
          
          
        </>
    )
}

export default Videos
