import React from 'react'
import {Link} from 'react-router-dom'
import slug from 'slug'
function NewsLast(props) {
    var slugTitle = (slug(props.title)) + '-' + props.id
    console.log(slugTitle);
    return (
        <>
                
            <Link to={`/haberler/${slugTitle}`}>
                <div  className="padding-slick">
                    <img alt={props.title} src={props.image}/>
                <span>{props.title}</span>
                
                </div>
            </Link>
           
        
        </>
    )
}

export default NewsLast
