import React from "react";

import { useTranslation } from "react-i18next";
import BreadCrumb from "../Shared/BreadCrumb/BreadCrumb";
import { Formik } from "formik";
import * as Yup from "yup";
import { fetchCustomerService } from "../../api/agent";
import { ToastContainer, toast } from "react-toastify";
import { Helmet } from "react-helmet";

function CustomerServices() {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>Müşteri Hizmetleri - YILMAZ REDÜKTÖR San. ve Tic. A.S</title>
        <link rel="canonical" href="https://yr.com.tr/musteri-hizmetleri" />
        <meta
          name="keywords"
          content="yılmaz reduktor redüktör, reduktor,dişli kutusu,disli kutusu,gearbox,gearunit,getriebe,getriebemotoren"
        />
       
      </Helmet>
      <BreadCrumb location={t("SCP-CST-SER")} />
      <div className="secpadd">
        <div className="container">
          <Formik
            initialValues={{
              firstName: "",
              lastName: "",
              email: "",
              phoneNumber: "",
              message: "",
            }}
            validationSchema={Yup.object({
              firstName: Yup.string().required(t("CUSTOMER-SERVICE-FORM-1")),
              lastName: Yup.string().required(t("CUSTOMER-SERVICE-FORM-2")),
              email: Yup.string()
                .email(t("CUSTOMER-SERVICE-FORM-6"))
                .required(t("CUSTOMER-SERVICE-FORM-3")),
              phoneNumber: Yup.string().required(t("CUSTOMER-SERVICE-FORM-4")),
              message: Yup.string().required(t("CUSTOMER-SERVICE-FORM-6")),
            })}
            onSubmit={async (values, { resetForm, setSubmitting }) => {
              console.log(values);
              var result = await fetchCustomerService(values);
              setTimeout(() => {
                setSubmitting(false);
                toast.success(
                  `${values.firstName} ${values.lastName} ${t(
                    "COMMENT-OR-CLAIM-TOAST"
                  )}`,
                  {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  }
                );
                resetForm();
              }, 2000);
            }}
            render={(props) => (
              <div class="form">
                <div class="contact-info">
                  <h3 class="title">{t("MUS-HIZ-CON")}</h3>
                  <p className="contact-info-text">{t("MUS-HIZ-DESC")}</p>
                  <div class="info">
                    <div class="information">
                      <i class="fas fa-phone-alt fa-2x icon-contact"></i>
                      <p>{t("MUS-HIZ-TEL")}</p>
                    </div>
                    <div class="information">
                      <i class="fas fa-envelope fa-2x icon-contact"></i>
                      <p>{t("MUS-HIZ-EMAIL")}</p>
                    </div>
                    <div>
                      <p class="mail-text">{t("MUS-HIZ-DESC-2")}</p>
                    </div>
                  </div>
                </div>
                <div class="contact-form">
                  <form
                    class="services-contact-form"
                    onSubmit={props.handleSubmit}
                  >
                    <h3 class="title">{t("CONTACT-FORM-1")}</h3>
                    <div class="input-container">
                      <input
                        id="firstName"
                        name="firstName"
                        value={props.values.firstName}
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        autocomplete="off"
                        placeholder=" "
                        type="text"
                        class="form_input"
                      />
                      <label class="form_label" htmlFor="">
                        {t("CONTACT-FORM-2")}
                      </label>
                    </div>

                    <div class="input-container">
                      <input
                        id="lastName"
                        name="lastName"
                        value={props.values.lastName}
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        autocomplete="off"
                        placeholder=" "
                        type="text"
                        class="form_input"
                      />
                      <label class="form_label" htmlFor="">
                        {t("CONTACT-FORM-3")}
                      </label>
                    </div>
                    <div class="input-container">
                      <input
                        id="email"
                        name="email"
                        value={props.values.email}
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        autocomplete="off"
                        placeholder=" "
                        type="email"
                        class="form_input"
                      />
                      <label class="form_label" htmlFor="">
                        {t("CONTACT-FORM-4")}
                      </label>
                    </div>
                    <div class="input-container">
                      <input
                        id="phoneNumber"
                        name="phoneNumber"
                        value={props.values.phoneNumber}
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        autocomplete="off"
                        placeholder=" "
                        type="tel"
                        class="form_input"
                      />
                      <label class="form_label" htmlFor="">
                        {t("CONTACT-FORM-5")}
                      </label>
                    </div>

                    <div class="input-container textarea">
                      <textarea
                        id="message"
                        name="message"
                        value={props.values.message}
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        placeholder=" "
                        class="form_input form_textarea"
                      />
                      <label class="form_label form_label_textarea" htmlFor="">
                        {t("CONTACT-FORM-6")}
                      </label>
                    </div>

                    <div>
                      <span
                        style={{
                          color: "red",
                          display: "block",
                          marginBottom: "10px",
                        }}
                      >
                        {props.touched.firstName ? props.errors.firstName : ""}
                      </span>
                      <span
                        style={{
                          color: "red",
                          display: "block",
                          marginBottom: "10px",
                        }}
                      >
                        {props.touched.lastName ? props.errors.lastName : ""}
                      </span>

                      <span
                        style={{
                          color: "red",
                          display: "block",
                          marginBottom: "10px",
                        }}
                      >
                        {props.touched.email ? props.errors.email : ""}
                      </span>

                      <span
                        style={{
                          color: "red",
                          display: "block",
                          marginBottom: "10px",
                        }}
                      >
                        {props.touched.phoneNumber
                          ? props.errors.phoneNumber
                          : ""}
                      </span>

                      <span
                        style={{
                          color: "red",
                          display: "block",
                          marginBottom: "10px",
                        }}
                      >
                        {props.touched.message ? props.errors.message : ""}
                      </span>
                    </div>
                    <input
                      disabled={!props.dirty || props.isSubmitting}
                      type="submit"
                      value={t("CONTACT-FORM-BTN")}
                      class="btn-contact"
                    />
                  </form>
                </div>
              </div>
            )}
          />
        </div>
      </div>

      <ToastContainer />
    </>
  );
}

export default CustomerServices;
