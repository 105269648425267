import React from "react";
import NavbarM from "./NavbarMobileFactory/NavbarM";
import NavbarWeb from './NavbarWeb'
import { useTranslation } from "react-i18next";

const Navbar = (props) =>  {
  const { t } = useTranslation();
  return (
    <div
    id="page"
    className="page-wrapper header-sticky header-transparent header-v3"
  >
      {/* <NavbarMobile/> */}
      <NavbarWeb/>
      <NavbarM/>
      <div id="off-canvas-layer" className="off-canvas-layer"></div>
    </div>
  );
}

export default Navbar;
