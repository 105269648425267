import React ,{useEffect , useState} from 'react'
import IconMenuItem from './IconMenuItem/IconMenuItem'
import Slider from "react-slick";

import { useTranslation } from 'react-i18next';
import './IconMenu.css'
import { useMediaPredicate } from "react-media-hook";

import { fetchTipsheets } from '../../../api/agent';
function IconMenu() {
  const { t } = useTranslation();
  const isMobile = useMediaPredicate("(max-width: 479px)"); // boyutlar düzenlenebilir

    
  const [tipSheets, setTipSheets] = useState([]);

  useEffect(() => {
    const getTipSheets = async() => {
      const allTipSheets = await fetchTipsheets();
      var result = allTipSheets.data;
      setTipSheets(result);
    }
    getTipSheets();
  }, [])


  
function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", right:'-30px' , zIndex:'9999999' }}
      onClick={onClick}
    />
  );
}

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block" , left:"-50px" , zIndex:'9999999'}}
      onClick={onClick}
    />
  );
}

  
function NextArrowMobile(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", right:'-30px' , zIndex:'9999999' }}
      onClick={onClick}
    />
  );
}

function PrevArrowMobile(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block" , left:"-50px" , zIndex:'9999999'}}
      onClick={onClick}
    />
  );
}





var settingsMobile = {
  dots: true,
  nextArrow: <NextArrowMobile />,
  prevArrow: <PrevArrowMobile />,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
};

  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    rows: 2,
      slidesPerRow: 3,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
    slidesToScroll: 1,
    initialSlide: 0,
   
    responsive: [
      {
     
        breakpoint: 1024,
        settings: {
      
          slidesToScroll: 1,
          rows:2,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          fade : true,
          
        }
      }
    ]
  };

    return (
 
        <section className="our-services rotated-bg">
        <div className="container">
            <div className="sec-title">
                <h2 className="center">{t("HP-WHY-T")}</h2>
            
            </div>
            <div className="row clearfix">
                
            {isMobile ? <>
                <Slider {...settingsMobile}>

      
  {tipSheets.map((data,key)=>{
      return(
          
          <IconMenuItem isDetail={false} key={key} id={data.id} icon={data.imagePath} title={(data.title)} subTitle={t(data.subTitle)} />
      )
  })}
          </Slider>



            
            
            </> : <>
            
            <Slider {...settings}>

     
{tipSheets.map((data,key)=>{
    return(
        
        <IconMenuItem isDetail={false} key={key} id={data.id} icon={data.imagePath} title={(data.title)} subTitle={t(data.subTitle)} />
    )
})}
         </Slider>


            
            </>}
             
            
            
            
          </div>
        </div>
      </section>
    )
}

export default IconMenu;


/*

/* 
<div class="feat bg-gray pt-5 pb-5">
    <div class="container">
      <div class="row">
        <div class="section-head col-sm-12">
          <h4><span>Why Choose</span> Me?</h4>
          <p>Lorem Ipsum is simply dummy text of the printing and type setting industry. Lorem Ipsum has been the industry's<br>standard dummy text ever since the 1500s, when an unknown book.</p>
        </div>
        <div class="col-lg-4 col-sm-6">
          <div class="item"> <span class="icon feature_box_col_one"><i class="fa fa-globe"></i></span>
            <h6>Modern Design</h6>
            <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor Aenean massa.</p>
          </div>
        </div>
        
        
        
        
        
      </div>
    </div>
  </div>


  
            
*/








