import React from "react";
import CardItem from "./CarItem/CardItem";
import cardData from "./CardData";

import { useMediaPredicate } from "react-media-hook";
import { useTranslation } from 'react-i18next';
import Slider from "react-slick";

function Card() {
  const isMobile = useMediaPredicate("(max-width: 479px)"); // boyutlar düzenlenebilir
  const { t } = useTranslation();

  var settingsMobile = {
    dots: true,
    nextArrow: <NextArrowMobile />,
    prevArrow: <PrevArrowMobile />,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1
  }

  var settings = {
    dots: true,
      fade: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1
  };

  function NextArrowMobile(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "none", right:'-10px' , zIndex:'9999999' }}
        onClick={onClick}
      />
    );
  }
  
  function PrevArrowMobile(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "none" , left:"-20px" , zIndex:'9999999'}}
        onClick={onClick}
      />
    );
  }

  return (
    
    <div className="servicessec secpadd">
      <div className="container">
        {!isMobile &&
          cardData.map((data, key) => {
            return (
              <CardItem
                key={key}
                image={data.image}
                link=  {data.link}
                title={t(data.title)}
                text={t(data.text)}
              />
            );
          })}

        {isMobile && (
          <Slider {...settingsMobile}>
          
            {" "}
            {cardData.map((data, key) => {
              return (
                <CardItem
                  key={key}
                  image={data.image}
                  link=  {data.link}
                  title={t(data.title)}
                  text={t(data.text)}
                />
              );
            })}
          </Slider>
        )}
      </div>
    </div>
  );
}

export default Card;
