import React , {useState ,useEffect} from "react";
import '../ProductDocumentDownload/ProductDocumentDownload.css';
import { useTranslation } from 'react-i18next';
import { fetchDocumentShortCuts, fetchLanguages } from "../../../api/agent";
import Select from "react-select";
import Swal from "sweetalert2";
import './CustomSweetAlert.css'
const ProductDocumentDownload = ({id , type}) => {
  const { t } = useTranslation();

  const [productDocumentDownloads, setProductDocumentDownloads] = useState([]);
  const [languages , setLanguages] = useState([]);
  //const [selectedOption, setSelectedOption] = useState({label: t("İngilizce") , value: "en-US"});
  const [selectedOption, setSelectedOption] = useState({label : '' , value : localStorage.getItem('language')});



  // const { language, setLanguage  } = useLanguage();


  function refreshPage() {
    window.location.reload(false);
  }

  
  const style = {
    control: (base, state) => ({
      ...base,
      border: "1px solid #761952",
      boxShadow: "none",
      "&:hover": {
        border: "1px solid #761952"
      }
    }),
    option: (styles) => {
     
      return {
        ...styles,
        color: "black"
      };
    }
  };
  useEffect(() => {
    const getProductDocumentDownloads = async() => {
      const allProductDocumentDownloads = await fetchDocumentShortCuts(id,selectedOption.value,"DOC");
      var result = allProductDocumentDownloads.data;
      setProductDocumentDownloads(result);
    }
    const getLanguages = async() => {
      const allLanguages = await fetchLanguages();
      var result = allLanguages.data;
      setLanguages(result);
    
    }
    getProductDocumentDownloads();
    getLanguages();
  }, [id,selectedOption.value])


  const filtered = languages.filter(x=> x.name != "CODE" ).map(item => {
   
    return {
      
        label : t(item.name),
        value : item.code
    }
  })
  


  const handleFileDownload = (file) => {
    return (
     
      Swal.fire({
        title: t('FILE-SIZE-LARGE'),
        customClass : 'swal-wide',
        icon: 'warning',
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText:
        `<a class="documentDownloadHigh" href=${file} target="_blank">${t("FILE-BTN-DOWNLOAD")}</a>`,
        confirmButtonColor : 'rgb(99, 101, 106)',
        cancelButtonText:
          t('FILE-BTN-CANCEL'),
        cancelButtonColor : "#770750"
      
      })

    )
  }
 

   
    return (
        <div id="documentDownload"  className="secpadd" style={{background: type == 1 ? "white" : "#f1f1f1"}}>

        <div  className="container tableOverFlow">

       

     
      
        
        <h2  className="inner-header-sec">{t("G-DOC-DOW")}</h2>
        <div style={{width:'30%' , display:'flex' , flexDirection:'column' , margin:'auto'}}>
        <Select
        placeholder={localStorage.getItem('language')=== 'tr-TR' ? t("LANG-TR") : localStorage.getItem('language')=== 'en-US' ? t("LANG-EN") :  localStorage.getItem('language')=== 'de-DE' ? t("LANG-DE") : localStorage.getItem('language')=== 'fr-FR' ? t("LANG-FR") : t("LANG-EN")}
          //defaultValue={selectedOption.}
          onChange={setSelectedOption}
          options={filtered}
          styles={style}
          theme={theme => ({
            ...theme,
            borderRadius: 0,
            colors: {
              ...theme.colors,
              primary25: 'rgb(190, 5, 126)',
              primary: '#63656a',
            },
          })}
          
       
          
        
        />

</div>
        <table className="responstable">


          <thead>
          <tr>
            <th></th>
            <th style={{width : '30%'}}>{t("G-DOC-NAME")}</th>
            <th style={{width : '45%'}}>{t("G-DOC-DESC")}</th>
            <th style={{width : '15%'}}>{t("G-DOC-VER")}</th>
            <th style={{width : '10%'}}>{t("G-DOC-SIZE")}</th>
          </tr>
          </thead>
    
          

          <tbody> 
            
              
          
              {productDocumentDownloads.map((data,key)=>{
               
                var fileSize = data.fileSize;
                var fileSizeSplit = fileSize.split("MB");
                var fileSizeNumber = fileSizeSplit[0].trim();
            
               return(
               <tr>
                    {parseFloat(fileSizeNumber) > 100 ? <><td><button onClick={() => handleFileDownload(data.fileName)} className="download-button"><i className="fas fa-download"></i></button></td></> : <>  <td><a href={data.fileName} target="_blank" className="download-button"><i className="fas fa-download"></i></a></td></>}
                  
                
                
                    <td>{data.description}</td>
                    <td>{data.documentDescription}</td>
                    <td>{data.ver}</td>
                    <td>{data.fileSize}</td>
                    
              </tr>)
              })}
              
              


          </tbody>
       
        </table>
              </div>
              </div>
        
    )
}

export default ProductDocumentDownload;