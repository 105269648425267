import React from "react";
import BoxItem from "./BoxItem/BoxItem";
import BoxData from "./BoxData";
import { motion } from "framer-motion";
import { useTranslation } from 'react-i18next';
function Box() {
  const { t } = useTranslation();

  const boxVariants = {
    hidden: { opacity: 0, y: 25 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration:0.6,delay:1},
    },
  };
  return (
    <motion.div
      variants={boxVariants}
      initial="hidden"
      animate="visible"
      className="container2"
    >
      {BoxData.map((data, key) => {
        return (
          <BoxItem
            key={key}
            icon={data.icon}
            header={t(data.header)}
            text={t(data.text)}
            path={data.path}
          />
        );
      })}
    </motion.div>
  );
}

export default Box;
