import React, { useState } from "react";
import "./NavbarProduct.css";
import NavbarCategory from "./NavbarCategory/NavbarCategory";
import navbarProducts from './navbarProducts'
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
function NavbarMenu() {
  const { t } = useTranslation();
  const [menuItemPrimary, setMenuItemPrimary] = useState(101);
  const onMouseEnter = (id) => {
    setMenuItemPrimary(id);
   
  };
  return (
    <div >
        <div className="navbar-box">
          <div className="box-header-section">
            <div className="imgBx">
              <img  src={`/assets/images/menu/Main-${menuItemPrimary}.webp`} alt="reduktor" />
            </div>
            <div className="content">
              <p>{t("NB-PR-MN-P-1")}</p>
              <p>{t("NB-PR-MN-P-2")}</p>
            </div>
            <Link to="/urun/aksesuarlar-opsiyonlar"><button className="box-button-2">{t("G-AKS-MN")}</button></Link>
            <Link to="/reduktor-secim"><button className="box-button">{t("NB-PR-MN-BTN")}</button></Link>
      
          </div>
          <div className="box-content-section">
            {navbarProducts.map((data, key) => {
              return (
                <div
                  key={key}
                  onMouseEnter={() => onMouseEnter(data.menuId)}
                  className={
                    menuItemPrimary === data.menuId
                      ? "box-content-section-text-active"
                      : "box-content-section-text"
                  }
                >

                  <div className="box-content-section-arrow">
                  <span>{t(data.menuName)}</span>
                  {menuItemPrimary === data.menuId && <img  alt="arrow" src="/assets/images/bg/Arrow.png" className="nav-arrow-product"></img>}
                  </div>
                

                  {menuItemPrimary === data.menuId ? (
                    <NavbarCategory
                      menuFirstCategoryId={data.menuFirstCategoryId}
                      menuId={menuItemPrimary}
                    />
                  ) : (
                    <div />
                  )}
                </div>
              );
            })}
          </div>
        </div>
        </div>
      ) 
}

export default NavbarMenu;
