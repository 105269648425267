import React ,{useEffect , useState} from 'react'
import NewsItem from "./NewsItem/NewsItem";
import './News.css'
import { useTranslation } from 'react-i18next';
import { fetchNewsAll } from '../../../api/agent';
import Slider from "react-slick";
import { useMediaPredicate } from "react-media-hook";

import { fetchTipsheets } from '../../../api/agent';


function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", right:'20px' , zIndex:'9999999' }}
      onClick={onClick}
    />
  );
}

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block" , left:"0px" , zIndex:'9999999'}}
      onClick={onClick}
    />
  );
}
function NextArrowMobile(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", right:'-30px' , zIndex:'9999999' }}
      onClick={onClick}
    />
  );
}

function PrevArrowMobile(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block" , left:"-50px" , zIndex:'9999999'}}
      onClick={onClick}
    />
  );
}

function NewsReact() {
  const { t } = useTranslation();
  const isMobile = useMediaPredicate("(max-width: 479px)"); // boyutlar düzenlenebilir

    
  const [news, setNews] = useState([]);

  useEffect(() => {
    const getAllNews = async() => {
      const allNews = await fetchNewsAll();
      var result = allNews.data;
      setNews(result);
    }
    getAllNews();
  }, [])

  var settingsMobile = {
    dots: true,
    nextArrow: <NextArrowMobile />,
    prevArrow: <PrevArrowMobile />,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1
  };

  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
   
    responsive: [
      {
     
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          fade : true,
          
        }
      }
    ]
  };

  return (
    <div className="letestpro1 secpadd-custom clearfix">
       <h2 className="news-header">{t("HP-NP-T")}</h2>
       {(isMobile) ? <>
       
        <Slider 
        
        {...settingsMobile}
      
      >
  
        {news.map((data, key) => {
          return (
            <NewsItem
              id= {data.id}
              key={data.id}
              title={t("YR-NEWS-TITLE-"+data.id)}
              image={t("YR-NEWS-IMG-"+data.id)}
              description= {data.description}
            />
          );
        })}
      </Slider>
  
       
       </> : <>
       <Slider 
        
        {...settings}
      
      >
  
        {news.map((data, key) => {
          return (
            <NewsItem
              id= {data.id}
              key={data.id}
              title={t("YR-NEWS-TITLE-"+data.id)}
              image={t("YR-NEWS-IMG-"+data.id)}
              description= {data.description}
            />
          );
        })}
      </Slider>

       </>}
    
    </div>
  );
}

export default NewsReact;
