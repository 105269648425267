import React, { useState, useCallback } from "react";
import BreadCrumb from "../Shared/BreadCrumb/BreadCrumb";
import "./JobAndInternShip.css";
import "date-fns";
import trLocale from "date-fns/locale/tr";
import DateFnsUtils from "@date-io/date-fns";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { Formik } from "formik";
import { makeStyles } from "@material-ui/core/styles";
import * as Yup from "yup";
import api from '../../api/agent'


import { default as ReactSelect } from "react-select";
import { components } from "react-select";


import {
  TextField,
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
  Button,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  Checkbox,
} from "@material-ui/core";

import Grid from "@material-ui/core/Grid";
import Dropzone from "react-dropzone-uploader";
import "react-dropzone-uploader/dist/styles.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from 'react-i18next';
import { fetchJobAndIntership } from "../../api/agent";



const colourOptions = [
  { value: "ocean1", label: "Ocean" },
  { value: "blue", label: "Blue" },
  { value: "purple", label: "Purple" },
  { value: "red", label: "Red" },
  { value: "orange", label: "Orange" },
  { value: "yellow", label: "Yellow" },
  { value: "green", label: "Green" },
  { value: "forest", label: "Forest" },
  { value: "slate", label: "Slate" },
  { value: "silver", label: "Silver" }
];

const intershipWithDepartment = [
  { value:"ÜRETİM", label:"ÜRETİM"},
  { value:"SATIS", label:"SATIŞ"},
  { value:"ARGE", label:"ARGE"},
  { value:"PLANLAMA", label:"PLANLAMA"},
  { value:"BİLGİ İŞLEM", label:"BİLGİ İŞLEM"},
  { value:"KALİTE KONTROL", label:"KALİTE KONTROL"},
  { value:"OTOMASYON", label:"OTOMASYON"}
];

export const customTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#be057e",
    },
  },

  overrides: {
    MuiFormControlLabel: {
      label: {
        fontSize: "16px",
        fontFamily: "Monosettra",
      },
    },
    MuiButton: {
      root: {                
        "&:not($sizeLarge):not($sizeSmall) $label": {
          font: "normal normal 700 0.875rem/1.6875rem Open Sans",
          color: "green"
        }
      },
      sizeLarge: {
        "& $label": {
          font: "normal normal 700 1.5rem/2rem Montserrat",
        }
      },
      sizeSmall: {
        "& $label": {
          font: "normal normal 700 0.8125rem/1.6875rem Open Sans"
        }
      }
    }
  },

  typography: {
    fontFamily: ["Monosettra"],
    fontSize: ["2rem"],
  },
});


const useStyles = makeStyles((theme) => ({
  resize: {
    fontSize: 16,
  },
  root: {
    "& .MuiTextField-root": {
      width: "60ch",
    },
    "& .MuiSelect-root": {
      width: "60ch",
    },
  },
  smallRadioButton: {
    "& svg": {
      width: "2em",
      height: "2em",
    },
  },
  tickSize: {
    transform: "scale(1.5)",
  },
}));

const JobAndInternShip = () => {
  const [isJob, setIsJob] = useState(true);
  const [cvChange , setCvChange] = useState();
  const [optionSelected , setOptionSelected] = useState();
  const classes = useStyles();
  const { t } = useTranslation();
  


  
  const addPdfFile = (event ) => {
    const pdfFile = event.target.files[0];
    setCvChange(pdfFile);
}

const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{" "}
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};

const changeOption = (selected) => {
  console.log("changeOption",selected)
  setOptionSelected(selected)
};


  const handleChangeStatus = ({ meta, remove }, status) => {
    
    if (status === "headers_received") {
      toast.success(`${meta.name} dosyası yüklendi...`, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      remove();
    } else if (status === "aborted") {
      toast.error(`${meta.name}, dosyası yüklenemedi...`, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (status === "rejected_file_type") {
      toast.error(`Geçersiz dosya tipi...`, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const formTemp = isJob ? (
    <>
      <Formik
        initialValues={{
          firstName: "",
          lastName: "",
          birthDay: null,
          identityNumber: "",
          gender: "",
          maritalStatus: "",
          militaryStatus: "",
          email: "",
          phoneNumber: "",
          city: "",
          district: "",
          address: "",
          educationLevel: "",
          schoolName: "",
          schoolStartTime: null,
          schoolFinishTime: null,
          cvPath: null,
          jobOrIntership : 0,
          onay: false,
        }}
        validationSchema={Yup.object({
          firstName: Yup.string().required(t("CP-APPLY-G-NAME-V")),
          lastName: Yup.string().required(t("CP-APPLY-G-SURNAME-V")),

          identityNumber: Yup.string().required(
            t("CP-APPLY-G-TC-V")
          ),

          birthDay: Yup.date()
            .nullable()
            .required(t("CP-APPLY-G-DATE-V")),
            schoolStartTime: Yup.date()
            .nullable()
            .required(t("CP-APPLY-G-START-V")),
            schoolFinishTime: Yup.date()
            .nullable()
            .required(t("CP-APPLY-G-FINISH-V")),
            gender: Yup.string().required(t("CP-APPLY-G-GENDER-V")),

            maritalStatus: Yup.string()
            .required(t("CP-APPLY-G-MARITAL-V"))
            .oneOf(["Evli", "Bekar"]),
            militaryStatus: Yup.string()
            .required(t("CP-APPLY-G-MILITARY-V"))
            .oneOf(["Muaf", "Tecilli", "Tamamlandı"]),

            email: Yup.string()
            .email("Geçerli bir e-posta giriniz !")
            .required(t("CP-APPLY-G-MAIL-V")),
          phoneNumber: Yup.string().required(t("CP-APPLY-G-PHONE-V")),
          city: Yup.string().required(t("CP-APPLY-G-CITY-V")),

          district: Yup.string().required(t("CP-APPLY-G-DISTRICT-V")),

          address: Yup.string().required(t("CP-APPLY-G-ADDRESS-V")),

          educationLevel: Yup.string()
            .required(t("CP-APPLY-G-EDU-V"))
            .oneOf([
              "İlkokul",
              "Ortaokul",
              "Lise",
              "Önlisans",
              "Lisans",
              "Yüksek Lisans",
            ]),

            schoolName: Yup.string().required(t("CP-APPLY-G-SCHOOL-V")),

          // cv: Yup.mixed().required("Cv boş bırakılamaz !"),

          onay: Yup.bool().oneOf([true], "Koşulları kabul etmelisiniz !"),
        })}
        onSubmit={ async (values, { resetForm, setSubmitting }) => {
          
          values.cvPath = cvChange;
          await api.JobAndInternShip.postJob(values)
          
          setTimeout(() => {
            setSubmitting(false);
            toast.success(
              `${values.firstName} ${values.lastName} başarıyla cv'inizi gönderdiniz.`,
              {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              }
            );
            resetForm();
          }, 2000);
        }}
        render={(props) => (
          <form className={classes.root} onSubmit={props.handleSubmit}>
            <h5
              style={{
                margin: "30px 0",
                color: "#63656a",
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              {t("CP-APPLY-G-PI")}
            </h5>
            {console.log(props.touched)}
            <MuiThemeProvider theme={customTheme}>
            <Grid container justify="space-between">
              <TextField
                style={{ marginBottom: "20px" }}
                label={t("CP-APPLY-G-NAME")}
                InputProps={{ classes: { input: classes.resize } }}
                InputLabelProps={{ style: { fontSize: 20 } }}
                
                name="firstName"
                id="firstName"
                type="text"
                helperText={props.touched.firstName ? props.errors.firstName : ""}
                error={props.touched.firstName && Boolean(props.errors.firstName)}
                onBlur={props.handleBlur}
                FormHelperTextProps={{ style: { fontSize: 14 } }}
                value={props.values.firstName}
                onChange={props.handleChange}
              />

              <TextField
                style={{ marginBottom: "20px" }}
                label={t("CP-APPLY-G-SURNAME")}
                InputProps={{ classes: { input: classes.resize } }}
                InputLabelProps={{ style: { fontSize: 20 } }}
                name="lastName"
                id="lastName"
                type="text"
                helperText={props.touched.lastName ? props.errors.lastName : ""}
                error={props.touched.lastName && Boolean(props.errors.lastName)}
                onBlur={props.handleBlur}
                FormHelperTextProps={{ style: { fontSize: 14 } }}
                value={props.values.lastName}
                onChange={props.handleChange}
              />
            </Grid>

            <Grid container justify="space-between">
              <TextField
                style={{ marginBottom: "20px" }}
                label={t("CP-APPLY-G-TC")}
                InputProps={{ classes: { input: classes.resize } }}
                InputLabelProps={{ style: { fontSize: 20 } }}
                name="identityNumber"
                id="identityNumber"
                type="text"
                value={props.values.identityNumber}
                onBlur={props.handleBlur}
                helperText={
                  props.touched.identityNumber ? props.errors.identityNumber : ""
                }
                error={
                  props.touched.identityNumber && Boolean(props.errors.identityNumber)
                }
                onChange={props.handleChange}
                FormHelperTextProps={{ style: { fontSize: 14 } }}
              />

       
                <MuiPickersUtilsProvider locale={trLocale} utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    style={{ marginBottom: "20px" }}
                    InputLabelProps={{
                      style: { fontSize: 20 },
                      shrink: true,
                    }}
                    InputProps={{ classes: { input: classes.resize } }}
                    okLabel="Kaydet"
                    clearLabel="Temizle"
                    cancelLabel="İptal"
                    id="birthDay"
                    name="birthDay"
                    label={t("CP-APPLY-G-DATE")}
                    format="dd/MM/yyyy"
                    value={props.values.birthDay}
                    onBlur={props.handleBlur}
                    helperText={
                      props.touched.birthDay ? props.errors.birthDay : ""
                    }
                    error={
                      props.touched.birthDay &&
                      Boolean(props.errors.birthDay)
                    }
                    onChange={(value) =>
                      props.setFieldValue("birthDay", value)
                    }
                    FormHelperTextProps={{ style: { fontSize: 14 } }}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </MuiPickersUtilsProvider>
      
            </Grid>
      
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                
                  flexDirection: "column",
                }}
              >
                <FormLabel
                  style={{
                    fontSize: "20px",
                  
                    marginTop: "20px",
                  }}
                  component="legend"
                >
                  {t("CP-APPLY-G-GENDER-T")}
                </FormLabel>
                <RadioGroup
                  style={
                    props.touched.gender
                      ? { marginTop: "20px" }
                      : { margin: "20px 0" }
                  }
                  row
                  aria-label="gender"
                  id="gender"
                  value={props.values.gender}
                  onBlur={props.handleBlur}
                  error={props.errors.gender}
                  helperText={
                    props.touched.gender ? props.errors.gender : ""
                  }
                  touched={props.touched.gender}
                  onChange={props.handleChange}
                >
                  <FormControlLabel
                    className={classes.smallRadioButton}
                    value="Kadın"
                    control={<Radio name="gender" color="primary" />}
                    label={t("CP-APPLY-GENDER-W")}
                  />
                  <FormControlLabel
                    className={classes.smallRadioButton}
                    value="Erkek"
                    control={<Radio name="gender" color="primary" />}
                    label={t("CP-APPLY-GENDER-M")}
                  />
                </RadioGroup>

                <span
                  style={{
                    marginBottom: "10px",
                    color: "#f44336",
                    fontWeight: 400,
                  }}
                >
                  {props.touched.gender ? props.errors.gender : ""}
                </span>

                <FormLabel
                  style={{ fontSize: "20px" }}
                  component="legend"
                >
                 {t("CP-APPLY-MARITAL")}
                </FormLabel>
                <RadioGroup
                  row
                  style={
                    props.touched.maritalStatus
                      ? { marginTop: "20px" }
                      : { margin: "20px 0" }
                  }
                  aria-label="maritalStatus"
                  name="maritalStatus"
                  value={props.values.maritalStatus}
                  onBlur={props.handleBlur}
                  error={
                    props.touched.maritalStatus &&
                    Boolean(props.errors.maritalStatus)
                  }
                  helperText={
                    props.touched.maritalStatus ? props.errors.maritalStatus : ""
                  }
                  onChange={props.handleChange}
                >
                  <FormControlLabel
                    className={classes.smallRadioButton}
                    value="Evli"
                    control={<Radio color="primary" />}
                    label={t("CP-APPLY-MARRIED")}
                  />
                  <FormControlLabel
                    className={classes.smallRadioButton}
                    value="Bekar"
                    control={<Radio color="primary" />}
                    label={t("CP-APPLY-SINGLE")}
                  />
                </RadioGroup>
                <span
                  style={{
                    marginBottom: "10px",
                    color: "#f44336",
                    fontWeight: 400,
                  }}
                >
                  {props.touched.maritalStatus ? props.errors.maritalStatus : ""}
                </span>

                <FormLabel
                  style={{ fontSize: "20px" }}
                  component="legend"
                >
                  {t("CP-APPLY-MILITARY")}
                </FormLabel>
                <RadioGroup
                  style={
                    props.touched.militaryStatus
                      ? { marginTop: "20px" }
                      : { margin: "20px 0" }
                  }
                  row
                  aria-label="militaryStatus"
                  name="militaryStatus"
                  value={props.values.militaryStatus}
                  onBlur={props.handleBlur}
                  error={
                    props.touched.militaryStatus &&
                    Boolean(props.errors.militaryStatus)
                  }
                  helperText={
                    props.touched.militaryStatus
                      ? props.errors.militaryStatus
                      : ""
                  }
                  onChange={props.handleChange}
                >
                  <FormControlLabel
                    className={classes.smallRadioButton}
                    value="Muaf"
                    control={<Radio color="primary" />}
                    label={t("CP-APPLY-EXEMPT")}
                  />
                  <FormControlLabel
                    className={classes.smallRadioButton}
                    value="Tecilli"
                    control={<Radio color="primary" />}
                    label={t("CP-APPLY-DELAYED")}
                  />
                  <FormControlLabel
                    className={classes.smallRadioButton}
                    value="Tamamlandı"
                    control={<Radio color="primary" />}
                    label={t("CP-APPLY-COMPLETED")}
                  />
                </RadioGroup>
                <span
                  style={{
                    marginBottom: "10px",
                    color: "#f44336",
                    fontWeight: 400,
                  }}
                >
                  {props.touched.militaryStatus
                    ? props.errors.militaryStatus
                    : ""}
                </span>
              </div>
        

            <h5
              style={{
                margin: "30px 0",
                color: "#63656a",
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              {t("CP-APPLY-G-CI")}
            </h5>

            <Grid container justify="space-between">
              <TextField
                style={{ marginBottom: "20px" }}
                label={t("CP-APPLY-G-MAIL")}
                InputProps={{ classes: { input: classes.resize } }}
                InputLabelProps={{ style: { fontSize: 20 } }}
                name="email"
                id="email"
                type="text"
                value={props.values.email}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                helperText={props.touched.email ? props.errors.email : ""}
                error={props.touched.email && Boolean(props.errors.email)}
                FormHelperTextProps={{ style: { fontSize: 14 } }}
              />
              <TextField
                style={{ marginBottom: "20px" }}
                label={t("CP-APPLY-G-PHONE")}
                InputProps={{ classes: { input: classes.resize } }}
                InputLabelProps={{ style: { fontSize: 20 } }}
                name="phoneNumber"
                id="phoneNumber"
                type="text"
                value={props.values.phoneNumber}
                onBlur={props.handleBlur}
                helperText={props.touched.phoneNumber ? props.errors.phoneNumber : ""}
                error={props.touched.phoneNumber && Boolean(props.errors.phoneNumber)}
                onChange={props.handleChange}
                FormHelperTextProps={{ style: { fontSize: 14 } }}
              />
            </Grid>

            <Grid container justify="space-between">
              <TextField
                style={{ marginBottom: "20px" }}
                label={t("CP-APPLY-G-CITY")}
                InputProps={{ classes: { input: classes.resize } }}
                InputLabelProps={{ style: { fontSize: 20 } }}
                name="city"
                id="city"
                type="text"
                value={props.values.city}
                onBlur={props.handleBlur}
                helperText={props.touched.city ? props.errors.city : ""}
                error={props.touched.city && Boolean(props.errors.city)}
                onChange={props.handleChange}
                FormHelperTextProps={{ style: { fontSize: 14 } }}
              />
              <TextField
                style={{ marginBottom: "20px" }}
                label={t("CP-APPLY-G-DISTRICT")}
                InputProps={{ classes: { input: classes.resize } }}
                InputLabelProps={{ style: { fontSize: 20 } }}
                name="district"
                id="district"
                type="text"
                value={props.values.district}
                onBlur={props.handleBlur}
                helperText={props.touched.district ? props.errors.district : ""}
                error={props.touched.district && Boolean(props.errors.district)}
                onChange={props.handleChange}
                FormHelperTextProps={{ style: { fontSize: 14 } }}
              />
            </Grid>

            <Grid container justify="center">
              <TextField
                style={{ marginBottom: "20px" }}
                label={t("CP-APPLY-G-ADDRESS")}
                InputProps={{ classes: { input: classes.resize } }}
                InputLabelProps={{ style: { fontSize: 20 } }}
                name="address"
                id="address"
                type="text"
                value={props.values.address}
                onBlur={props.handleBlur}
                helperText={props.touched.address ? props.errors.address : ""}
                error={props.touched.address && Boolean(props.errors.address)}
                onChange={props.handleChange}
                FormHelperTextProps={{ style: { fontSize: 14 } }}
              />
            </Grid>

            <h5
              style={{
                margin: "30px 0",
                color: "#63656a",
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              {t("CP-APPLY-G-EI")}
            </h5>

            <Grid container justify="space-between">
              <TextField
                label={t("CP-APPLY-G-EDU")}
                InputProps={{ classes: { input: classes.resize } }}
                InputLabelProps={{ style: { fontSize: 20 } }}
                select
                id="educationLevel"
                name="educationLevel"
                value={props.values.educationLevel}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                helperText={
                  props.touched.educationLevel
                    ? props.errors.educationLevel
                    : ""
                }
                error={
                  props.touched.educationLevel &&
                  Boolean(props.errors.educationLevel)
                }
                FormHelperTextProps={{ style: { fontSize: 14 } }}
              >
                <MenuItem style={{ fontSize: "16px" }} value={"İlkokul"}>
                {t("CP-APPLY-G-ILKOKUL")}
                </MenuItem>
                <MenuItem style={{ fontSize: "16px" }} value={"Ortaokul"}>
                {t("CP-APPLY-G-ORTAOKUL")}
                </MenuItem>
                <MenuItem style={{ fontSize: "16px" }} value={"Lise"}>
                {t("CP-APPLY-G-LISE")}
                </MenuItem>
                <MenuItem style={{ fontSize: "16px" }} value={"Önlisans"}>
                {t("CP-APPLY-G-ONLISANS")}
                </MenuItem>
                <MenuItem style={{ fontSize: "16px" }} value={"Lisans"}>
                {t("CP-APPLY-G-LISANS")}
                </MenuItem>
                <MenuItem style={{ fontSize: "16px" }} value={"Yüksek Lisans"}>
                {t("CP-APPLY-G-YUKSEK-LISANS")}
                </MenuItem>
              </TextField>

              <TextField
                label={t("CP-APPLY-G-SCHOOL")}
                InputProps={{ classes: { input: classes.resize } }}
                InputLabelProps={{ style: { fontSize: 20 } }}
                name="schoolName"
                id="schoolName"
                type="text"
                value={props.values.schoolName}
                onBlur={props.handleBlur}
                helperText={props.touched.schoolName ? props.errors.schoolName : ""}
                error={props.touched.schoolName && Boolean(props.errors.schoolName)}
                onChange={props.handleChange}
                FormHelperTextProps={{ style: { fontSize: 14 } }}
              />
            </Grid>

            
              <MuiPickersUtilsProvider locale={trLocale} utils={DateFnsUtils}>
                <Grid
                  style={{ marginTop: "20px" }}
                  container
                  justify="space-between"
                >
                  <KeyboardDatePicker
                    InputLabelProps={{
                      style: { fontSize: 20 },
                      shrink: true,
                    }}
                    InputProps={{ classes: { input: classes.resize } }}
                    okLabel="Kaydet"
                    clearLabel="Temizle"
                    cancelLabel="İptal"
                    id="schoolStartTime"
                    name="schoolStartTime"
                    label={t("CP-APPLY-G-START")}
                    format="dd/MM/yyyy"
                    value={props.values.schoolStartTime}
                    onBlur={props.handleBlur}
                    helperText={
                      props.touched.schoolStartTime
                        ? props.errors.schoolStartTime
                        : null
                    }
                    error={
                      props.touched.schoolStartTime &&
                      Boolean(props.errors.schoolStartTime)
                    }
                    onChange={(value) =>
                      props.setFieldValue("schoolStartTime", value)
                    }
                    FormHelperTextProps={{ style: { fontSize: 14 } }}
                  />

                  <KeyboardDatePicker
                    InputLabelProps={{
                      style: { fontSize: 20 },
                      shrink: true,
                    }}
                    InputProps={{ classes: { input: classes.resize } }}
                    okLabel="Kaydet"
                    clearLabel="Temizle"
                    cancelLabel="İptal"
                    id="schoolFinishTime"
                    name="schoolFinishTime"
                    label={t("CP-APPLY-G-FINISH")}
                    format="dd/MM/yyyy"
                    value={props.values.schoolFinishTime}
                    onBlur={props.handleBlur}
                    helperText={
                      props.touched.schoolFinishTime
                        ? props.errors.schoolFinishTime
                        : null
                    }
                    error={
                      props.touched.schoolFinishTime &&
                      Boolean(props.errors.schoolFinishTime)
                    }
                    onChange={(value) =>
                      props.setFieldValue("schoolFinishTime", value)
                    }
                    FormHelperTextProps={{ style: { fontSize: 14 } }}
                  />
                </Grid>
              </MuiPickersUtilsProvider>
   

            <Grid   style={{ marginTop: "20px" }} container justify="center">
            <Button
              variant="contained"
              component="label"
            >
              <span style={{fontSize:'18px',marginRight:'10px', color:'var(--primary-dark)'}}>   {t("CV-ADD-FILE")}</span>
              <input
                type="file"
                name="cvPath"
                onChange={addPdfFile}
              />
            </Button>
            </Grid>

            <ToastContainer />

            <p style={{ margin: "20px 0" }}>
                {t("CP-APPLY-G-CONF-J")}
            </p>
            <Grid container justify="space-between">
              <FormControlLabel
                control={
                  <Checkbox
                    className={classes.tickSize}
                    color="default"
                    value={props.values.onay}
                    onChange={props.handleChange}
                    name="onay"
                  />
                }
                label={t("CP-APPLY-G-AGREE")}
              />

              <Button 
              style={{marginTop: '50px', position: 'absolute'}}
              color = "primary"
                type="submit"
                size="large"
                disabled={!props.dirty || props.isSubmitting}
                variant="contained"
              >
                {t("CP-APPLY-G-SUBMIT")}
              </Button>
            </Grid>
            </MuiThemeProvider>
          </form>
        )}
      />
    </>
  ) : (
    <>
      {" "}
      <Formik
        initialValues={{
        

          firstName: "",
          lastName: "",
          birthDay: null,
          identityNumber: "",
          gender: "",
          email: "",
          phoneNumber: "",
          city: "",
          district: "",
          address: "",
          educationLevel: "",
          schoolName: "",
          schoolStartTime: null,
          schoolFinishTime: null,
          cvPath: null,
          jobOrIntership : 1,
          intershipType : "",
          intershipDepartment : "",
          onay: false,
        }}
        validationSchema={Yup.object({
          firstName: Yup.string().required(t("CP-APPLY-G-NAME-V")),
          lastName: Yup.string().required(t("CP-APPLY-G-SURNAME-V")),

          identityNumber: Yup.string().required(
            t("CP-APPLY-G-TC-V")
          ),

          birthDay: Yup.date()
            .nullable()
            .required(t("CP-APPLY-G-DATE-V")),
          schoolStartTime: Yup.date()
            .nullable()
            .required(t("CP-APPLY-G-START-V")),
          schoolFinishTime: Yup.date()
            .nullable()
            .required(t("CP-APPLY-G-FINISH-V")),
          gender: Yup.string().required(t("CP-APPLY-G-GENDER-V")),

          email: Yup.string()
            .email("Geçerli bir e-posta giriniz !")
            .required(t("CP-APPLY-G-MAIL-V")),
          phoneNumber: Yup.string().required(t("CP-APPLY-G-PHONE-V")),
          city: Yup.string().required(t("CP-APPLY-G-CITY-V")),

          district: Yup.string().required(t("CP-APPLY-G-DISTRICT-V")),

          address: Yup.string().required(t("CP-APPLY-G-ADDRESS-V")),

          educationLevel: Yup.string()
            .required(t("CP-APPLY-G-EDU-V"))
            .oneOf([
              "İlkokul",
              "Ortaokul",
              "Lise",
              "Önlisans",
              "Lisans",
              "Yüksek Lisans",
            ]),

            intershipType: Yup.string()
            .required(t("CP-APPLY-G-REFERENCE-TYPE-V"))
            .oneOf(["Lise Kış Dönemi", "Lise Yaz Dönemi", "Üniversite Uzun Dönemi", "Üniversite Yaz Dönemi"]),

          intershipDepartment: Yup.string()
            .required(t("CP-APPLY-G-DEPARTMENT-I-V"))
            .oneOf(["ÜRETİM", "SATIŞ", "ARGE", "PLANLAMA", "BİLGİ İŞLEM", "KALİTE KONTROL", "OTOMASYON"]),


            


          schoolName: Yup.string().required(t("CP-APPLY-G-SCHOOL-V")),

          // cv: Yup.mixed().required("Cv boş bırakılamaz !"),

          onay: Yup.bool().oneOf([true], "Koşulları kabul etmelisiniz !"),
        })}
        onSubmit={async (values, { resetForm, setSubmitting }) => {
          
          values.cvPath = cvChange;
          await api.JobAndInternShip.postIntership(values)
          setTimeout(() => {
            setSubmitting(false);
            toast.success(
              `${values.firstName} ${values.lastName} başarıyla cv'inizi gönderdiniz.`,
              {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              }
            );
            resetForm();
          }, 2000);
        }}
        render={(props) => (
          <form className={classes.root} onSubmit={props.handleSubmit}>
            <h5
              style={{
                margin: "30px 0",
                color: "#63656a",
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              {t("CP-APPLY-G-PI")}
            </h5>
            {console.log(props.touched)}
            <MuiThemeProvider theme={customTheme}>
            <Grid container justify="space-between">
              <TextField
                style={{ marginBottom: "20px" }}
                label={t("CP-APPLY-G-NAME")}
                InputProps={{ classes: { input: classes.resize } }}
                InputLabelProps={{ style: { fontSize: 20 } }}
                name="firstName"
                id="firstName"
                type="text"
                helperText={props.touched.firstName ? props.errors.firstName : ""}
                error={props.touched.firstName && Boolean(props.errors.firstName)}
                onBlur={props.handleBlur}
                FormHelperTextProps={{ style: { fontSize: 14 } }}
                value={props.values.firstName}
                onChange={props.handleChange}
              />

              <TextField
                style={{ marginBottom: "20px" }}
                label={t("CP-APPLY-G-SURNAME")}
                InputProps={{ classes: { input: classes.resize } }}
                InputLabelProps={{ style: { fontSize: 20 } }}
                name="lastName"
                id="lastName"
                type="text"
                helperText={props.touched.lastName ? props.errors.lastName : ""}
                error={props.touched.lastName && Boolean(props.errors.lastName)}
                onBlur={props.handleBlur}
                FormHelperTextProps={{ style: { fontSize: 14 } }}
                value={props.values.lastName}
                onChange={props.handleChange}
              />
            </Grid>

            <Grid container justify="space-between">
              <TextField
                style={{ marginBottom: "20px" }}
                label={t("CP-APPLY-G-TC")}
                InputProps={{ classes: { input: classes.resize } }}
                InputLabelProps={{ style: { fontSize: 20 } }}
                name="identityNumber"
                id="identityNumber"
                type="text"
                value={props.values.identityNumber}
                onBlur={props.handleBlur}
                helperText={
                  props.touched.identityNumber ? props.errors.identityNumber : ""
                }
                error={
                  props.touched.identityNumber && Boolean(props.errors.identityNumber)
                }
                onChange={props.handleChange}
                FormHelperTextProps={{ style: { fontSize: 14 } }}
              />

        
                <MuiPickersUtilsProvider locale={trLocale} utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    style={{ marginBottom: "20px" }}
                    InputLabelProps={{
                      style: { fontSize: 20 },
                      shrink: true,
                    }}
                    InputProps={{ classes: { input: classes.resize } }}
                    okLabel="Kaydet"
                    clearLabel="Temizle"
                    cancelLabel="İptal"
                    id="birthDay"
                    name="birthDay"
                    label={t("CP-APPLY-G-DATE")}
                    format="dd/MM/yyyy"
                    value={props.values.birthDay}
                    onBlur={props.handleBlur}
                    helperText={
                      props.touched.birthDay ? props.errors.birthDay : ""
                    }
                    error={
                      props.touched.birthDay &&
                      Boolean(props.errors.birthDay)
                    }
                    onChange={(value) =>
                      props.setFieldValue("birthDay", value)
                    }
                    FormHelperTextProps={{ style: { fontSize: 14 } }}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </MuiPickersUtilsProvider>
       
            </Grid>
           
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
               
                  flexDirection: "column",
                }}
              >
                <FormLabel
                  style={{
                    fontSize: "20px",
                    
                    marginTop: "20px",
                  }}
                  component="legend"
                >
                  {t("CP-APPLY-G-GENDER-T")}
                </FormLabel>
                <RadioGroup
                  style={
                    props.touched.gender
                      ? { marginTop: "20px" }
                      : { margin: "20px 0" }
                  }
                  row
                  aria-label="gender"
                  id="gender"
                  value={props.values.gender}
                  onBlur={props.handleBlur}
                  error={props.errors.gender}
                  helperText={
                    props.touched.gender ? props.errors.gender : ""
                  }
                  touched={props.touched.gender}
                  onChange={props.handleChange}
                >
                  <FormControlLabel
                    className={classes.smallRadioButton}
                    value="Kadın"
                    control={<Radio name="gender" color="primary" />}
                    label={t("CP-APPLY-GENDER-W")}
                  />
                  <FormControlLabel
                    className={classes.smallRadioButton}
                    value="Erkek"
                    control={<Radio name="gender" color="primary" />}
                    label={t("CP-APPLY-GENDER-M")}
                  />
                </RadioGroup>

                <span
                  style={{
                    marginBottom: "10px",
                    color: "#f44336",
                    fontWeight: 400,
                  }}
                >
                  {props.touched.gender ? props.errors.gender : ""}
                </span>
              </div>
     

            <h5
              style={{
                margin: "30px 0",
                color: "#63656a",
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
             {t("CP-APPLY-G-CI")}
            </h5>

            <Grid container justify="space-between">
              <TextField
                style={{ marginBottom: "20px" }}
                label={t("CP-APPLY-G-MAIL")}
                InputProps={{ classes: { input: classes.resize } }}
                InputLabelProps={{ style: { fontSize: 20 } }}
                name="email"
                id="email"
                type="text"
                value={props.values.email}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                helperText={props.touched.email ? props.errors.email : ""}
                error={props.touched.email && Boolean(props.errors.email)}
                FormHelperTextProps={{ style: { fontSize: 14 } }}
              />
              <TextField
                style={{ marginBottom: "20px" }}
                label={t("CP-APPLY-G-PHONE")}
                InputProps={{ classes: { input: classes.resize } }}
                InputLabelProps={{ style: { fontSize: 20 } }}
                name="phoneNumber"
                id="phoneNumber"
                type="text"
                value={props.values.phoneNumber}
                onBlur={props.handleBlur}
                helperText={props.touched.phoneNumber ? props.errors.phoneNumber : ""}
                error={props.touched.phoneNumber && Boolean(props.errors.phoneNumber)}
                onChange={props.handleChange}
                FormHelperTextProps={{ style: { fontSize: 14 } }}
              />
            </Grid>

            <Grid container justify="space-between">
              <TextField
                style={{ marginBottom: "20px" }}
                label={t("CP-APPLY-G-CITY")}
                InputProps={{ classes: { input: classes.resize } }}
                InputLabelProps={{ style: { fontSize: 20 } }}
                name="city"
                id="city"
                type="text"
                value={props.values.city}
                onBlur={props.handleBlur}
                helperText={props.touched.city ? props.errors.city : ""}
                error={props.touched.city && Boolean(props.errors.city)}
                onChange={props.handleChange}
                FormHelperTextProps={{ style: { fontSize: 14 } }}
              />
              <TextField
                style={{ marginBottom: "20px" }}
                label={t("CP-APPLY-G-DISTRICT")}
                InputProps={{ classes: { input: classes.resize } }}
                InputLabelProps={{ style: { fontSize: 20 } }}
                name="district"
                id="district"
                type="text"
                value={props.values.district}
                onBlur={props.handleBlur}
                helperText={props.touched.district ? props.errors.district : ""}
                error={props.touched.district && Boolean(props.errors.district)}
                onChange={props.handleChange}
                FormHelperTextProps={{ style: { fontSize: 14 } }}
              />
            </Grid>

            <Grid container justify="center">
              <TextField
                style={{ marginBottom: "20px" }}
                label={t("CP-APPLY-G-ADDRESS")}
                InputProps={{ classes: { input: classes.resize } }}
                InputLabelProps={{ style: { fontSize: 20 } }}
                name="address"
                id="address"
                type="text"
                value={props.values.address}
                onBlur={props.handleBlur}
                helperText={props.touched.address ? props.errors.address : ""}
                error={props.touched.address && Boolean(props.errors.address)}
                onChange={props.handleChange}
                FormHelperTextProps={{ style: { fontSize: 14 } }}
              />
            </Grid>

            <h5
              style={{
                margin: "30px 0",
                color: "#63656a",
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              {t("CP-APPLY-G-EI")}
            </h5>

            <Grid container justify="space-between">
              <TextField
                label={t("CP-APPLY-G-EDU")}
                InputProps={{ classes: { input: classes.resize } }}
                InputLabelProps={{ style: { fontSize: 20 } }}
                select
                id="educationLevel"
                name="educationLevel"
                value={props.values.educationLevel}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                helperText={
                  props.touched.educationLevel
                    ? props.errors.educationLevel
                    : ""
                }
                error={
                  props.touched.educationLevel &&
                  Boolean(props.errors.educationLevel)
                }
                FormHelperTextProps={{ style: { fontSize: 14 } }}
              >
               <MenuItem style={{ fontSize: "16px" }} value={"İlkokul"}>
                {t("CP-APPLY-G-ILKOKUL")}
                </MenuItem>
                <MenuItem style={{ fontSize: "16px" }} value={"Ortaokul"}>
                {t("CP-APPLY-G-ORTAOKUL")}
                </MenuItem>
                <MenuItem style={{ fontSize: "16px" }} value={"Lise"}>
                {t("CP-APPLY-G-LISE")}
                </MenuItem>
                <MenuItem style={{ fontSize: "16px" }} value={"Önlisans"}>
                {t("CP-APPLY-G-ONLISANS")}
                </MenuItem>
                <MenuItem style={{ fontSize: "16px" }} value={"Lisans"}>
                {t("CP-APPLY-G-LISANS")}
                </MenuItem>
                <MenuItem style={{ fontSize: "16px" }} value={"Yüksek Lisans"}>
                {t("CP-APPLY-G-YUKSEK-LISANS")}
                </MenuItem>
              </TextField>

              <TextField
                label={t("CP-APPLY-G-SCHOOL")}
                InputProps={{ classes: { input: classes.resize } }}
                InputLabelProps={{ style: { fontSize: 20 } }}
                name="schoolName"
                id="schoolName"
                type="text"
                value={props.values.schoolName}
                onBlur={props.handleBlur}
                helperText={props.touched.schoolName ? props.errors.schoolName : ""}
                error={props.touched.schoolName && Boolean(props.errors.schoolName)}
                onChange={props.handleChange}
                FormHelperTextProps={{ style: { fontSize: 14 } }}
              />
            </Grid>

           
              <MuiPickersUtilsProvider locale={trLocale} utils={DateFnsUtils}>
                <Grid
                  style={{ marginTop: "20px" }}
                  container
                  justify="space-between"
                >
                  <KeyboardDatePicker
                    InputLabelProps={{
                      style: { fontSize: 20 },
                      shrink: true,
                    }}
                    InputProps={{ classes: { input: classes.resize } }}
                    okLabel="Kaydet"
                    clearLabel="Temizle"
                    cancelLabel="İptal"
                    id="schoolStartTime"
                    name="schoolStartTime"
                    label={t("CP-APPLY-G-START")}
                    format="dd/MM/yyyy"
                    value={props.values.schoolStartTime}
                    onBlur={props.handleBlur}
                    helperText={
                      props.touched.schoolStartTime
                        ? props.errors.schoolStartTime
                        : null
                    }
                    error={
                      props.touched.schoolStartTime &&
                      Boolean(props.errors.schoolStartTime)
                    }
                    onChange={(value) =>
                      props.setFieldValue("schoolStartTime", value)
                    }
                    FormHelperTextProps={{ style: { fontSize: 14 } }}
                  />

                  <KeyboardDatePicker
                    InputLabelProps={{
                      style: { fontSize: 20 },
                      shrink: true,
                    }}
                    InputProps={{ classes: { input: classes.resize } }}
                    okLabel="Kaydet"
                    clearLabel="Temizle"
                    cancelLabel="İptal"
                    id="schoolFinishTime"
                    name="schoolFinishTime"
                    label={t("CP-APPLY-G-FINISH")}
                    format="dd/MM/yyyy"
                    value={props.values.schoolFinishTime}
                    onBlur={props.handleBlur}
                    helperText={
                      props.touched.schoolFinishTime
                        ? props.errors.schoolFinishTime
                        : null
                    }
                    error={
                      props.touched.schoolFinishTime &&
                      Boolean(props.errors.schoolFinishTime)
                    }
                    onChange={(value) =>
                      props.setFieldValue("schoolFinishTime", value)
                    }
                    FormHelperTextProps={{ style: { fontSize: 14 } }}
                  />
                </Grid>
              </MuiPickersUtilsProvider>
              <Grid   style={{ marginTop: "20px" }} container justify="center">
            <Button
              variant="contained"
              component="label"
            >
              <span style={{fontSize:'18px',marginRight:'10px', color:'var(--primary-dark)'}}>   {t("CV-ADD-FILE")}</span>
              <input
                type="file"
                name="cvPath"
                onChange={addPdfFile}
              />
            </Button>
            </Grid>

            <h5
              style={{
                margin: "30px 0",
                color: "#63656a",
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              {t("CP-APPLY-G-INTERN-TYPE")}
            </h5>

            <Grid container justify="space-between">
              <TextField
                label={t("CP-APPLY-G-REFERENCE-TYPE")}
                InputProps={{ classes: { input: classes.resize } }}
                InputLabelProps={{ style: { fontSize: 20 } }}
                select
                id="intershipType"
                name="intershipType"
                value={props.values.intershipType}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                helperText={
                  props.touched.intershipType ? props.errors.intershipType : ""
                }
                error={
                  props.touched.intershipType && Boolean(props.errors.intershipType)
                }
                FormHelperTextProps={{ style: { fontSize: 14 } }}
              >
                <MenuItem style={{ fontSize: "16px" }} value={"Lise Kış Dönemi"}>
                  {t("G-INTERN-LISE-KIS")}
                </MenuItem>
                <MenuItem style={{ fontSize: "16px" }} value={"Lise Yaz Dönemi"}>
                {t("G-INTERN-LISE-YAZ")}
                </MenuItem>
                <MenuItem style={{ fontSize: "16px" }} value={"Üniversite Uzun Dönemi"}>
                {t("G-INTERN-UNI-UZUN")}
                </MenuItem>
                <MenuItem style={{ fontSize: "16px" }} value={"Üniversite Yaz Dönemi"}>
                {t("G-INTERN-UNI-YAZ")}
                </MenuItem>
               
              </TextField>

              <TextField
                label={t("CP-APPLY-G-DEPARTMENT-I")}
                InputProps={{ classes: { input: classes.resize } }}
                InputLabelProps={{ style: { fontSize: 20 } }}
                select
                id="intershipDepartment"
                name="intershipDepartment"
                value={props.values.intershipDepartment}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                helperText={
                  props.touched.intershipDepartment
                    ? props.errors.intershipDepartment
                    : ""
                }
                error={
                  props.touched.intershipDepartment &&
                  Boolean(props.errors.intershipDepartment)
                }
                FormHelperTextProps={{ style: { fontSize: 14 } }}
              >
                <MenuItem style={{ fontSize: "16px" }} value={"ÜRETİM"}>
                  {"ÜRETİM"}
                </MenuItem>
                <MenuItem style={{ fontSize: "16px" }} value={"SATIŞ"}>
                {"SATIŞ"}
                </MenuItem>
                <MenuItem style={{ fontSize: "16px" }} value={"ARGE"}>
                {"ARGE"}
                </MenuItem>
                <MenuItem style={{ fontSize: "16px" }} value={"PLANLAMA"}>
                {"PLANLAMA"}
                </MenuItem>
                <MenuItem style={{ fontSize: "16px" }} value={"BİLGİ İŞLEM"}>
                  {"BİLGİ İŞLEM"}
                </MenuItem>
                <MenuItem style={{ fontSize: "16px" }} value={"KALİTE KONTROL"}>
                {"KALİTE KONTROL"}
                </MenuItem>
                <MenuItem style={{ fontSize: "16px" }} value={"OTOMASYON"}>
                {"OTOMASYON"}
                </MenuItem>
                
              </TextField>

                
            </Grid>

            


            <p style={{ margin: "20px 0" }}>
              {t("CP-APPLY-G-CONF-J")}
            </p>
            <Grid container justify="space-between">
              <FormControlLabel
                control={
                  <Checkbox
                    className={classes.tickSize}
                    color="default"
                    value={props.values.onay}
                    onChange={props.handleChange}
                    name="onay"
                  />
                }
                label={t("CP-APPLY-G-AGREE")}
              />

              <Button 
              style={{marginTop: '50px', position: 'absolute'}}
                type="submit"
                size="large"
                color="primary"
                disabled={!props.dirty || props.isSubmitting}
                variant="contained"
                
              >
                {t("CP-APPLY-G-SUBMIT")}
              </Button>
            </Grid>
            </MuiThemeProvider>
          </form>
        )}
      />
    </>
  );

  return (
    <>
      <BreadCrumb location={t("CP-J-I-BREACRUMB-TITLE")} />
      <div className="secpadd">
        <div className="container">
          <div className="job-and-intern-button-container">
            <button
              onClick={() => setIsJob(true)}
              className={`job-and-intern-button ${
                isJob === true ? "choose-active" : ""
              }`}
            >
              {t("CP-APPLY-J-T")}
            </button>
            <button
              onClick={() => setIsJob(false)}
              className={`job-and-intern-button ${
                isJob === false ? "choose-active" : ""
              }`}
            >
              {t("CP-APPLY-I-T")}
            </button>
          </div>
          <div>{formTemp}</div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default JobAndInternShip;
