import React, { useState } from "react";
import BreadCrumb from '../Shared/BreadCrumb/BreadCrumb'
import { useTranslation } from 'react-i18next';
import './gst.css'
import LoginForm from "./LoginForm";

  function BayiSipariss() {

    const adminUser = {
      email:"admin@admin.com",
      password: "admin123"
    }

    const [user, setUser] = useState({name: "", email: ""});
    const [error, setError] = useState("");

    const Login = details => {
      console.log(details);
      
      if (details.email == adminUser.email && details.password == adminUser.password)
      console.log("Logged in");
      setUser({
        name: details.name,
        email: details.email
      });
      setError("Hatalı Giriş");
      
      
    }

    const Logout = () => {
      setUser({ name: "", email: ""});
    }


    return(
      <>
        <BreadCrumb location={('Sipariş Takibi')} />
        <div  style={{background : '#e8e8e8'}}>
      
       
        
      <div className="BayiSipariss">
        {(user.email != "") ? (
          <div className="welcome">
            <h2>Welcome, <span>{user.name}</span></h2>
            <button onClick={Logout}>Logout</button>
            </div>
        ) : (
          <LoginForm Login={Login} error={error} />
        )
        }
      
        
      </div>
      </div>
      </>
    );



  }

export default BayiSipariss
