import React, {useState} from 'react';
import { useMediaPredicate } from "react-media-hook";

import './ScrollArrow.css'


const ScrollArrow = () =>{

  const [showScroll, setShowScroll] = useState(false);
  const isMobile = useMediaPredicate("(max-width: 480px)");


  const checkScrollTop = () => {
    if (!showScroll && window.pageYOffset > 400){
      setShowScroll(true)
    } else if (showScroll && window.pageYOffset <= 400){
      setShowScroll(false)
    }
  };

  const scrollTop = () =>{
    window.scrollTo({top: 0, behavior: 'smooth'});
  };

  window.addEventListener('scroll', checkScrollTop)

  return (
        <i className={`scrollTop ${isMobile ?  "fas fa-arrow-circle-up fa-3x" : "fas fa-arrow-circle-up fa-4x"} `} onClick={scrollTop} style={{height: 40, display: showScroll ? 'flex' : 'none'}}></i>
  );
}

export default ScrollArrow;