import React from "react";
import { Link } from "react-router-dom";

function CardItem(props) {
  return (
    <div className="fh-service service-grid">
      <div className="service-list">
        <div className="item-service col-xs-12 col-sm-6 col-md-4">
          <Link to={props.link}>
          <div className="service-content">
            <div className="entry-thumbnail">
              <div
                className="overlay"
                style={{ backgroundColor: "rgba(172, 24, 120,0.5)" }}
              />
              <img className="box-shadow-fixed" src={props.image} alt={props.title} />
            </div>
            <h2 className="entry-title">{props.title}</h2>
            <p>{props.text}</p>
          </div>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default CardItem;
