import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { createStore, applyMiddleware } from "redux";
import { Provider } from "react-redux";
import thunk from "redux-thunk";
import reducers from "./pages/reducers/index";
import { createBrowserHistory } from "history";
import App from "./App";
import Preloader from "./components/Shared/Preloader/Preloader";
import { SelectedCountryProvider } from "./components/Services/SelectedCountryContext";
export const history = createBrowserHistory();

let createStoreWithMiddleware = applyMiddleware(thunk)(createStore);
let store = createStoreWithMiddleware(reducers);

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <Suspense fallback={<Preloader />}>
        <SelectedCountryProvider>
          <App />
        </SelectedCountryProvider>
      </Suspense>
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);
