import React from "react";
import BayiSipariss from "../components/BayiSiparis/BayiSipariss";
import { Helmet } from "react-helmet";

function BayiSiparis() {
  return (
    <>
      <Helmet>
        <title>Bayi Sipariş Seçim - YILMAZ REDÜKTÖR San. ve Tic. A.S</title>
        <link rel="canonical" href="https://yr.com.tr/bayi-siparis-secim" />
        <meta
          name="keywords"
          content="yılmaz reduktor redüktör, reduktor,dişli kutusu,disli kutusu,gearbox,gearunit,getriebe,getriebemotoren"
        />
       
      </Helmet>
      <BayiSipariss />
    </>
  );
}

export default BayiSiparis;
