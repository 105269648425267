const DealerCountryData = [
 

   
    {
        id : 1,
        pos : { lat: parseFloat('39.215674'), lng: parseFloat('36.0694096') },
        country : "COUNTRY-TURKEY",
        zoom: 6.5

    },
    {
        id : 2,
        pos : { lat: parseFloat('52.2984984'), lng: parseFloat('9.4899024') },
        country : "COUNTRY-GERMANY",
        zoom: 7

    },
    {
        id : 3,
        pos : { lat: parseFloat('42.7219285'), lng: parseFloat('24.9222369') },
        country : "COUNTRY-BULGARIA",
        zoom: 8

    },
    {
        id : 4,
        pos : { lat: parseFloat('47.1936134'), lng: parseFloat('1.9059492') },
        country : "COUNTRY-FRANCE",
        zoom: 6.5

    },
    {
        id : 5,
        pos : { lat: parseFloat('36.4909131'), lng: parseFloat('127.4925832') },
        country : "COUNTRY-SOUTHKOREA",
        zoom: 7.5

    },
    {
        id : 6,
        pos : { lat: parseFloat('52.2194625'), lng: parseFloat('5.7953968') },
        country : "COUNTRY-NETHERLANDS",
        zoom: 7.75

    },
    {
        id : 7,
        pos : { lat: parseFloat('44.5355943'), lng: parseFloat('16.6274004') },
        country : "COUNTRY-CROATIA",
        zoom: 7.5

    },
    {
        id : 8,
        pos : { lat: parseFloat('55.1762608'), lng: parseFloat('23.5478804') },
        country : "COUNTRY-LITHUANIA",
        zoom: 7.5

    },
    {
        id : 8,
        pos : { lat: parseFloat('34.1075888'), lng: parseFloat('43.9469241') },
        country : "COUNTRY-IRAQ",
        zoom: 6.75

    },
    {
        id : 9,
        pos : { lat: parseFloat('63.4744455'), lng: parseFloat('17.0298227') },
        country : "COUNTRY-SWEDEN",
        zoom: 5.25

    },
    {
        id : 9,
        pos : { lat: parseFloat('40.173099'), lng: parseFloat('-3.5510593') },
        country : "COUNTRY-SPAIN",
        zoom: 6.75

    },
    {
        id : 10,
        pos : { lat: parseFloat('53.0442856'), lng: parseFloat('0.2838977') },
        country : "COUNTRY-ENGLAND",
        zoom: 7

    },
    {
        id : 11,
        pos : { lat: parseFloat('31.3985808'), lng: parseFloat('36.4875535,7') },
        country : "COUNTRY-ISRAEL",
        zoom: 7.5

    },
    {
        id : 12,
        pos : { lat: parseFloat('48.5206224'), lng: parseFloat('70.3289885') },
        country : "COUNTRY-KAZAKHSTAN",
        zoom: 5.25

    },
    {
        id : 13,
        pos : { lat: parseFloat('27.2045164'), lng: parseFloat('28.2117622') },
        country : "COUNTRY-EGYPT",
        zoom: 6.25

    },
    {
        id : 14,
        pos : { lat: parseFloat('45.9570536'), lng: parseFloat('25.9003002') },
        country : "COUNTRY-ROMANIA",
        zoom: 7

    },
    {
        id : 15,
        pos : { lat: parseFloat('58.0722963'), lng: parseFloat('42.0074816') },
        country : "COUNTRY-RUSSIA",
        zoom: 6

    },
    {
        id : 16,
        pos : { lat: parseFloat('48.5417273'), lng: parseFloat('19.798029') },
        country : "COUNTRY-SLOVAKIA",
        zoom: 7.5

    },
    {
        id : 17,
        pos : { lat: parseFloat('13.2214639'), lng: parseFloat('104.467799') },
        country : "COUNTRY-THAILAND",
        zoom: 6

    },
    {
        id : 18,
        pos : { lat: parseFloat('39.215674'), lng: parseFloat('36.0694096') },
        country : "COUNTRY-TURKEY",
        zoom: 6.5

    },
    {
        id : 19,
        pos : { lat: parseFloat('39.484065'), lng: parseFloat('60.5023991') },
        country : "COUNTRY-TURKMENISTAN",
        zoom: 6.5

    },
    {
        id : 20,
        pos : { lat: parseFloat('49.1694364'), lng: parseFloat('30.7938337') },
        country : "COUNTRY-UKRAINE",
        zoom: 6.5

    },
    {
        id : 21,
        pos : { lat: parseFloat('39.2239526'), lng: parseFloat('23.1741953') },
        country : "COUNTRY-GREECE",
        zoom: 7.5

    },
    {
        id : 22,
        pos : { lat: parseFloat('49.7717574'), lng: parseFloat('15.1949657') },
        country : "COUNTRY-CZEC",
        zoom: 8

    },
    {
        id : 23,
        pos : { lat: parseFloat('41.2562417'), lng: parseFloat('64.8638759') },
        country : "COUNTRY-UZBEKISTAN",
        zoom: 6.5

    },
    {
        id : 24,
        pos : { lat: parseFloat('35.6913293'), lng: parseFloat('51.4245318') },
        country : "COUNTRY-IRAN",
        zoom: 6.5

    }
    


    

  
  

  
  ];
  export default DealerCountryData;
  