import React, { useState } from "react";
import { Document, Page , pdfjs  } from "react-pdf";
import './pdf.css'
import { useTranslation } from 'react-i18next';

import fileDownload from 'js-file-download'
import axios from 'axios'
import { useMediaPredicate } from "react-media-hook";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;


export default function SinglePdf(props) {
  const isMobile = useMediaPredicate("(max-width: 479px)"); // boyutlar düzenlenebilir
  var lang = localStorage.getItem("language")
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1); //setting 1 to show fisrt page
  const { t } = useTranslation();

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  function changePage(offset) {
    setPageNumber(prevPageNumber => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }
  const { pdf , pdfEnglish } = props;
  const handleDownload = () => {
    var result =   lang == "tr-TR" ? pdf : pdfEnglish;
    axios.get(result, {
      responseType: 'blob',
    })
    .then((res) => {
      fileDownload(res.data, result)
    })
  }


  return (
    <>
   {!isMobile ? <>
   
    <div style={{textAlign:'right' ,marginTop:'2rem'}}>  <button style={{marginRight:'0px'}}  className="pdf-button" onClick={() => handleDownload(`${pdf}`,`${pdf}`)}>{t("HP-WHY-DOWNLOAD")}</button></div>
    
    <Document
      file={lang == "tr-TR" ? pdf : pdfEnglish}

      onLoadSuccess={onDocumentLoadSuccess}
    >
      <Page scale={1.6}  pageNumber={pageNumber} />
    </Document>
    <div style={{textAlign:'center'}}>
      <p>
        {t("PAGE")} {pageNumber || (numPages ? 1 : "--")} / {numPages || "--"}
      </p>
      <button className="pdf-button" type="button" disabled={pageNumber <= 1} onClick={previousPage}>
        {t("PREVIOUS")}
      </button>
      <button  className="pdf-button"
        type="button"
        disabled={pageNumber >= numPages}
        onClick={nextPage}
      >
             {t("NEXT")}
      </button>
     
    </div>
   </> : 
   
   <>
    <div style={{textAlign:'center' ,marginTop:'2rem'}}>  <button style={{marginRight:'0px'}}  className="pdf-button" onClick={() => handleDownload()}>{t("HP-WHY-DOWNLOAD")}</button></div>
    
   
   </>}
  
    </>
  );
}
