import React from 'react';
import {Link} from 'react-router-dom'
import slug from 'slug'
import { useTranslation } from 'react-i18next';

function NewsItem(props) {
	const { t } = useTranslation();
	var slugTitle = (slug(props.title)) + '-' + props.id
	var propid = props.id;
	return (
		<div className="item-project">
			<div className="project-inner" style={{height:'478px', width: '478px'}}>
				<div className="overlay" />
				<Link to={`/haberler/${slugTitle}`} className="pro-link" aria-label="Yılmaz Redüktör Haberler" tabindex="-1">
					<span className="project-icon">
						<i className="fa fa-link" aria-hidden="true" tabindex="-1"/>
					</span>
				</Link>
				<div className="	">
					<img className="shadow-News"  src={t("YR-NEWS-IMG-"+slugTitle)} aria-label="Yılmaz Redüktör Haberler" alt={props.title} />
				</div>
				<div className="project-summary">
					<h2 className="project-title">
						<Link to={`/haberler/${slugTitle}`}>{props.title}</Link>
					</h2>
					{/* <div className="project-cat">
						<Link to="/urunler" className="cat">
							{props.cat}
						</Link>
						<Link to="/urunler" className="cat">
							{props.cat2}
						</Link>
					</div> */}
				</div>
			</div>
		</div>
	);
}

export default NewsItem;
