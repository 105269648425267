import React, { useState } from "react";
import BreadCrumb from "../Shared/BreadCrumb/BreadCrumb";

import { createMuiTheme, MuiThemeProvider } from "@material-ui/core";

import { Formik } from "formik";
import { makeStyles } from "@material-ui/core/styles";
import * as Yup from "yup";
import {
  TextField,
  Button,
  MenuItem,

} from "@material-ui/core";
import { useTranslation } from 'react-i18next';

import Grid from "@material-ui/core/Grid";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { fetchCommentOrClaim } from "../../api/agent";



export const customTheme = createMuiTheme({
    palette: {
      primary: {
        main: "#be057e",
      },
    },
  
    overrides: {
      MuiFormControlLabel: {
        label: {
          fontSize: "16px",
          fontFamily: "Monosettra",
        },
      },

      MuiButton: {
        root: {                
          "&:not($sizeLarge):not($sizeSmall) $label": {
            font: "normal normal 700 0.875rem/1.6875rem Open Sans",
            color: "green"
          }
        },
        sizeLarge: {
          "& $label": {
            font: "normal normal 700 1.5rem/2rem Montserrat",
          }
        },
        sizeSmall: {
          "& $label": {
            font: "normal normal 700 0.8125rem/1.6875rem Open Sans"
          }
        }
      }
    },



  
    typography: {
      fontFamily: ["Monosettra"],
    },
  });
  
  const useStyles = makeStyles((theme) => ({
    resize: {
      fontSize: 16,
    },
    root: {
      "& .MuiTextField-root": {
        width: "60ch",
      },
      "& .MuiSelect-root": {
        width: "60ch",
      },
    },
    smallRadioButton: {
      "& svg": {
        width: "2em",
        height: "2em",
      },
    },
    tickSize: {
      transform: "scale(1.5)",
    },
  }));
const SuggestionComplaint = () => {
    const classes = useStyles();
    const { t } = useTranslation();
  
    return (
        <>
             <BreadCrumb location={t("CP-REC-COMP-BC-T")}/>
             <div className="secpadd">
                 <div className="container">
                 <Formik
        initialValues={{
          fullName : "",
          companyName : "",
          email: "",
          phoneNumber: "",
          fax : "",
          message :'',
          messageType : "",
   

       
        }}
        validationSchema={Yup.object({
          fullName: Yup.string().required(t("COMMENT-OR-CLAIM-FULLNAME")),
          companyName : Yup.string().required(t("COMMENT-OR-CLAIM-COMPANYNAME")),
          message: Yup.string().required(t("COMMENT-OR-CLAIM-MESSAGE")),

        

          email: Yup.string()
            .email(t("COMMENT-OR-CLAIM-EMAIL"))
            .required(t("COMMENT-OR-CLAIM-EMAIL-R")),
            phoneNumber: Yup.string().required(t("COMMENT-OR-CLAIM-PHONE")),
            fax: Yup.string(),
         

            messageType: Yup.string()
            .required(t("COMMENT-OR-CLAIM-MESSAGETYPE"))
            .oneOf([
              t("COMMENT-OR-CLAIM-COMMENT"),
              t("COMMENT-OR-CLAIM-CLAIM"),
            ]),

       
          
        })}
        onSubmit=  { async (values, { resetForm, setSubmitting }) => {
        

         
    
  
          var result = await fetchCommentOrClaim(values);
        
          setTimeout(() => {
            setSubmitting(false);
            toast.success(
              `${values.fullName} ${t("COMMENT-OR-CLAIM-TOAST")}`,
              {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              }
            );
            resetForm();
          }, 2000);
        }}
        render={(props) => (
          <form className={classes.root} onSubmit={props.handleSubmit}>
          
       


          <MuiThemeProvider theme={customTheme}>
          <Grid  container justify="space-between">
              <TextField
                label={t("CP-APPLY-G-F-D-N")}
                style={{ marginBottom: "20px" }}
                InputProps={{ classes: { input: classes.resize } }}
                InputLabelProps={{ style: { fontSize: 20 } }}
                select
                id="messageType"
                name="messageType"
                value={props.values.messageType}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                helperText={
                  props.touched.messageType
                    ? props.errors.messageType
                    : ""
                }
                error={
                  props.touched.messageType &&
                  Boolean(props.errors.messageType)
                }
                FormHelperTextProps={{ style: { fontSize: 14 } }}
              >
                <MenuItem style={{ fontSize: "16px" }} value={t("COMMENT-OR-CLAIM-COMMENT")}>
                  {t("COMMENT-OR-CLAIM-COMMENT")}
                </MenuItem>
                <MenuItem style={{ fontSize: "16px" }} value={t("COMMENT-OR-CLAIM-CLAIM")}>
                {t("COMMENT-OR-CLAIM-CLAIM")}
                </MenuItem>
               
              </TextField>

              <TextField
                style={{ marginBottom: "20px" }}
                label={t("CP-APPLY-G-FULLNAME")}
                InputProps={{ classes: { input: classes.resize } }}
                InputLabelProps={{ style: { fontSize: 20 } }}
                name="fullName"
                id="fullName"
                type="text"
                helperText={props.touched.fullName ? props.errors.fullName : ""}
                error={props.touched.fullName && Boolean(props.errors.fullName)}
                onBlur={props.handleBlur}
                FormHelperTextProps={{ style: { fontSize: 14 } }}
                value={props.values.fullName}
                onChange={props.handleChange}
              />

              {/* <TextField
                style={{ marginBottom: "20px" }}
                label={t("CP-APPLY-G-S-K")}
                InputProps={{ classes: { input: classes.resize } }}
                InputLabelProps={{ style: { fontSize: 20 } }}
                select
                id="sikayetSebepleri"
                name="sikayetSebepleri"
                disabled = {props.values.formDoldurmaSebebi  !== '' ? false : true}
                value={props.values.sikayetSebepleri}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                helperText={
                  props.touched.sikayetSebepleri
                    ? props.errors.sikayetSebepleri
                    : ""
                }
                error={
                  props.touched.sikayetSebepleri &&
                  Boolean(props.errors.sikayetSebepleri)
                }
                FormHelperTextProps={{ style: { fontSize: 14 } }}
              >
                <MenuItem style={{ fontSize: "16px" }} value={"Sube Personel Sikayetleri"}>
                Şube Personel Şikayetleri
                </MenuItem>
                <MenuItem style={{ fontSize: "16px" }} value={"Gec Teslim"}>
                  Geç Teslim
                </MenuItem>
                <MenuItem style={{ fontSize: "16px" }} value={"Yanlis Adrese Teslim"}>
                Yanlış Adrese Teslim
                </MenuItem>

                <MenuItem style={{ fontSize: "16px" }} value={"Kayip / Calinti"}>
                Kayıp Çalıntı
                </MenuItem>

                <MenuItem style={{ fontSize: "16px" }} value={"Yanlis Eksik Bilgilendirme"}>
                Yanlış Eksik Bilgilendirme
                </MenuItem>

                <MenuItem style={{ fontSize: "16px" }} value={"Veri Gizliligi"}>
                Veri Gizliliği
                </MenuItem>
               
              </TextField> */}
            </Grid>


            <Grid  container justify="space-between">
              <TextField
                style={{ marginBottom: "20px" }}
                label={t("CP-APPLY-G-COMPANYNAME")}
                InputProps={{ classes: { input: classes.resize } }}
                InputLabelProps={{ style: { fontSize: 20 } }}
                name="companyName"
                id="companyName"
                type="text"
                helperText={props.touched.companyName ? props.errors.companyName : ""}
                error={props.touched.companyName && Boolean(props.errors.companyName)}
                onBlur={props.handleBlur}
                FormHelperTextProps={{ style: { fontSize: 14 } }}
                value={props.values.companyName}
                onChange={props.handleChange}
              />

              <TextField
                style={{ marginBottom: "20px" }}
                label={t("CP-APPLY-G-FAX")}
                InputProps={{ classes: { input: classes.resize } }}
                InputLabelProps={{ style: { fontSize: 20 } }}
                name="fax"
                id="fax"
                type="text"
                helperText={props.touched.fax ? props.errors.fax : ""}
                error={props.touched.fax && Boolean(props.errors.fax)}
                onBlur={props.handleBlur}
                FormHelperTextProps={{ style: { fontSize: 14 } }}
                value={props.values.fax}
                onChange={props.handleChange}
              />
            </Grid>

            <Grid  container justify="space-between">
              <TextField
                style={{ marginBottom: "20px" }}
                label={t("CP-APPLY-G-MAIL")}
                InputProps={{ classes: { input: classes.resize } }}
                InputLabelProps={{ style: { fontSize: 20 } }}
                name="email"
                id="email"
                type="text"
                helperText={props.touched.email ? props.errors.email : ""}
                error={props.touched.email && Boolean(props.errors.email)}
                onBlur={props.handleBlur}
                FormHelperTextProps={{ style: { fontSize: 14 } }}
                value={props.values.email}
                onChange={props.handleChange}
              />

              <TextField
                style={{ marginBottom: "20px" }}
                label={t("CP-APPLY-G-PHONE")}
                InputProps={{ classes: { input: classes.resize } }}
                InputLabelProps={{ style: { fontSize: 20 } }}
                name="phoneNumber"
                id="phoneNumber"
                type="text"
                helperText={props.touched.phoneNumber ? props.errors.phoneNumber : ""}
                error={props.touched.phoneNumber && Boolean(props.errors.phoneNumber)}
                onBlur={props.handleBlur}
                FormHelperTextProps={{ style: { fontSize: 14 } }}
                value={props.values.phoneNumber}
                onChange={props.handleChange}
              />
            </Grid>


       
            <Grid container justify="center">
              <TextField
                style={{ marginBottom: "20px" }}
                label={t("CP-APPLY-G-MESSAGE")}
                InputProps={{ classes: { input: classes.resize } }}
                InputLabelProps={{ style: { fontSize: 20 } }}
                name="message"
                id="message"
                type="text"
                multiline
                rows={2}
                rowsMax={4}
                variant="outlined"
                value={props.values.message}
                onBlur={props.handleBlur}
                helperText={props.touched.message ? props.errors.message : ""}
                error={props.touched.message && Boolean(props.errors.message)}
                onChange={props.handleChange}
                FormHelperTextProps={{ style: { fontSize: 14 } }}
              />
            </Grid>
        
         
          

              <Button
                type="submit"
                size="large"
                disabled={!props.dirty || props.isSubmitting}
                variant="contained"
                color  = "primary"
              >
               {t("CP-APPLY-G-SUBMIT")}
              </Button>
          </MuiThemeProvider>
       
      

          
              <ToastContainer />

          </form>
        )}
      />
                 </div>
             </div>
        </>
       
    )
}

export default SuggestionComplaint
