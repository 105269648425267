import React from 'react'

function NavbarM() {
    return (
        <>
        </>
    )
}

export default NavbarM
