import React, { useState, useEffect, useRef } from "react";

import {
  GoogleMap,
  useLoadScript,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";
import { useLocation } from "react-router-dom";
import {
  CountryDropdown,
  RegionDropdown,
  CountryRegionData,
} from "react-country-region-selector";
import { useMediaPredicate } from "react-media-hook";
import { useTranslation } from "react-i18next";
import { fetchDealersAll } from "../../api/agent";
import Select from "react-select";

import DealersCityPoint from "./DealersCityData";
import DealerCountryData from "./DealerCountryData";

import mapStyles from "./mapStyles";
import "./Dealers.css";
import { useSelectedCountry } from "./SelectedCountryContext";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
const libraries = ["places"];
const mapContainerStyle = {
  height: "100vh",
};

const mapContainerMobileStyle = {
  height: "60vh",
  marginTop: "30px",
};

const options = {
  styles: mapStyles,
  zoomControl: true,
};
const Dealers = (props) => {
  let history = useHistory();
  const { t } = useTranslation();
  const {
    selectedOption,
    handleSet,
    countryList,
    setOptionsList,
    selectedOptionCity,
    handleSetCity,
    clickedWhere,
    setClickedWherePro,
  } = useSelectedCountry();

  const [dealers, setDealers] = useState([]);

  const isLargeScreen = useMediaPredicate("(max-width: 991px)");
  const mapRefScroll = useRef();
  const [mapRef, setMapRef] = useState(null);
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [markerMap, setMarkerMap] = useState({});
  const [center, setCenter] = useState({ lat: 41.0321781, lng: 28.6268251 });
  const [zoom, setZoom] = useState(10);
  const [infoOpen, setInfoOpen] = useState(false);
  const [infoPlace, setInfoPlace] = useState(null);
  const [country, setCountry] = useState("");
  const [region, setRegion] = useState("");
  // const [countryList, setCountryList] = useState([]);
  const [cityList, setCityList] = useState([]);

  const [markerSelecting, setmarkerSelecting] = useState(false);
  
  // const [selectedOption, setSelectedOption] = useState({
  //   label: "Türkiye",
  //   value: "COUNTRY-TURKEY",
  // });
  // const [selectedOptionCity, setSelectedOptionCity] = useState({
  //   label: "İstanbul",
  //   value: "İstanbul",
  // });


  const getAllDealers = async () => {
    var filtered = [];
    const allDealers = await fetchDealersAll();

    var result = allDealers.data;
    filtered = result .map((item) => {
      return {
        id: item.id,
        pos: {
          lat: parseFloat(item.latitude),
          lng: parseFloat(item.longitude),
        },
        sellerName: item.sellerName,
        address: item.address,
        phone: item.phone,
        fax: item.fax,
        city: item.city,
        country: item.country,
        webSite: item.webSite,
        email: item.email,
        reduktor: item.reduktor,
        surucu: item.surucu,
        surucuEgitimi: item.surucuEgitimi,
      };
    });

    setDealers(filtered);
  };

  const getCityList = async () => {
    var filterCity = [];
    var filteredCity = [];
    const allCountryList = await fetchDealersAll();
    var result = allCountryList.data;

    if (clickedWhere === "domestic") {
      filterCity = result.filter((x) => x.country === "COUNTRY-TURKEY");
      filteredCity = [...new Set(filterCity.map((item) => item.city))];
      setCityList(filteredCity);
    } else {
      filterCity = result.filter((x) => x.country == selectedOption.value);
      filteredCity = [...new Set(filterCity.map((item) => item.city))];
      setCityList(filteredCity);
    }
  };

  useEffect(() => {
    getAllDealers();
    getCityList();
  }, [t, selectedOption.value, clickedWhere]);

  const filtered = countryList.sort((a,b) => a.label - b.label).map((item) => {
    return {
      label: t(item),
      value: item,
    };
  });

  const filteredCityData = cityList.sort((a,b) => a.label - b.label).map((item) => {
    return {
      label: t(item),
      value: item,
    };
  });


  // {console.log(

  //   filtered.sort(  function(a, b) {
  //     var atitle = a.label;
  //     var btitle = b.label;
  //     var alfabe = "AaBbCcÇçDdEeFfGgĞğHhIıİiJjKkLlMmNnOoÖöPpQqRrSsŞşTtUuÜüVvWwXxYyZz0123456789";
  //     if (atitle.length === 0 || btitle.length === 0) {
  //         return atitle.length - btitle.length;
  //     }
  //     for(var i=0;i<atitle.length && i<btitle.length;i++){
  //         var ai = alfabe.indexOf(atitle[i]);
  //         var bi = alfabe.indexOf(btitle[i]);
  //         if (ai !== bi) {
  //             return ai - bi;
  //         }
  //     }
  // }).map((item) => {
      
  //   return {
  //     label: t(item),
  //     value: item,
  //   };
    
  //   })
  // )}



 

  const cityFiltered = cityList.map((item) => {
    return {
      label: item,
      value: item,
    };
  });

  // useEffect(() => {
  //   if (countryList.length > 0) {
  //     let urlParamCountry = filtered.filter(
  //       (item) => item.label === urlCountry
  //     )[0];

  //     setSelectedOption(urlParamCountry);
  //   }
  // }, [filtered]);

  useEffect(() => {
    let label = "seçiniz";
    if (clickedWhere === "abroad") {
      if (selectedOption.label === "Türkiye") {
        cityFiltered.length > 0 && handleSetCity("dealer", cityFiltered[6]);
      } else {
        cityFiltered.length > 0 && handleSetCity("dealer", "");
      }
    } else {
      if (selectedOption.label === "Türkiye") {
        cityFiltered.length > 0 && handleSetCity("dealer", selectedOptionCity);
      } else {
        cityFiltered.length > 0 && handleSetCity("dealer", cityFiltered[0]);
      }
    }
  }, [cityList]);

  const [currentPosition, setCurrentPosition] = useState({});

  const markerSelectedHandler = (place) => {
    // Remember which place was clicked
    setZoom(place.zoom);

    mapRefScroll.current.scrollIntoView({ behavior: "smooth" });
    setSelectedPlace(place);

    // // Required so clicking a 2nd marker works as expected
    // if (infoOpen) {
    //   setInfoOpen(false);
    // }

    // setInfoOpen(true);

    // If you want to zoom in a little on marker click
    // if (zoom < 13) {
    // }

    // if you want to center the selected Marker
    setCenter(place.pos);
  };

  useEffect(() => {
    if (markerSelecting === false && infoOpen === false) {
      if (DealerCountryData.length  && mapRefScroll.current !== undefined) {
        DealerCountryData.map((data) => {
          if (data.country === selectedOption.value) {
            markerSelectedHandler(data);
          }
        });
        DealersCityPoint.map((data) => {
          if (data.city === history.location.state.country1) {
            markerSelectedHandler(data);
          }
        });
      }
    }
  });

  useEffect(() => {
    history.push({
      ...history.location,
      state: { country1: selectedOptionCity.value },
    });
  }, [selectedOptionCity]);

  const handleSelectCountry = (val) => {
    setCountry(val);
  };

  const icon = {
    url: "assets/images/map/YR.png",
    scaledSize: { width: 48, height: 48 },
  };

  const handleSelectRegion = (val) => {
    setRegion(val);
  };

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyBEg6bVNcrkvbDMSgZjCmfRjODjlq-We3Y",
    libraries,
  });

  if (loadError) return "Error loading maps";
  if (!isLoaded) return "Loading Maps";

  const fitBounds = (map) => {
    const bounds = new window.google.maps.LatLngBounds();

    dealers.map((place) => {
      bounds.extend(place.pos);
      return place.id;
    });
    map.fitBounds(bounds);
  };

  const loadHandler = (map) => {
    // Store a reference to the google map instance in state
    setMapRef(map);
    // Fit map bounds to contain all markers
    // fitBounds(map);
  };

  // We have to create a mapping of our places to actual Marker objects
  const markerLoadHandler = (marker, place) => {
    return setMarkerMap((prevState) => {
      return { ...prevState, [place.id]: marker };
    });
  };

  const markerClickHandler = (event, place) => {
    //console.log(event, place)
    // Remember which place was clicked
    mapRefScroll.current.scrollIntoView({ behavior: "smooth" });
    setSelectedPlace(place);
    setInfoPlace(place);

    // Required so clicking a 2nd marker works as expected
    if (infoOpen) {
      setInfoOpen(false);
    }

    setInfoOpen(true);

    // If you want to zoom in a little on marker click
    if (zoom < 13) {
      setZoom(14);
    }

    // if you want to center the selected Marker
    setCenter(place.pos);
  };

  const markerChangeHandler = (event, place) => {
    // Remember which place was clicked
    mapRefScroll.current.scrollIntoView({ behavior: "smooth" });
    handleSetCity("dealer", place);

    // Required so clicking a 2nd marker works as expected
    if (infoOpen) {
      setInfoOpen(false);
    }

    setInfoOpen(true);

    // If you want to zoom in a little on marker click
    if (zoom < 13) {
      setZoom(14);
    }

    // if you want to center the selected Marker
    setCenter(place.pos);
  };

  const setSelectedOptionToContext = (e) => {
    handleSet(e);
  };

 // console.log(infoOpen, selectedPlace, infoPlace)

  return (
    <>
      <div class="block-location col-xs-24 col-md-24 col-lg-24 col-xl-24">
        <div class="content-location col-xs-12 col-md-5 col-lg-4 col-xl-4">
          <div class="block-address">
            <div
              onClick={() =>
                navigator.geolocation.getCurrentPosition(function (position) {
                  const currentPosition = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                  };
                  setCurrentPosition(currentPosition);
                })
              }
              class="content-button-сontacts"
            >
              <a class="resource">{t("SCP-LOCU")}</a>
              <i
                style={{ color: "#be057e" }}
                class="fas fa-location-arrow fa-2x"
              ></i>
            </div>

            <div style={{ width: "90%" }}>
              <Select
                value={selectedOption}
                options={filtered.sort(  function(a, b) {
                  var atitle = a.label;
                  var btitle = b.label;
                  var alfabe = "AaBbCcÇçDdEeFfGgĞğHhIıİiJjKkLlMmNnOoÖöPpQqRrSsŞşTtUuÜüVvWwXxYyZz0123456789";
                  if (atitle.length === 0 || btitle.length === 0) {
                      return atitle.length - btitle.length;
                  }
                  for(var i=0;i<atitle.length && i<btitle.length;i++){
                      var ai = alfabe.indexOf(atitle[i]);
                      var bi = alfabe.indexOf(btitle[i]);
                      if (ai !== bi) {
                          return ai - bi;
                      }
                  }
              })}
                onChange={(event) => {
                  setClickedWherePro("abroad");
                  setSelectedOptionToContext(event);
                  setmarkerSelecting(false);
                }}
              />
            </div>

            <div style={{ width: "90%", marginTop: "1em" }}>
              <Select
                placeholder={t("DEALER-SELECT-CITY")}
                value={selectedOptionCity}
                //options={cityFiltered}
                options={filteredCityData.sort(  function(a, b) {
                  var atitle = a.label;
                  var btitle = b.label;
                  var alfabe = "AaBbCcÇçDdEeFfGgĞğHhIıİiJjKkLlMmNnOoÖöPpQqRrSsŞşTtUuÜüVvWwXxYyZz0123456789";
                  if (atitle.length === 0 || btitle.length === 0) {
                      return atitle.length - btitle.length;
                  }
                  for(var i=0;i<atitle.length && i<btitle.length;i++){
                      var ai = alfabe.indexOf(atitle[i]);
                      var bi = alfabe.indexOf(btitle[i]);
                      if (ai !== bi) {
                          return ai - bi;
                      }
                  }
              })}
                onChange={(event) => {
                  setClickedWherePro("abroad");
                  handleSetCity("dealer", event);
                  setmarkerSelecting(false);
                  // DealersCityPoint.map((data) => {
                  //   if (data.city === event.value) {
                  //     {
                  //       markerSelectedHandler(data);
                  //     }
                  //   }
                  // });
                }}
              />
            </div>

            <div style={{ width: "90%", marginTop: "5%" }}>
              <p style={{ fontSize: "1vw !important" }}>
                {
                   
                      selectedOption?.label == t("COUNTRY-TURKEY") 
                      ? 
                
                      dealers.filter(
                    (places) =>
                      places.country.includes(selectedOption?.value) 
                      &&  places.city.includes(selectedOptionCity?.value)
                  ).length 
                    
                  :
                  selectedOptionCity?.value == null ?
                  dealers.filter(
                    (places) =>
                      places.country.includes(selectedOption?.value) 
                      //&&  places.city.includes(selectedOptionCity?.value)
                  ).length 
                  
                  : 

                  dealers.filter(
                    (places) =>
                      places.country.includes(selectedOption?.value) 
                      &&  places.city.includes(selectedOptionCity?.value)
                  ).length 


                }{" "}
                {t("SCP-RESULT")}
                {/* {(dealers.filter(places => places.country.includes(selectedOption.value)) && places.city.includes(selectedOptionCity.value).length)} {t("SCP-RESULT")} */}
              </p>
            </div>

            {!isLargeScreen && selectedOption?.label == t("COUNTRY-TURKEY") ? (

              
              <>
                {/* MarkerClickHandler */}
                {dealers
                  .filter(
                    (places) =>
                      places.country.includes(selectedOption?.value)
                      &&   places.city.includes(selectedOptionCity?.value)
                  ).sort(function(a, b) {
                    var atitle = a.sellerName;
                    var btitle = b.sellerName;
                    var alfabe = "AaBbCcÇçDdEeFfGgĞğHhIıİiJjKkLlMmNnOoÖöPpQqRrSsŞşTtUuÜüVvWwXxYyZz0123456789";
                    if (atitle.length === 0 || btitle.length === 0) {
                        return atitle.length - btitle.length;
                    }
                    for(var i=0;i<atitle.length && i<btitle.length;i++){
                        var ai = alfabe.indexOf(atitle[i]);
                        var bi = alfabe.indexOf(btitle[i]);
                        if (ai !== bi) {
                            return ai - bi;
                        }
                    }
                })
                  .map((place) => {
                    return (
                      <div
                        onClick={(event) => {setmarkerSelecting(true); markerClickHandler(event, place);}}
                        style={{ cursor: "pointer" }}
                      >
                        <div
                          style={{
                            background: "#fafafa",
                            margin: "5% 0%",
                            width: "90%",
                            height: "35vh",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "column",
                            lineHeight: "1.4"
                          }}
                        >
                          <p style={{ textAlign: "center" }}>
                            {place.sellerName}
                          </p>
                          <p style={{ textAlign: "center" }}>
                            <i
                              style={{ marginRight: "10px" }}
                              class="fas fa-map-marker-alt"
                            ></i>
                            {place.address}
                          </p>
                          <p style={{ textAlign: "center" }}>
                            <i
                              style={{ marginRight: "10px" }}
                              class="fas fa-phone-alt"
                            ></i>
                            {place.phone}
                          </p>
                          <p style={{ textAlign: "center", textTransform:'lowercase' }}>
                            <i
                              style={{ marginRight: "10px" }}
                              class="fas fa-envelope"
                            ></i>
                            {place.email}
                          </p> 
                          {<p style={{ textAlign: "center", textTransform:'lowercase' }}>
                            <i
                              style={{ marginRight: "10px" }}
                              class="fas fa-globe"
                            ></i>
                            {place.webSite}
                          </p>}
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              marginBottom: "20px",
                            }}
                          >
                            {place.reduktor==true ? (
                              <div
                                class="tooltipx"
                                style={{ marginRight: "20px" }}
                              >
                                <img
                                  style={{ maxHeight: "48px" }}
                                  src="assets/images/map/bayi.png"
                                />
                                <span class="tooltiptextx">{t("SCP-Y-R-B")}</span>
                              </div>
                            ) : null}
                            {place.surucu ? (
                              <div
                                class="tooltipx"
                                style={{ marginRight: "20px" }}
                              >
                                <img
                                  style={{ maxHeight: "48px" }}
                                  src="assets/images/map/sürücü.png"
                                />
                                <span class="tooltiptextx">{t("SCP-Y-S-B")}</span>
                              </div>
                            ) : null}

                            {place.surucuEgitimi ? (
                              <div
                                class="tooltipx"
                                style={{ marginRight: "20px" }}
                              >
                                <img
                                  style={{ maxHeight: "48px" }}
                                  src="assets/images/map/sürücü teknik servis.png"
                                />
                                <span class="tooltiptextx">{t("SCP-S-T-S")}</span>
                              </div>
                            ) : null}
                          </div>

                          {/* <div style={{display:'flex', width:'90%' , justifyContent:'space-between'}}>
      <i class="fab fa-instagram">/yilmazredüktör</i>
      <i class="fab fa-linkedin-in">/yilmazredüktör</i>
      <i class="fab fa-twitter">/yilmazredüktör</i>
    </div> */}
                        </div>

                        <div></div>
                      </div>
                    );
                  })}
              </>
            )  :
            !isLargeScreen && selectedOption?.label !== t("COUNTRY-TURKEY") && selectedOptionCity?.value == null ?
            (

              
              <>
                {/* MarkerClickHandler */}
                {dealers
                  .filter(
                    (places) =>
                      places.country.includes(selectedOption?.value)
                      //&&   places.city.includes(selectedOptionCity?.value)
                  )
                  .map((place) => {
                    return (
                      <div   
                        onClick={(event) => {setmarkerSelecting(true); markerClickHandler(event, place);}}
                        style={{ cursor: "pointer" }}
                      >
                        <div
                          style={{
                            background: "#fafafa",
                            margin: "5% 0%",
                            width: "90%",
                            height: "35vh",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "column",
                            lineHeight: "1.4"
                          }}
                        >
                          <p style={{ textAlign: "center" }}>
                            {place.sellerName}
                          </p>
                          <p style={{ textAlign: "center" }}>
                            <i
                              style={{ marginRight: "10px" }}
                              class="fas fa-map-marker-alt"
                            ></i>
                            {place.address}
                          </p>
                          <p style={{ textAlign: "center" }}>
                            <i
                              style={{ marginRight: "10px" }}
                              class="fas fa-phone-alt"
                            ></i>
                            {place.phone}
                          </p>
                          <p style={{ textAlign: "center", textTransform:'lowercase' }}>
                            <i
                              style={{ marginRight: "10px" }}
                              class="fas fa-envelope"
                            ></i>
                            {place.email}
                          </p>
                          <p style={{ textAlign: "center", textTransform:'lowercase' }}>
                            <i
                              style={{ marginRight: "10px" }}
                              class="fas fa-globe"
                            ></i>
                            {place.webSite}
                          </p>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              marginBottom: "20px",
                            }}
                          >
                            {place.reduktor==true ? (
                              <div
                                class="tooltipx"
                                style={{ marginRight: "20px" }}
                              >
                                <img
                                  style={{ maxHeight: "48px" }}
                                  src="assets/images/map/bayi.png"
                                />
                                <span class="tooltiptextx">{t("SCP-Y-R-B")}</span>
                              </div>
                            ) : null}
                            {place.surucu ? (
                              <div
                                class="tooltipx"
                                style={{ marginRight: "20px" }}
                              >
                                <img
                                  style={{ maxHeight: "48px" }}
                                  src="assets/images/map/sürücü.png"
                                />
                                <span class="tooltiptextx">{t("SCP-Y-S-B")}</span>
                              </div>
                            ) : null}

                            {place.surucuEgitimi ? (
                              <div
                                class="tooltipx"
                                style={{ marginRight: "20px" }}
                              >
                                <img
                                  style={{ maxHeight: "48px" }}
                                  src="assets/images/map/sürücü teknik servis.png"
                                />
                                <span class="tooltiptextx">{t("SCP-S-T-S")}</span>
                              </div>
                            ) : null}
                          </div>

                          {/* <div style={{display:'flex', width:'90%' , justifyContent:'space-between'}}>
      <i class="fab fa-instagram">/yilmazredüktör</i>
      <i class="fab fa-linkedin-in">/yilmazredüktör</i>
      <i class="fab fa-twitter">/yilmazredüktör</i>
    </div> */}
                        </div>

                        <div></div>
                      </div>
                    );
                  })}
              </>
            )
            :
            !isLargeScreen && selectedOption?.label !== t("COUNTRY-TURKEY") && selectedOptionCity?.value !== null ?
            (

              
              <>
                {/* MarkerClickHandler */}
                {dealers
                  .filter(
                    (places) =>
                      places.country.includes(selectedOption?.value)
                      &&   places.city.includes(selectedOptionCity?.value)
                  )
                  .map((place) => {
                    return (
                      <div
                        onClick={(event) => {setmarkerSelecting(true); markerClickHandler(event, place);}}
                        style={{ cursor: "pointer" }}
                      >
                        <div
                          style={{
                            background: "#fafafa",
                            margin: "5% 0%",
                            width: "90%",
                            height: "35vh",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "column",
                            lineHeight: "1.4"
                          }}
                        >
                          <p style={{ textAlign: "center" }}>
                            {place.sellerName}
                          </p>
                          <p style={{ textAlign: "center" }}>
                            <i
                              style={{ marginRight: "10px" }}
                              class="fas fa-map-marker-alt"
                            ></i>
                            {place.address}
                          </p>
                          <p style={{ textAlign: "center" }}>
                            <i
                              style={{ marginRight: "10px" }}
                              class="fas fa-phone-alt"
                            ></i>
                            {place.phone}
                          </p>
                          <p style={{ textAlign: "center", textTransform:'lowercase' }}>
                            <i
                              style={{ marginRight: "10px" }}
                              class="fas fa-envelope"
                            ></i>
                            {place.email}
                          </p>
                          <p style={{ textAlign: "center", textTransform:'lowercase' }}>
                            <i
                              style={{ marginRight: "10px" }}
                              class="fas fa-globe"
                            ></i>
                            {place.webSite}
                          </p>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              marginBottom: "20px",
                            }}
                          >
                            {place.reduktor==true ? (
                              <div
                                class="tooltipx"
                                style={{ marginRight: "20px" }}
                              >
                                <img
                                  style={{ maxHeight: "48px" }}
                                  src="assets/images/map/bayi.png"
                                />
                                <span class="tooltiptextx">{t("SCP-Y-R-B")}</span>
                              </div>
                            ) : null}
                            {place.surucu ? (
                              <div
                                class="tooltipx"
                                style={{ marginRight: "20px" }}
                              >
                                <img
                                  style={{ maxHeight: "48px" }}
                                  src="assets/images/map/sürücü.png"
                                />
                                <span class="tooltiptextx">{t("SCP-Y-S-B")}</span>
                              </div>
                            ) : null}

                            {place.surucuEgitimi ? (
                              <div
                                class="tooltipx"
                                style={{ marginRight: "20px" }}
                              >
                                <img
                                  style={{ maxHeight: "48px" }}
                                  src="assets/images/map/sürücü teknik servis.png"
                                />
                                <span class="tooltiptextx">{t("SCP-S-T-S")}</span>
                              </div>
                            ) : null}
                          </div>

                          {/* <div style={{display:'flex', width:'90%' , justifyContent:'space-between'}}>
      <i class="fab fa-instagram">/yilmazredüktör</i>
      <i class="fab fa-linkedin-in">/yilmazredüktör</i>
      <i class="fab fa-twitter">/yilmazredüktör</i>
    </div> */}
                        </div>

                        <div></div>
                      </div>
                    );
                  })}
              </>
            )
            : 
            isLargeScreen && selectedOption?.label === t("COUNTRY-TURKEY") ?
            (
              <>
                {dealers
                  .filter(
                    (places) =>
                      places.country.includes(selectedOption?.value) &&
                      places.city.includes(selectedOptionCity?.value)
                  )
                  .map((place) => (
                    <div>
                      <div className="info-dealer">
                        <p style={{ textAlign: "center" }}>
                          {place.sellerName}
                        </p>
                        <p style={{ textAlign: "center" }}>
                          <i
                            style={{ marginRight: "10px" }}
                            class="fas fa-map-marker-alt"
                          ></i>
                          {place.address}
                        </p>
                        <p style={{ textAlign: "center" }}>
                          <i
                            style={{ marginRight: "10px" }}
                            class="fas fa-phone-alt"
                          ></i>
                          {place.phone}
                        </p>
                        <p style={{ textAlign: "center"}}>
                          <i
                            style={{ marginRight: "10px" }}
                            class="fas fa-envelope"
                          ></i>
                          {place.email}
                        </p>
                        <p style={{ textAlign: "center", textTransform:'lowercase' }}>
                            <i
                              style={{ marginRight: "10px" }}
                              class="fas fa-globe"
                            ></i>
                            {place.webSite}
                          </p>
                        <div style={{ display: "flex" }}>
                          <button
                            onClick={(event) =>
                              markerClickHandler(event, place)
                            }
                            
                            style={{
                              cursor: "pointer",
                              flex: 1,
                              margin: "10px",
                              padding: "1em 1.5em",
                            }}
                          >
                            {t("SCP-MAP-SHW")}
                          </button>
                          <button
                            style={{
                              flex: 1,
                              margin: "10px",
                              padding: "1em 1.5em",
                            }}
                          >
                            {" "}
                            {t("SCP-ADDRESS")}
                          </button>
                        </div>
                      </div>

                      <div></div>
                    </div>
                  ))}
              </>
            ) :
            (
              <>
                {dealers
                  .filter(
                    (places) =>
                      places.country.includes(selectedOption?.value) 
                      //&&      places.city.includes(selectedOptionCity?.value)
                  )
                  .map((place) => (
                    <div>
                      <div className="info-dealer">
                        <p style={{ textAlign: "center" }}>
                          {place.sellerName}
                        </p>
                        <p style={{ textAlign: "center" }}>
                          <i
                            style={{ marginRight: "10px" }}
                            class="fas fa-map-marker-alt"
                          ></i>
                          {place.address}
                        </p>
                        <p style={{ textAlign: "center" }}>
                          <i
                            style={{ marginRight: "10px" }}
                            class="fas fa-phone-alt"
                          ></i>
                          {place.phone}
                        </p>
                        <p style={{ textAlign: "center"}}>
                          <i
                            style={{ marginRight: "10px" }}
                            class="fas fa-envelope"
                          ></i>
                          {place.email}
                        </p>
                        <p style={{ textAlign: "center", textTransform:'lowercase' }}>
                            <i
                              style={{ marginRight: "10px" }}
                              class="fas fa-globe"
                            ></i>
                            {place.webSite}
                          </p>
                        <div style={{ display: "flex" }}>
                          <button
                            onClick={(event) =>
                              markerClickHandler(event, place)
                            }
                            
                            style={{
                              cursor: "pointer",
                              flex: 1,
                              margin: "10px",
                              padding: "1em 1.5em",
                            }}
                          >
                            {t("SCP-MAP-SHW")}
                          </button>
                          <button
                            style={{
                              flex: 1,
                              margin: "10px",
                              padding: "1em 1.5em",
                            }}
                          >
                            {" "}
                            {t("SCP-ADDRESS")}
                          </button>
                        </div>
                      </div>

                      <div></div>
                    </div>
                  ))}
              </>
            )}
          </div>
        </div>

        <div
          ref={mapRefScroll}
          class="content-map col-xs-12 col-md-7 col-lg-8 col-xl-8"
        >
          {isLoaded ? null : <div class="preloaderModal">Yükleniyor</div>}

          <GoogleMap
            mapContainerStyle={
              isLargeScreen ? mapContainerMobileStyle : mapContainerStyle
            }
            zoom={currentPosition.lat ? 12 : zoom}
            onLoad={loadHandler}
            center={currentPosition.lat ? currentPosition : center}
            options={options}
          >
            {country !== "" && region !== ""
              ? // dealers.filter(places => places.country.includes(country) && places.city.includes(region)).map(place => (
                
                    dealers.map((place) => (
                      <Marker
                        key={place.id}
                        position={place.pos}
                        onLoad={(marker) => markerLoadHandler(marker, place)}
                        onClick={(event) => markerClickHandler(event, place)}
                        onChange={(event) => markerChangeHandler(event, place)}
                        // Not required, but if you want a custom icon:
                        icon={icon}
                      />
                    ))
              : dealers.filter(
                        (places) =>
                          places.country.includes(selectedOption?.value) 
                          &&  places.city.includes(selectedOptionCity?.value)
                      )
                      .map((place) => (
                        <Marker
                          key={place.id}
                          position={place.pos}
                          onLoad={(marker) => markerLoadHandler(marker, place)}
                          onClick={(event) => markerClickHandler(event, place)}
                          onChange={(event) => markerChangeHandler(event, place)}
                          // Not required, but if you want a custom icon:
                          icon={icon}
                        />
                      ))
                  }

{country !== "" && region === ""
              ? // dealers.filter(places => places.country.includes(country) && places.city.includes(region)).map(place => (
                
                    dealers.map((place) => (
                      <Marker
                        key={place.id}
                        position={place.pos}
                        onLoad={(marker) => markerLoadHandler(marker, place)}
                        onClick={(event) => markerClickHandler(event, place)}
                        onChange={(event) => markerChangeHandler(event, place)}
                        // Not required, but if you want a custom icon:
                        icon={icon}
                      />
                    ))
              : dealers.filter(
                        (places) =>
                          places.country.includes(selectedOption?.value) 
                         // &&  places.city.includes(selectedOptionCity?.value)
                      )
                      .map((place) => (
                        <Marker
                          key={place.id}
                          position={place.pos}
                          onLoad={(marker) => markerLoadHandler(marker, place)}
                          onClick={(event) => markerClickHandler(event, place)}
                          onChange={(event) => markerChangeHandler(event, place)}
                          // Not required, but if you want a custom icon:
                          icon={icon}
                        />
                      ))
                  }

            {currentPosition.lat && <Marker position={currentPosition} />}

            
            {infoOpen && selectedPlace && infoPlace &&  (
              <InfoWindow
                anchor={markerMap[infoPlace.id]}
                onCloseClick={() => setInfoOpen(false)}
              >
                <div className="block-info">
                  <div className="content-info">
                    <p className="header-info">{infoPlace.sellerName}</p>
                    <p>{infoPlace.address}</p>
                    <div className="region-block">
                      <p>
                        {t(infoPlace.city)}/{t(infoPlace.country)}
                      </p>
                    </div>

                    <p>T: {infoPlace.phone}</p>
                    <p>F: {infoPlace.fax}</p>
                    <p>E: {infoPlace.email}</p>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        marginBottom: "20px",
                      }}
                    >
                      {infoPlace.reduktor==true ? (
                        <div class="tooltipx" style={{ marginRight: "20px" }}>
                          <img
                            style={{ maxHeight: "48px" }}
                            src="assets/images/map/bayi.png"
                          />
                          <span style={{ width: "140px" }} class="tooltiptextx">
                          {t("SCP-Y-R-B")}
                          </span>
                        </div>
                      ) : null}
                      {infoPlace.surucu ? (
                        <div class="tooltipx" style={{ marginRight: "20px" }}>
                          <img
                            style={{ maxHeight: "48px" }}
                            src="assets/images/map/sürücü.png"
                          />
                          <span style={{ width: "140px" }} class="tooltiptextx">
                          {t("SCP-Y-S-B")} 
                          </span>
                        </div>
                      ) : null}

                      {infoPlace.surucuEgitimi ? (
                        <div class="tooltipx">
                          <img
                            style={{ maxHeight: "48px" }}
                            src="assets/images/map/sürücü teknik servis.png"
                          />
                          <span style={{ width: "140px" }} class="tooltiptextx">
                          {t("SCP-S-T-S")} 
                          </span>
                        </div>
                      ) : null}
                    </div>
                    <p className="resource ">
                      <a
                        className="route-text"
                        target="_blank"
                        href={`https://www.google.com.tr/maps/dir//${infoPlace.pos.lat},${infoPlace.pos.lng}/`}
                      >
                        {t("SCP-ADDRESS")}
                      </a>
                    </p>
                  </div>
                </div>
              </InfoWindow>
            )}
            
          </GoogleMap>
        </div>
      </div>
    </>
  );
};

export default Dealers;
