const ServicePointData = [
 


    {
        id : 1,
        latitude : "41.0345297",
        longitude : "28.6241967",
       
        sellerName : "Yılmaz Redüktör",
        address : "Atatürk, Lozan Cd. No:17, 34522 Esenyurt/İstanbul",
        phone : "0 212 886 90 00",
        fax : "0090 212 886 54 57",
        city : "İstanbul",
        country : "COUNTRY-TURKEY",
        webSite : "http://www.yr.com.tr/",
        email : "yilmaz@yr.com.tr",
       

      
    },

  
  

  
  ];
  export default ServicePointData;
  