import React, { useState } from 'react';
import {Link} from 'react-router-dom'
function PartnerItem(props) {
	const [isHover , setIsHover] = useState(false);


	const toggleHover = () => {
		setIsHover(!isHover);
	}
	var changeImage;
	if(isHover){
		changeImage = props.hoverImage;
	}
	else{
		changeImage = props.image;
	}
	return (
        
		<div className="partner-item">
			<div  onMouseEnter={toggleHover} onMouseLeave={toggleHover} className="partner-content">
				<Link to={{pathname:props.path}} target="_blank">
					<img className="box-shadow-fixed" src={changeImage} alt={props.name} />
				</Link>
			</div>
		</div>
	);
}

export default PartnerItem;
