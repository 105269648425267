import React, { useState } from "react";
import NavbarItem from "./NavbarItem/NavbarItem";
import navbarCategories from "./navbarCategories";
import { useTranslation } from "react-i18next";
function NavbarCategory(props) {
  const [navbarCategory, setNavbarCategory] = useState(
    props.menuFirstCategoryId
  );
  const onMouseEnter = (id) => {
    setNavbarCategory(id);
  };
  const { t } = useTranslation();
  return (
    <>
      <div className="box-content-generalReduktor">
        {navbarCategories &&
          navbarCategories.map((data, key) => {
            return data.menuId === props.menuId ? (
              <div key={key}>

<div className="box-content-section-arrow-navbarCategory">
<span
                  className={
                    navbarCategory === data.categoryId
                      ? "box-content-category-active"
                      : "box-content-category"
                  }
                  onMouseEnter={() => onMouseEnter(data.categoryId)}
                >
                  {t(data.categoryName)}
                </span>
                  {navbarCategory === data.categoryId && <img alt="arrow"  src="/assets/images/bg/Arrow.png" className="nav-arrow-category"></img>}
                  </div>
              
                {navbarCategory === data.categoryId ? (
                  <NavbarItem
                    categoryFirstItem={data.categoryFirstItem}
                    categoryId={navbarCategory}
                  />
                ) : (
                  <div />
                )}
              </div>
            ) : null;
          })}
      </div>
    </>
  );
}

export default NavbarCategory;
