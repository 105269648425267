import React from "react";
import "./ScrollbarMenu.css";
import {Link} from 'react-router-dom'
import {Link as LinkScroll} from 'react-scroll'

function ScrollbarMenu() {
  return (
    <div>
      {/* <Link to="/a"> <div className="scroll-item personel "><i className="fa fa-user" aria-hidden="true"></i></div></Link> */}
      <LinkScroll to="goContact" spy={true} smooth={true}><div className="scroll-item phone "><i className="fa fa-phone" aria-hidden="true" tabindex={-1}></i></div></LinkScroll>
      <Link to="/reduktor-secim"><div className="scroll-item gst "><img src="/assets/images/icons/gstanasayfa.png" alt="Redüktör Seçim Sayfası"></img></div></Link>
      <Link to="/bayi-siparis"><div className="scroll-item bayisiparis "><img src="/assets/images/icons/bayi.png" alt="Bayi Giriş Sayfası"></img></div></Link>
      
    </div>
  );
}

export default ScrollbarMenu;
