import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import SinglePagePDFViewer from "../../pdf/single-pdf";
import { useTranslation } from "react-i18next";

import "./iconMenuDetail.css";
import { fetchTipsheetById, fetchTipsheets } from "../../../api/agent";
import BreadCrumb from "../../Shared/BreadCrumb/BreadCrumb";
import IconMenuItem from "./IconMenuItem/IconMenuItem";
import Slider from "react-slick";
import { useMediaPredicate } from "react-media-hook";
import { Helmet } from "react-helmet";

function IconMenuDetail() {
  let { slugTitle } = useParams();
  var slugTitleSplit = slugTitle.split("-");
  var id = slugTitleSplit[slugTitleSplit.length - 1];
  const { t } = useTranslation();

  const isMobile = useMediaPredicate("(max-width: 479px)");
  const [tipSheet, setTipsheet] = useState([]);

  const [iconMenuDetail, setIconMenuDetail] = useState();

  var countIconMenu = tipSheet.length;

  var settingsMobile = {
    dots: true,
    nextArrow: <NextArrowMobile />,
    prevArrow: <PrevArrowMobile />,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: countIconMenu > 4 ? 4 : countIconMenu,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  function NextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "block",
          right: "-30px",
          zIndex: "9999999",
        }}
        onClick={onClick}
      />
    );
  }

  function PrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", left: "-50px", zIndex: "9999999" }}
        onClick={onClick}
      />
    );
  }

  function NextArrowMobile(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "none", right: "-10px", zIndex: "9999999" }}
        onClick={onClick}
      />
    );
  }

  function PrevArrowMobile(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "none", left: "-20px", zIndex: "9999999" }}
        onClick={onClick}
      />
    );
  }

  useEffect(() => {
    const getIconMenuDetail = async () => {
      const iconItemDetail = await fetchTipsheetById(id);
      var result = iconItemDetail.data;
      setIconMenuDetail(result);
    };
    getIconMenuDetail();
  }, [id]);

  useEffect(() => {
    const getTipsheet = async () => {
      const allTipsheet = await fetchTipsheets();
      var result = allTipsheet.data;
      setTipsheet(result.filter((x) => x.id != id));
    };
    getTipsheet();
  }, [id]);

  if (iconMenuDetail != undefined) {
    var iconMenuDetailContent = (
      <>
        {" "}
        <h3 className="iconMenuDetail-title">{t(iconMenuDetail.title)}</h3>
        <div className="iconMenuDetail-content">
          <p>{iconMenuDetail.description}</p>
          <SinglePagePDFViewer
            pdfEnglish={iconMenuDetail.pdfEnglishPath}
            pdf={iconMenuDetail.pdfPath}
          />
        </div>
      </>
    );
  }

  return (
    <>
      <Helmet>
        <title>Faydalı Bilgiler - YILMAZ REDÜKTÖR San. ve Tic. A.S</title>
        <meta
          name="keywords"
          content="yılmaz reduktor redüktör, reduktor,dişli kutusu,disli kutusu,gearbox,gearunit,getriebe,getriebemotoren"
        />
       
      </Helmet>
      <BreadCrumb location={t("NEWS-P-B-HABER")} />
      <div className="secpadd">
        <div className="container">
          <div className="iconMenuDetail-container">{iconMenuDetailContent}</div>
          <h2 style={{ marginTop: "30px" }} className="news-header">
            <span>{t("OTHER-INFORMATION")}</span>
          </h2>
          <div className="container padding-slick-container">
            <div className="row">
              {isMobile ? (
                <>
                  <Slider {...settingsMobile}>
                    {tipSheet.map((data, key) => {
                      return (
                        <IconMenuItem
                          isDetail={true}
                          id={data.id}
                          key={key}
                          title={data.title}
                          image={data.imagePath}
                        />
                      );
                    })}
                  </Slider>
                </>
              ) : (
                <>
                  <Slider {...settings}>
                    {tipSheet.map((data, key) => {
                      return (
                        <IconMenuItem
                          isDetail={true}
                          id={data.id}
                          key={key}
                          title={data.title}
                          image={data.imagePath}
                        />
                      );
                    })}
                  </Slider>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default IconMenuDetail;
