import React from "react";
import JobAndInternShip from "../components/JobAndInternShip/JobAndInternShip";
import { Helmet } from "react-helmet";

const JobAndInternShipPage = () => {
  return (
    <>
      <Helmet>
        <title>İş ve Staj Başvuru - YILMAZ REDÜKTÖR San. ve Tic. A.S</title>
        <link rel="canonical" href="https://yr.com.tr/is-ve-staj-basvuru" />
        <meta
          name="keywords"
          content="yılmaz reduktor redüktör, reduktor,dişli kutusu,disli kutusu,gearbox,gearunit,getriebe,getriebemotoren"
        />
        
      </Helmet>
      <JobAndInternShip />
    </>
  );
};

export default JobAndInternShipPage;
