import React, { useContext, useState, createContext } from "react";

const SelectedCountryContext = createContext();

export function useSelectedCountry() {
  return useContext(SelectedCountryContext);
}

export function SelectedCountryProvider({ children }) {
  const [selectedOption, setSelectedOption] = useState({
    label: "Türkiye",
    value: "COUNTRY-TURKEY",
  });

  const [selectedOptionCity, setSelectedOptionCity] = useState({
    label: "İstanbul",
    value: "İstanbul",
  });

  const [countryList, setCountryList] = useState([]);

  const [clickedWhere, setClickedWhere] = useState('abroad'); // domestic

  const handleSet = (val) => {
    setSelectedOption(val);
  };

  const handleSetCity = (type, val) => {
    if (type === "footer") {
      setSelectedOptionCity({
        label: val,
        value: val,
      });
    } else if (type === "dealer") {
        setSelectedOptionCity(val)
    }
  };


  const setOptionsList = (data) => {
    setCountryList(data);
  };

  const setClickedWherePro = (type) => {
    setClickedWhere(type);
  }

  const value = {
    selectedOption,
    handleSet,
    countryList,
    setOptionsList,
    selectedOptionCity,
    handleSetCity,
    clickedWhere,
    setClickedWherePro
  };

  return (
    <SelectedCountryContext.Provider value={value}>
      {children}
    </SelectedCountryContext.Provider>
  );
}
