import React , {useState , useEffect, useRef} from 'react'

import {GoogleMap , useLoadScript , Marker , InfoWindow} from '@react-google-maps/api'
import { CountryDropdown, RegionDropdown, CountryRegionData } from 'react-country-region-selector';
import { useMediaPredicate } from "react-media-hook";
import { useTranslation } from 'react-i18next';
import { fetchDealersAll } from '../../api/agent'
import Select from "react-select";


import mapStyles from './mapStyles'
import "./Dealers.css"
import ServicePointData from './ServicePointData';
const libraries = ["places"];
const mapContainerStyle = {
  height : "100vh",
}

const mapContainerMobileStyle = {
  height : "60vh",
  marginTop : "30px"
}

const options = {
  styles : mapStyles,
  zoomControl : true
}
const ServicePoints = () => {
  const { t } = useTranslation();

  const [dealers, setDealers] = useState([]);

  const isLargeScreen = useMediaPredicate("(max-width: 991px)");
  const mapRefScroll = useRef();
  const [mapRef, setMapRef] = useState(null);
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [markerMap, setMarkerMap] = useState({});
  const [center, setCenter] = useState({ lat: 41.0321781, lng: 28.6268251 });
  const [zoom, setZoom] = useState(13);
  const [infoOpen, setInfoOpen] = useState(false);

  const [country , setCountry] = useState('')
  const [region , setRegion] = useState('')
  const [countryList , setCountryList] = useState([]);
  const [cityList , setCityList] = useState([])
  const [selectedOption, setSelectedOption] = useState({label: "Türkiye" , value: "COUNTRY-TURKEY"});
  const [selectedOptionCity, setSelectedOptionCity] = useState({label: "İstanbul" , value: "İstanbul"});


  const getAllDealers = async() => {
    var filtered = [];
    
 
    filtered = ServicePointData.map(item => {
      return {
        id : item.id,
        pos : {lat : parseFloat(item.latitude) , lng: parseFloat(item.longitude)},
        sellerName : item.sellerName,
        address : item.address,
        phone : item.phone,
        fax : item.fax,
        city : item.city,
        country : item.country,
        webSite : item.webSite,
        email : item.email,
        reduktor : item.reduktor,
        surucu : item.surucu,
        surucuEgitimi : item.surucuEgitimi

      }
    })
    
    setDealers(filtered);
   
  }


  const getCountryList = async() => {
    var filtered = [];
 
    filtered = [...new Set(ServicePointData.map(item=>(item.country)))]
    setCountryList(filtered);
   
  }


  const getCityList = async() => {
    var filterCity = [];
    var filteredCity = [];
  
    filterCity = ServicePointData.filter(x=>x.country == selectedOption.value)
    filteredCity = [...new Set(filterCity.map(item=>item.city))]
    setCityList(filteredCity)
  }
  
  useEffect(() => {
    getAllDealers();
    getCountryList();
    getCityList();
  }, [t,selectedOption.value])



  const filtered = countryList.map(item => {
    return {
      label : t(item),
      value : item
    }
  })

  const cityFiltered = cityList.map(item => {
    return {
      label : item,
      value : item
    }
  })


  const [ currentPosition, setCurrentPosition ] = useState({});

 
  const handleSelectCountry = (val) =>{
    setCountry(val);
  } 

  const icon = { url: 'assets/images/map/YR.png', scaledSize: { width: 48, height: 48 } };

  
  const handleSelectRegion = (val) =>{
    setRegion(val);
  } 
  
  const {isLoaded , loadError} = useLoadScript({
    googleMapsApiKey : "AIzaSyBEg6bVNcrkvbDMSgZjCmfRjODjlq-We3Y",
    libraries
  })

  if(loadError) return "Error loading maps";
  if(!isLoaded) return "Loading Maps";

   


  


 
  const fitBounds = map => {
    const bounds = new window.google.maps.LatLngBounds();

    dealers.map(place => {
      bounds.extend(place.pos);
      return place.id;
    });
    map.fitBounds(bounds);

   


  };

  const loadHandler = map => {

   
    setMapRef(map);
  
  };


   
   const markerLoadHandler = (marker, place) => {
    return setMarkerMap(prevState => {
      return { ...prevState, [place.id]: marker };
    });
    
  };

  const markerClickHandler = (event, place) => {
   
    mapRefScroll.current.scrollIntoView({ behavior: 'smooth' })
    setSelectedPlace(place);

   
    if (infoOpen) {
      setInfoOpen(false);
    }

    setInfoOpen(true);

    
    if (zoom < 13) {
      setZoom(14);
    }

   
    setCenter(place.pos)
  };





  return (
    < >
    <div class="block-location col-xs-24 col-md-24 col-lg-24 col-xl-24">

    <div class="content-location col-xs-12 col-md-5 col-lg-4 col-xl-4">
      <div class="block-address">
      <div onClick={() => navigator.geolocation.getCurrentPosition(function(position){
        const currentPosition = {lat : position.coords.latitude , lng: position.coords.longitude }
        setCurrentPosition(currentPosition)
      })}  
      
      class="content-button-сontacts"><a  class="resource">{t("SCP-LOCU")}</a>
      <i style={{color : '#be057e'}} class="fas fa-location-arrow fa-2x"></i>
       </div>



      <div style={{width:'90%'}}>
        <Select
            defaultValue={selectedOption}
            onChange={setSelectedOption}
            options={filtered}
          />
      </div>


      <div style={{width:'90%', marginTop:'1em'}}>
        <Select
            defaultValue={selectedOptionCity}
            onChange={setSelectedOptionCity}
            options={cityFiltered}
          />

      </div>






      <div style={{width: '90%', marginTop: '5%'}}>
        <p style={{    fontSize: '1vw !important'}}>
          {(dealers.filter(places => places.country.includes(selectedOption.value) && places.city.includes(selectedOptionCity.value)).length)} {t("SCP-RESULT")}
          {/* {(dealers.filter(places => places.country.includes(selectedOption.value)) && places.city.includes(selectedOptionCity.value).length)} {t("SCP-RESULT")} */}
          
        </p>
      </div>
      

      {!isLargeScreen ?  
      
      
      <>
       {dealers.filter(places => places.country.includes(selectedOption.value) && places.city.includes(selectedOptionCity.value)).map(place => (
      

<div  onClick={event => markerClickHandler(event, place)} style={{cursor:'pointer'}}>
  <div style={{background: '#fafafa' , margin : '5% 0%' , width:'100%' ,height:'55vh', display: 'flex' , justifyContent : 'center' , alignItems : 'center' , flexDirection : 'column'}}>
    <p style={{textAlign:'center',margin: '0 0 15px'}}>  <strong>- {place.sellerName} - </strong></p>
    <p style={{textAlign:'center',margin: '0 0 5px'}}><i style={{marginRight:'10px'}}  class="fas fa-map-marker-alt"></i>{place.address}</p>
    <p style={{textAlign:'center',margin: '0 0 5px'}}><i  style={{marginRight:'10px'}} class="fas fa-phone-alt"></i>{place.phone}</p>
    <p style={{textAlign:'center',margin: '0 0 15px', textTransform:'lowercase'}}><i style={{marginRight:'10px'}} class="fas fa-envelope"></i>{place.email}</p>
    <p style={{textAlign:'center',margin: '0 0 5px'}}><i style={{marginRight:'10px'}} ></i> <strong>- {t("SCP-SERVICE-TITLE")} -</strong></p>
    <p style={{textAlign:'center',margin: '0 0 5px'}}><i style={{marginRight:'10px'}} class="fas fa-phone-alt"></i>{t("SCP-SERVICE-NUMBER")}</p>
    <p style={{textAlign:'center',margin: '0 0 5px'}}><i style={{marginRight:'10px'}} class="fab fa-whatsapp"></i>{t("SCP-SERVICE-WHATSAPP")}</p>
    <p style={{textAlign:'center',margin: '0 0 15px'}}><i style={{marginRight:'10px'}} class="fas fa-envelope"></i>{t("SCP-SERVICE-MAIL")}</p>
    <p style={{textAlign:'center',margin: '0 0 5px'}}><i style={{marginRight:'10px'}} class="fas fa-whatsapp"></i> <strong>- {t("SCP-YEDEK-TITLE")} - </strong></p>
    <p style={{textAlign:'center',margin: '0 0 5px'}}><i style={{marginRight:'10px'}} class="fas fa-phone-alt"></i>{t("SCP-YEDEK-NUMBER")}</p>
    <p style={{textAlign:'center',margin: '0 0 5px'}}><i style={{marginRight:'10px'}} class="fab fa-whatsapp"></i>{t("SCP-YEDEK-WHATSAPP")}</p>
    <p style={{textAlign:'center',margin: '0 0 5px'}}><i style={{marginRight:'10px'}} class="fas fa-envelope"></i>{t("SCP-YEDEK-MAIL")}</p>
    
  </div>
  
  <div>

  </div>
</div>
))}
      
      </> 
      
      
      
      
      :
      
      
      
      <>
      
      
      {dealers.filter(places => places.country.includes(selectedOption.value) && places.city.includes(selectedOptionCity.value)).map(place => (

<div  >
  <div className="info-dealer">
    <p style={{textAlign:'center'}}>{place.sellerName}</p>
    <p style={{textAlign:'center'}}><i style={{marginRight:'10px'}}  class="fas fa-map-marker-alt"></i>{place.address}</p>
    <p style={{textAlign:'center'}}><i  style={{marginRight:'10px'}} class="fas fa-phone-alt"></i>{place.phone}</p>
    <p style={{textAlign:'center', textTransform:'lowercase'}}><i style={{marginRight:'10px'}} class="fas fa-envelope"></i>{place.email}</p>
    <div style={{display  : 'flex'}} >
      <button onClick={event => markerClickHandler(event, place)} style={{cursor:'pointer'}}  style={{flex : 1 , margin:'10px',    padding: '1em 1.5em'}}>{t("SCP-MAP-SHW")}</button>
      <button style={{flex : 1 , margin:'10px' ,     padding: '1em 1.5em'}} > {t("SCP-ADDRESS")}</button>
    </div>


    
  </div>
  
  <div>

  </div>
</div>
))}
      </>}
     
     

      

 
      </div>
    </div>

    




    <div ref={mapRefScroll} class="content-map col-xs-12 col-md-7 col-lg-8 col-xl-8">

    {isLoaded ?  null : (<div class="preloaderModal">Yükleniyor</div>)}

    
<GoogleMap 
   mapContainerStyle={isLargeScreen ? mapContainerMobileStyle :  mapContainerStyle}
   zoom={currentPosition.lat ? 12 : zoom}
   onLoad={loadHandler}
   center={currentPosition.lat ? currentPosition : center}
   options={options}
   
   >
  
  {country !== '' && region !== '' ?  
  
 
    dealers.map(place => (
     
    <Marker

    key={place.id}
    position={place.pos}
    onLoad={marker => markerLoadHandler(marker, place)}
    onClick={event => markerClickHandler(event, place)}
  
    icon={
      icon
    }
    
  />
  ))

  :


  dealers.filter(places => places.country.includes(selectedOption.value) && places.city.includes(selectedOptionCity.value)).map(place => (
    <Marker
    key={place.id}
    position={place.pos}
    onLoad={marker => markerLoadHandler(marker, place)}
    onClick={event => markerClickHandler(event, place)}
   
    icon={
      icon
     
    }
    
  />
  ))

}
    
     

    {currentPosition.lat && (
      <Marker position={currentPosition}
      />
    )}


   


    

     {infoOpen && selectedPlace && (
       <InfoWindow
         anchor={markerMap[selectedPlace.id]}
         onCloseClick={() => setInfoOpen(false)}
       >
         <div className="block-info">
           <div className="content-info">
             <p className="header-info">{selectedPlace.sellerName}</p>
             <p>{selectedPlace.address}</p>
             <div className="region-block">
               <p>{t(selectedPlace.city)}/{t(selectedPlace.country)}</p>
             </div>

             <p>T: {selectedPlace.phone}</p>
             <p>F: {selectedPlace.fax}</p>
             <p>E: {selectedPlace.email}</p>
          
             <p className="resource ">
               <a className="route-text" target="_blank" href={`https://www.google.com.tr/maps/dir//${selectedPlace.pos.lat},${selectedPlace.pos.lng}/`}>{t("SCP-ADDRESS")}</a>
             </p>
           
           </div>
         </div>
       </InfoWindow>
     )}
 </GoogleMap>
</div>
    </div>
   
 
   
       
    </>
  )
}

export default ServicePoints
