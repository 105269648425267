import React from "react";
import {Link} from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import axios from 'axios'
import fileDownload from 'js-file-download'

function NavbarSubMenu(props) {
  const { t } = useTranslation();
  var lang = 'tr-TR'
  const handleDownload = () => {
    var result =   lang == "tr-TR" ? "/assets/docs/politikalar.pdf" : "/assets/docs/politikalar.pdf";
    axios.get(result, {
      responseType: 'blob',
    })
    .then((res) => {
      fileDownload(res.data, "politikalar.pdf")
    })
  }
  const navbarSubMenuData = [
  
   
   
    {
      name : "NB-CP-SUB-1",
      navbarId:6,
      path : '/tarihce',
      pdf:false
      
    },
    {
      name : "NB-CP-SUB-2",
      navbarId:6,
      path : '/misyonumuz',
      pdf:false
    },
    {
      name : "NB-CP-SUB-3",
      navbarId:6,
      path : '/sirketlerimiz',
      pdf:false
    },
    {
      name : "NB-CP-SUB-4",
      navbarId:6,
      path : '/politikalarımız',
      pdf : true
    },
    
    {
      name : "SCP-CST-SER",
      navbarId : 7,
      path : '/musteri-hizmetleri',
      pdf:false
    },

    {
      name : "NV-DEALERS",
      navbarId : 7,
      path : '/satis-ve-servisler',
      pdf:false
    },

    {
      name : "NV-FAQ",
      navbarId : 7,
      path : '/faq',
      pdf:false
    },

    {
      name : "NB-CT-SUB-1",
      navbarId : 7,
      path : '/is-ve-staj-basvuru',
      pdf:false
    },

    {
      name : "NB-CT-SUB-2",
      navbarId : 7,
      path : '/oneri-sikayet-ve-istek',
      pdf:false
    },

  

  ];
  return (
    <>
      {navbarSubMenuData.map((data, key) => {
        if(data.navbarId === props.navbarId && data.pdf == true){
          return (
            <li key={key}>
            <a href="/assets/docs/politikalar.pdf">{t("NB-CP-SUB-4")}</a>

          </li>
          )
        }
      
        return data.navbarId === props.navbarId && data.pdf == false ? (
          <li key={key}>
            <Link to={data.path}>{t(data.name)}</Link>
          </li>
        ) : null;
      })}
    </>
  );
}

export default NavbarSubMenu;
