import React from "react";
import BreadCrumb from "../Shared/BreadCrumb/BreadCrumb";
import {Link} from 'react-router-dom'
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import { useMediaPredicate } from "react-media-hook";
import { useTranslation } from 'react-i18next';


import "./GroupCompoanies.css";
const GroupCompanies = () => {
  const isLargeScreen = useMediaPredicate("(max-width: 991px)");
  const isSmallScreen = useMediaPredicate("(max-width: 768px)");
  const { t } = useTranslation();

  return (
    <>
      <BreadCrumb location={t("CP-B-T")} />
      <div className="secpadd">
        <div className="container custom-group-company">
          <div
            style={{
              background: "rgb(241, 241, 241)",
              boxShadow: "0px 5px 10px 0px rgb(50 50 50 / 75%)",
              margin: "0px 0px 50px 0px",
            }}
            className="row row-height"
          >
            <div className="col-sm-2 col-custom pr0">
              <div style={{ alignItems: "center" }} className="img-box-group-company">
                <img
                  src="assets/images/logo/yr.png"
                  alt="about-1"
                />
              </div>
            </div>
            <div className="col-sm-10 col-custom flex-custom-groupCompany">
              <div className="groupCompanyContent">
                {isLargeScreen ? (
                  <>
                    <Accordion allowZeroExpanded>
                      <AccordionItem>
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            <h1 className="groupCompanyName">
                             {isSmallScreen ? t("CP-YR-MOBILE"): t("CP-YR-L")} 
                            </h1>
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          {t("CP-YR-D")}
                          <a className="company-link" href="https://www.yr.com.tr" target="_blank">
                            <span>www.yr.com.tr</span>
                          </a>
                        </AccordionItemPanel>
                      </AccordionItem>
                    </Accordion>
                  </>
                ) : (
                  <>
                    <h1 className="groupCompanyName">
                    {t("CP-YR-L")}
                    </h1>

                    <p className="groupCompanyText">
                      {t("CP-YR-D")}
                    </p>
                    <a className="company-link" href="https://www.yr.com.tr" target="_blank">
                            <span>www.yr.com.tr</span>
                          </a>
                  </>
                )}
              </div>
            </div>
          </div>

        
         
         
         
         
         
         
         
          <div
            style={{
              background: "rgb(241, 241, 241)",
              boxShadow: "0px 5px 10px 0px rgb(50 50 50 / 75%)",
              margin: "0px 0px 50px 0px",
            }}
            className="row row-height"
          >
            <div className="col-sm-2 col-custom pr0">
              <div style={{ alignItems: "center" }} className="img-box-group-company">
                <img
                  src="assets/images/logo/mes.png"
                  alt="about-1"
                />
              </div>
            </div>
            <div className="col-sm-10 col-custom flex-custom-groupCompany">
              <div className="groupCompanyContent">


              {isLargeScreen ? <>
                    
                    <Accordion allowZeroExpanded>
          
          <AccordionItem>
              <AccordionItemHeading>
                  <AccordionItemButton>
                  <h1 className="groupCompanyName">
                  {isSmallScreen ? t("CP-MES-MOBILE") : t("CP-MES-L")} 
                            </h1>
                  
                  </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                {t("CP-MES-D")}
                  <a className="company-link" href="https://www.mesdokum.com.tr" target="_blank">
                            <span>www.mesdokum.com.tr</span>
                          </a>
              </AccordionItemPanel>
          </AccordionItem>
   
  </Accordion>
 
                
                </> 
                
                :  <>
                
                
                <h1 className="groupCompanyName">
                {t("CP-MES-L")}

                </h1>

                <p className="groupCompanyText">
                {t("CP-MES-D")}

                </p>

                <a className="company-link" href="https://www.mesdokum.com.tr" target="_blank">
                            <span>www.mesdokum.com.tr</span>
                          </a>
                
                </>}


              
          
              </div>
            </div>
          </div>

          <div
            style={{
              background: "rgb(241, 241, 241)",
              boxShadow: "0px 5px 10px 0px rgb(50 50 50 / 75%)",
              margin: "0px 0px 50px 0px",
            }}
            className="row row-height"
          >
            <div className="col-sm-2 col-custom pr0">
              <div style={{ alignItems: "center" }} className="img-box-group-company">
                <img
                  src="assets/images/logo/elk.png"
                  alt="about-1"
                />
              </div>
            </div>
            <div className="col-sm-10 col-custom flex-custom-groupCompany">
              <div className="groupCompanyContent">

              {isLargeScreen ? <>
                    
                    <Accordion allowZeroExpanded>
          
          <AccordionItem>
              <AccordionItemHeading>
                  <AccordionItemButton>

                  <h1 className="groupCompanyName">
                  {isSmallScreen ? t("CP-ELK-MOBILE") : t("CP-ELK-L") } 
                            </h1>

                  
                  </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                {t("CP-ELK-D")}
                  <a className="company-link" href="https://www.elkmotor.com.tr" target="_blank">
                            <span>www.elkmotor.com.tr</span>
                          </a>
              </AccordionItemPanel>
          </AccordionItem>
   
  </Accordion>
 
                
                </> 
                
                :  <>
                
                <h1 className="groupCompanyName">
                {t("CP-ELK-L")}
                </h1>

               
                <p className="groupCompanyText">
                {t("CP-ELK-D")}
                </p>
                <a className="company-link" href="http://www.elkmotor.com.tr" target="_blank">
                            <span>www.elkmotor.com.tr</span>
                          </a>
                </>}



               
            
              </div>
            </div>
          </div>
       
       
       
        

       
       
       
        </div>
      </div>
    </>
  );
};

export default GroupCompanies;
