const cardData = [
	{
		image: 'assets/images/card/detay1.webp',
		title: 'HP-USE-AREA-T-1',
		text: 'HP-USE-AREA-D-1',
		id: '1',
		link: '/cozumler/vinc'
    },
    {
		image: 'assets/images/card/detay2.webp',
		title: 'HP-USE-AREA-T-2',
		text: 'HP-USE-AREA-D-2',
		id: '2',
		link: '/cozumler/plastikvekaucuk'
    },
    {
		image: 'assets/images/card/detay3.webp',
		title: 'HP-USE-AREA-T-3',
		text: 'HP-USE-AREA-D-3',
		id: '3',
		link: '/cozumler/tasimasistemleri'
    },
    {
		image: 'assets/images/card/detay4.webp',
		title: 'HP-USE-AREA-T-4',
		text: 'HP-USE-AREA-D-4',
		id: '4',
		link: '/cozumler/karistirici'
    },
    {
		image: 'assets/images/card/detay5.webp',
		title: 'HP-USE-AREA-T-5',
		text: 'HP-USE-AREA-D-5',
		id: '5',
		link: '/cozumler/ruzgarenerjisi'
    },
    {
		image: 'assets/images/card/detay6.webp',
		title: 'HP-USE-AREA-T-6',
		text: 'HP-USE-AREA-D-6',
		id: '6',
		link: '/cozumler/raylisistemler'
	}
];
export default cardData;
