import React from "react";
import { useMediaPredicate } from "react-media-hook";
import { useTranslation } from "react-i18next";
 const BreadCrumb = (props) => {
  const isMobile = useMediaPredicate("(max-width: 1024px)");
  const { t } = useTranslation();
  return (
    <div
      className="page-header title-area style-2"
      style={{ marginTop : isMobile ? "72px" : "103px"  }}
    >
      
      <div className="breadcrumb-area " style={{backgroundColor: "#f1f1f1" , display:"flex" , justifyContent:"center" , alignItems:"center" , padding:"10px 0px"}}>
      <hr/>
        <div className="container"  >
          <div className="row">
            <div className="col-md-6 col-sm-6 col-xs-12" >
              <nav className="breadcrumb">
              
                <a className="home" href="/">
                  <span>{t("BC-HP")}</span>
                </a>
                <i className="fa fa-angle-right" aria-hidden="true" tabindex="-1"></i>
                <span>{props.location}</span>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default BreadCrumb;
