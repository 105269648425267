import React, { Suspense } from "react";
import Navbar from "./components/Shared/Navbar/Navbar";
import Footer from "./components/Shared/Footer/Footer";
import Home from "./pages/Home";
import Videos from "./pages/Videos";


import { BrowserRouter as Router, Route, Switch , Link } from "react-router-dom";
import Preloader from "./components/Shared/Preloader/Preloader";
import ScrollbarMenu from "./components/Shared/ScrollbarMenu/ScrollbarMenu";
import ScrollToTop from "./components/ScrollToTop"

import { useMediaPredicate } from "react-media-hook";
import ScrollArrow from "./components/ScrollArrow/ScrollArrow";
import Services from "./pages/Services";
import Company from "./pages/Company";
import MissionPage from "./pages/MissionPage";
import GroupCompaniesPage from "./pages/GroupCompaniesPage";
import JobAndInternShipPage from "./pages/JobAndInternShipPage";
import SuggestionComplaintPage from "./pages/SuggestionComplaintPage";
import NewsDetail from "./pages/NewsDetail";

import CustomerServices from "./components/Services/CustomerServices";
import Error404 from "./pages/Error404";
import IconMenuItem from "./components/HomePage/IconMenu/IconMenuItem/IconMenuItem";
import IconMenuDetail from "./components/HomePage/IconMenu/IconMenuDetail";
import {createBrowserHistory} from 'history';
import Gst from "./pages/Gst";
import BayiSiparis from "./pages/BayiSiparis";
import YrApp from "./pages/YrApp";
import QuickPicture from "./pages/QuickPicture";
import B2B from "./components/B2B/B2B";
import FAQ from "./components/FAQ/FAQ";
import SeriNoSorgula from "./pages/SeriNoSorgula";

export const history = createBrowserHistory();
const Products  = React.lazy(() =>  import("./pages/Products"));
const ProductDetail = React.lazy(() => import("./pages/ProductDetail"));
const Solutions = React.lazy(() => import("./pages/Solutions"));
const SolutionsDetails = React.lazy(() => import("./pages/SolutionsDetails"));
const News = React.lazy(() => import("./pages/News"));
const Documents = React.lazy(() => import("./pages/Documents"));
const Otomasyon = React.lazy(() => import("./pages/Otomasyon"));
const Docs = React.lazy(() => import("./pages/Docs"));


function RouteNavbarWithFooter({component : Component , ...rest}){
  const isMobile = useMediaPredicate("(max-width: 1024px)");

  return (
    <Route {...rest}>
       <ScrollToTop/>
        <Navbar/>
        {!isMobile ?  <ScrollbarMenu /> : null }
        <Component/>
        <ScrollArrow/>
        <Footer />
    </Route>
  )
}

function App() {


  const isMobile = useMediaPredicate("(max-width: 1024px)");
  return (
    <div>
      <Router history={history}>
         
        <Switch>
          <RouteNavbarWithFooter path="/" exact component={Home} />
          <RouteNavbarWithFooter path="/urunler" exact component={Products}/>
          <RouteNavbarWithFooter path="/urun/:id" exact component={ProductDetail}/>
          <RouteNavbarWithFooter path="/cozumler" exact component={Solutions}/>
          <RouteNavbarWithFooter path="/cozumler/:id" exact component={SolutionsDetails}/>
          <RouteNavbarWithFooter path="/haberler" exact component={News}/>
          <RouteNavbarWithFooter path="/videolar" exact component={Videos}/>
          <RouteNavbarWithFooter path="/dokuman" exact component={Documents}/>
          <RouteNavbarWithFooter path="/dokumanotomasyon" exact component={Otomasyon}/>
          <RouteNavbarWithFooter path="/satis-ve-servisler" exact component={Services}/>
          <RouteNavbarWithFooter path="/faq" exact component={FAQ}/>
          <RouteNavbarWithFooter path="/tarihce" exact component={Company}/>
          <RouteNavbarWithFooter path="/misyonumuz" exact component={MissionPage}/>
          <RouteNavbarWithFooter path="/sirketlerimiz" exact component={GroupCompaniesPage}/>
          <RouteNavbarWithFooter exact path="/is-ve-staj-basvuru" component={JobAndInternShipPage}  />
          <RouteNavbarWithFooter exact path="/oneri-sikayet-ve-istek" component={SuggestionComplaintPage}  />
          <RouteNavbarWithFooter exact path="/musteri-hizmetleri" component={CustomerServices} />
          <RouteNavbarWithFooter exact path="/reduktor-secim" component={Gst} />
          <RouteNavbarWithFooter exact path="/bayi-siparis" component={B2B} />
          <RouteNavbarWithFooter exact path="/bayi-siparis-secim" component={BayiSiparis} />
          <RouteNavbarWithFooter exact path="/uygulamalar" component={YrApp} />
          <RouteNavbarWithFooter exact path="/hizli-resim" component={QuickPicture} />
          <RouteNavbarWithFooter exact path="/haberler/:slugTitle" component={NewsDetail} />
          <RouteNavbarWithFooter exact path="/faydali-bilgiler/:slugTitle" component={IconMenuDetail}  />
          <RouteNavbarWithFooter path="/docs" exact component={Docs} />
          <RouteNavbarWithFooter path="/seri-no-sorgula" exact component={SeriNoSorgula} />
          <Route exact path="*" component={Error404}/>
          
        </Switch>
    
      </Router>
    </div>
  );
}

export default App;
