import React, { useState } from "react";
import BreadCrumb from "../components/Shared/BreadCrumb/BreadCrumb";
import "../components/Services/Services.css";
import Dealers from "../components/Services/Dealers";
import ServicePoints from "../components/Services/ServicePoints";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

function Services() {
  const { t } = useTranslation();

  const [chooseComponent, setChooseComponent] = useState(<Dealers />);
  const [chooseSelect, setChooseSelect] = useState(1);

  const handleClick = (param) => {
    switch (param) {
      case "bayi":
        setChooseComponent(<Dealers />);
        setChooseSelect(1);
        break;

      case "servis":
        setChooseComponent(<ServicePoints />);
        setChooseSelect(2);
        break;
    }
  };

  return (
    <div>
      <Helmet>
        <title>Satış ve Servisler - YILMAZ REDÜKTÖR San. ve Tic. A.S</title>
        <link rel="canonical" href="https://yr.com.tr/satis-ve-servisler" />
        <meta
          name="keywords"
          content="yılmaz reduktor redüktör, reduktor,dişli kutusu,disli kutusu,gearbox,gearunit,getriebe,getriebemotoren"
        />
       
      </Helmet>
      <BreadCrumb location={t("SATIS-SERVIS-BREAD")} />
      <div className="secpadd">
        <div className="services-choose-button-container">
          <button
            onClick={() => handleClick("bayi")}
            className={`services-choose-button ${
              chooseSelect == 1 ? "choose-active" : ""
            }`}
          >
            {t("SCP-DEALER")}
          </button>
          <button
            onClick={() => handleClick("servis")}
            className={`services-choose-button ${
              chooseSelect == 2 ? "choose-active" : ""
            }`}
          >
            {t("SCP-SERL")}
          </button>
        </div>
        {chooseComponent}
      </div>
    </div>
  );
}

export default Services;
