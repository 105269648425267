import React, { useState, useEffect } from "react";
import Box from "../components/HomePage/Box/Box";
import Card from "../components/HomePage/Card/Card";
import IconMenu from "../components/HomePage/IconMenu/IconMenu";
import Partner from "../components/Shared/Partner/Partner";
import Slider from "../components/HomePage/Slider/Slider";
import NewsReact from "../components/HomePage/News/NewsReact.js";
import SliderData from "../components/HomePage/Slider/SliderData";
import ScrollAnimation from "react-animate-on-scroll";
import { fetchSliderAll } from "../api/agent";
import { Helmet } from "react-helmet";

function Home() {
  const [sliders, setSliders] = useState([]);

  useEffect(() => {
    const getAllSlider = async () => {
      const allSlider = await fetchSliderAll();
      var result = allSlider.data;
      setSliders(result);
    };
    getAllSlider();
  }, []);

  return (
    <div>
      <Helmet>
        <title>Anasayfa - YILMAZ REDÜKTÖR San. ve Tic. A.S</title>
        <link rel="canonical" href="https://yr.com.tr/" />

		<meta name="keywords" content="yılmaz reduktor redüktör, reduktor,dişli kutusu,disli kutusu,gearbox,gearunit,getriebe,getriebemotoren" />
  
      </Helmet>
      <Slider slides={sliders} />
      {/* <Slider slides={SliderData} /> */}
      <Box />
      <ScrollAnimation animateIn="fadeInLeft" duration={1.5} animateOnce={true}>
        <Card />
      </ScrollAnimation>
      <NewsReact />
      <IconMenu />
      <Partner />
    </div>
  );
}
export default Home;
