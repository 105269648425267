import axios from 'axios';



const API = axios.create({baseURL : 'https://yr.com.tr:5557/api'})
//const API = axios.create({baseURL : 'http://localhost:5000/api'})




export const fetchReducerOptions = async() => await API.get('/reducerOptions/getall')
export const fetchReducerOptionsBySeries = async(reducerSeries) => await API.get(`/reducerOptions/getBySeries?reducerSeries=${reducerSeries}`)

export const fetchSolutionOptions = async() => await API.get('/solutionOptions/getall')
export const fetchSolutionOptionsBySeries = async(solutionSeries) => await API.get(`/solutionOptions/getBySeries?solutionSeries=${solutionSeries}`)

export const fetchReducerTableBySeries = async(series) => await API.get(`/reducerTable/getBySeries?reducerSeries=${series}`)

export const fetchNewsAll = async() => await API.get("/news/getall")
export const fetchNewsById = async(id) => await API.get(`/news/getbyid?newsId=${id}`);


export const fetchYoutubeLinkAll = async() => await API.get("/youtubeLink/getall")
export const fetchYoutubeLinkById = async(id) => await API.get(`/youtubeLink/getbyid?id=${id}`);


export const fetchDealersAll = async() => await API.get("/dealers/getall")

export const fetchProductsAll = async() => await API.get("/products/getall");




export const fetchDocumentShortCuts = async(seriCode , lang,docType) => await API.get(`/documentShortCut/getAllFilter?seriCode=${seriCode}&lang=${lang}&docType=${docType}`)
export const fetchDocumentShortCutsKılavuz = async(lang , docType , documentDescription) => await API.get(`/documentShortCut/getDescriptionFilter?lang=${lang}&docType=${docType}&documentDescription=${documentDescription}`)
export const fetchFeaturesAndSolutionsById = async(id) => await API.get(`/featuresAndSolutions/getbyseries?reducerSeries=${id}`)

export const fetchTipsheets = async() => await API.get(`/tipsheet/getall`);
export const fetchTipsheetById = async(id) => await API.get(`/tipsheet/getbyid?tipSheetId=${id}`);

export const fetchProductInfos = async() => await API.get("/productInfo/getall");
export const fetchProductInfoByProductId = async(productId) => await API.get(`/productInfo/getbyid?productId=${productId}`);

export const fetchSolutionInfos = async() => await API.get("/solutionInfo/getall");
export const fetchSolutionInfoByProductId = async(solutionId) => await API.get(`/solutionInfo/getbyid?solutionId=${solutionId}`);

export const fetchSliderAll = async() => await API.get(`/slider/getall`);
export const fetchSliderBySizeAll = async(size) => await API.get(`/slider/getById?sliderSize=${size}`);


export const fetchLanguages = async() => await API.get('/languages/getall')



export const fetchCommentOrClaim = async(commentOrClaim) => await API.post("/commentOrClaim",commentOrClaim);

export const fetchCustomerService = async(customerService) => await API.post("/customerService" , customerService);



const responseBody = (response) => response.data;

const request = {
    postJob : (url  , values ) => {
        let formData = new FormData()
        formData.append("firstName",values.firstName);
        formData.append("lastName",values.lastName);
        formData.append("birthDay",values.birthDay);
        formData.append("identityNumber",values.identityNumber);
        formData.append("gender",values.gender);
        formData.append("maritalStatus",values.maritalStatus);
        formData.append("militaryStatus",values.militaryStatus);
        formData.append("email",values.email);
        formData.append("phoneNumber",values.phoneNumber);
        formData.append("city",values.city);
        formData.append("district",values.district);
        formData.append("address",values.address);
        formData.append("educationLevel",values.educationLevel);
        formData.append("schoolName",values.schoolName);
        formData.append("schoolStartTime",values.schoolStartTime);
        formData.append("schoolFinishTime",values.schoolFinishTime);
        formData.append("cvPath",values.cvPath);
        formData.append("jobOrIntership",0);
        formData.append("coverLetter" ,"-");
        formData.append("intershipType" ,"-");
        formData.append("intershipDepartment" ,"-");
        formData.append("intershipWithDepartment" ,"-");

        return axios.post(url,formData,{
            headers:{'Content-type':'multipart/form-data'}
        }).then(responseBody);
    },
    postIntership : (url  , values ) => {
        let formData = new FormData()
        formData.append("firstName",values.firstName);
        formData.append("lastName",values.lastName);
        formData.append("birthDay",values.birthDay);
        formData.append("identityNumber",values.identityNumber);
        formData.append("gender",values.gender);
        formData.append("email",values.email);
        formData.append("phoneNumber",values.phoneNumber);
        formData.append("city",values.city);
        formData.append("district",values.district);
        formData.append("address",values.address);
        formData.append("educationLevel",values.educationLevel);
        formData.append("schoolName",values.schoolName);
        formData.append("schoolStartTime",values.schoolStartTime);
        formData.append("schoolFinishTime",values.schoolFinishTime);
        formData.append("cvPath",values.cvPath);
        formData.append("jobOrIntership",1);
        formData.append("coverLetter" , "-");
        formData.append("intershipType" , values.intershipType);
        formData.append("intershipDepartment" , values.intershipDepartment);
        formData.append("intershipWithDepartment" , "-");
        formData.append("maritalStatus","-");
        formData.append("militaryStatus","-");


        return axios.post(url,formData,{
            headers:{'Content-type':'multipart/form-data'}
        }).then(responseBody);
    },
}

const JobAndInternShip ={
    //build
    postJob : (values) => request.postJob("https://yr.com.tr:5557/api/JobAndIntership",values),
    postIntership : (values) => request.postIntership("https://yr.com.tr:5557/api/JobAndIntership",values)

    //local
    //postJob : (values) => request.postJob("http://192.168.0.221/api/JobAndIntership",values),
    //postIntership : (values) => request.postIntership("http://192.168.0.221/api/JobAndIntership",values)
}



export default{
    JobAndInternShip
}