import i18next from "i18next";
import HttpBackend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import {initReactI18next} from "react-i18next"


// const loadPath = `https://localhost:44375/WebAPI/api/Translates/gettranslatesbylang?lang={{lng}}`

const loadPath = `https://yr.com.tr:5557/api/Translates/gettranslatesbylang?lang={{lng}}`;
 //const loadPath = `http://localhost:5000/api/Translates/gettranslatesbylang?lang={{lng}}`;

i18next
  .use(HttpBackend)

  .use(initReactI18next)
  .init({
    react:{
      useSuspense:false
    },
    fallbackLng: "tr-TR",
    ns: ["default"],
    defaultNS: "default",
    //supportedLngs: ["en-US", "tr-TR","de-DE"],
    supportedLngs: ["en-US", "tr-TR","code-CODE","de-DE","fr-FR","ru-RU"],
    keySeparator: false, // messages.title gibi tanımlama yapmadığımız için false.
    interpolation: {
        escapeValue: false //XSS koruması için gerekli.
    },
    backend: {
      loadPath: loadPath
    }
  }); 

  export default i18next;