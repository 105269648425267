import React from "react";
import YrAppComponent from "../components/YrApp/YrAppComponent";
import { Helmet } from "react-helmet";

function YrApp() {
  return (
    <>
      <Helmet>
        <title>Uygulamalar - YILMAZ REDÜKTÖR San. ve Tic. A.S</title>
        <link rel="canonical" href="https://yr.com.tr/uygulamalar" />
        <meta
          name="keywords"
          content="yılmaz reduktor redüktör, reduktor,dişli kutusu,disli kutusu,gearbox,gearunit,getriebe,getriebemotoren"
        />
       
      </Helmet>
      <YrAppComponent />
    </>
  );
}

export default YrApp;
