import React from 'react'
import Iframe from 'react-iframe'
import BreadCrumb from '../Shared/BreadCrumb/BreadCrumb'
import { useTranslation } from 'react-i18next';
import './FAQ.css'
function FAQ() {
  const { t } = useTranslation();

    return (
        <>
        <BreadCrumb location={t('FAQ-BREAD')} />
        <div  style={{background : '#fff'}}>

        <div className="secpadd">
        <div className="container">
      
            <h2 className="history-header-text">{t('SOL-MAIN-TITLE')}</h2>
            <h2 style={{fontSize:'24px'}} className="history-header-text"> {t('SOL-SUB1-TITLE')}</h2>
            <div className="history-content-text">
              <span>• {t('SOL-SUB1-DESC1')}</span>
              <span style={{marginTop:"15px"}}>
              • {t('SOL-SUB1-DESC2')}

              </span>

              <span style={{marginTop:"15px"}}>
              • {t('SOL-SUB1-DESC3')}
              </span>

              <span style={{marginTop:"15px"}}>
              • {t('SOL-SUB1-DESC4')}
             </span>

             <span style={{marginTop:"15px"}}>
             • {t('SOL-SUB1-DESC5')}
             </span>

             <span style={{marginTop:"15px"}}>
             • {t('SOL-SUB1-DESC6')}
             </span>

             <span style={{marginTop:"15px"}}>
             • {t('SOL-SUB1-DESC7')}
             </span>

              <br/>
             <h2 style={{fontSize:'24px'} } className="history-header-text">{t('SOL-SUB2-TITLE')}</h2>

              <span>• {t('SOL-SUB2-DESC1')}</span>

              <br/>
             <h2 style={{fontSize:'24px'} } className="history-header-text">{t('SOL-SUB3-TITLE')}</h2>

             <span>• {t('SOL-SUB3-DESC1')}</span>
             <br/>
             <h2 style={{fontSize:'24px'} } className="history-header-text">{t('SOL-SUB4-TITLE')}</h2>

              <span>• {t('SOL-SUB4-DESC1')}</span>

              <br/>

            
             <h2 style={{fontSize:'24px'} } className="history-header-text">{t('SOL-SUB5-TITLE')}</h2>

              <span>• {t('SOL-SUB5-DESC1')}</span>

              <br/>
            
             <h2 style={{fontSize:'24px'} } className="history-header-text">{t('SOL-SUB6-TITLE')}</h2>

              <span>• {t('SOL-SUB6-DESC1')}</span>

              <br/>
            
             <h2 style={{fontSize:'24px'} } className="history-header-text">{t('SOL-SUB7-TITLE')}</h2>

              <span>• {t('SOL-SUB7-DESC1')}</span>

              <br/>

              <h2 style={{fontSize:'24px'} } className="history-header-text">{t('SOL-SUB8-TITLE')}</h2>

              <span>• {t('SOL-SUB8-DESC1')}</span>

              <br/>

              <h2 style={{fontSize:'24px'} } className="history-header-text">{t('SOL-SUB9-TITLE')}</h2>

              <span>• {t('SOL-SUB9-DESC1')}</span>

              <span style={{marginLeft:'42pt'}}> • {t('SOL-SUB8-DESC2')}</span>
              <span style={{marginLeft:'42pt'}}> • {t('SOL-SUB8-DESC3')}</span>
              <span style={{marginLeft:'42pt'}}> • {t('SOL-SUB8-DESC4')}</span>
              <span style={{marginLeft:'42pt'}}> • {t('SOL-SUB8-DESC5')}</span>
              <span style={{marginLeft:'42pt'}}> • {t('SOL-SUB8-DESC6')}</span>

              <span>• {t('SOL-SUB8-DESC7')}</span>

              <br/>



              <h2 style={{fontSize:'24px'} } className="history-header-text">{t('SOL-SUB10-TITLE')}</h2>

              <span>• {t('SOL-SUB10-DESC1')}</span>

              <br/>


             

              <h2 style={{fontSize:'24px'} } className="history-header-text">{t('SOL-SUB11-TITLE')}</h2>

              <span>• {t('SOL-SUB11-DESC1')}</span>

              <br/>
             

              <h2 style={{fontSize:'24px'} } className="history-header-text">{t('SOL-SUB12-TITLE')}</h2>

              <span>• {t('SOL-SUB12-DESC1')}</span>

              <br/>
             

              <h2 style={{fontSize:'24px'} } className="history-header-text">{t('SOL-SUB13-TITLE')}</h2>

              <span style={{marginLeft:'42pt'}}> • {t('SOL-SUB13-DESC1')}</span>
              <span style={{marginLeft:'42pt'}}> • {t('SOL-SUB13-DESC2')}</span>
              <span style={{marginLeft:'42pt'}}> • {t('SOL-SUB13-DESC3')}</span>
              <span style={{marginLeft:'42pt'}}> • {t('SOL-SUB13-DESC4')}</span>
              <span style={{marginLeft:'42pt'}}> • {t('SOL-SUB13-DESC5')}</span>


              <br/>
            

              <h2 style={{fontSize:'24px'} } className="history-header-text">{t('SOL-SUB14-TITLE')}</h2>

              <span>• {t('SOL-SUB14-DESC1')}</span>

          

              <br/>

<h2 style={{fontSize:'24px'} } className="history-header-text">{t('SOL-SUB15-TITLE')}</h2>

<span>• {t('SOL-SUB15-DESC1')}</span>

<br/>




<h2 style={{fontSize:'24px'} } className="history-header-text">{t('SOL-SUB16-TITLE')}</h2>

<span>• {t('SOL-SUB16-DESC1')} </span>



<br/>

<h2 style={{fontSize:'24px'} } className="history-header-text">{t('SOL-SUB17-TITLE')}</h2>

<span>•  {t('SOL-SUB17-DESC1')}</span>


<span style={{marginLeft:'42pt'}}> • {t('SOL-SUB17-DESC2')}</span>
              <span style={{marginLeft:'42pt'}}> • {t('SOL-SUB17-DESC3')}</span>
              <span style={{marginLeft:'42pt'}}> • {t('SOL-SUB17-DESC4')}</span>
              <span style={{marginLeft:'42pt'}}> • {t('SOL-SUB17-DESC5')}</span>
              
              <span>•  {t('SOL-SUB17-DESC6')}</span>
              <span>•  {t('SOL-SUB17-DESC7')}</span>
              




<br/>

<h2 style={{fontSize:'24px'} } className="history-header-text">{t('SOL-SUB18-TITLE')}</h2>

<span>• {t('SOL-SUB18-DESC1')} </span>
<span>• {t('SOL-SUB18-DESC2')} </span>



<br/>

<h2 style={{fontSize:'24px'} } className="history-header-text">{t('SOL-SUB19-TITLE')}</h2>

<span>• {t('SOL-SUB19-DESC1')} </span>
<span>• {t('SOL-SUB19-DESC2')}</span>
<span>• {t('SOL-SUB19-DESC3')}</span>
<br/>



<h2 style={{fontSize:'24px'} } className="history-header-text">{t('SOL-SUB20-TITLE')}</h2>

<span>• {t('SOL-SUB20-DESC1')} </span>



<br/>

<h2 style={{fontSize:'24px'} } className="history-header-text">{t('SOL-SUB21-TITLE')}</h2>

<span>• {t('SOL-SUB21-DESC1')}  </span>
<span>• {t('SOL-SUB21-DESC2')}  </span>
<span>• {t('SOL-SUB21-DESC3')}  </span>
<span>• {t('SOL-SUB21-DESC4')}  </span>
<span>• {t('SOL-SUB21-DESC5')}  </span>
<span>• {t('SOL-SUB21-DESC6')}  </span>



<br/>

<h2 style={{fontSize:'24px'} } className="history-header-text">{t('SOL-SUB22-TITLE')}</h2>

<span>• {t('SOL-SUB22-DESC1')} </span>


<br/>

<h2 style={{fontSize:'24px'} } className="history-header-text">{t('SOL-SUB23-TITLE')}</h2>

<span>• {t('SOL-SUB23-DESC1')} </span>
<span>• {t('SOL-SUB23-DESC2')}  </span>



<br/>

<h2 style={{fontSize:'24px'} } className="history-header-text">{t('SOL-SUB24-TITLE')}</h2>

<span>• {t('SOL-SUB24-DESC1')} </span>

<br/>



      </div>
      </div>
      </div>
      </div>
       
        </>
    )
}

export default FAQ
