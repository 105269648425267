import React from "react";
import SuggestionComplaint from "../components/SuggestionComplaint/SuggestionComplaint";
import { Helmet } from "react-helmet";

const SuggestionComplaintPage = () => {
  return (
    <div>
      <Helmet>
        <title>Öneri Şikayet ve İstek - YILMAZ REDÜKTÖR San. ve Tic. A.S</title>
        <link rel="canonical" href="https://yr.com.tr/oneri-sikayet-ve-istek" />
        <meta
          name="keywords"
          content="yılmaz reduktor redüktör, reduktor,dişli kutusu,disli kutusu,gearbox,gearunit,getriebe,getriebemotoren"
        />
       
      </Helmet>
      <SuggestionComplaint />
    </div>
  );
};

export default SuggestionComplaintPage;
