const PartnerData = [
  {
    name: "yr",
    image: "assets/images/logo/yr-logo-beyaz.webp",
    hoverImage:"assets/images/logo/yr-logo-renkli.webp",
    path: "https://www.yr.com.tr",
  },
  {
    name: "elk",
    image: "assets/images/logo/elk-logo-beyaz.webp",  
    hoverImage:"assets/images/logo/elk-logo-renkli.webp",

    path: "http://www.elkmotor.com.tr/",
  },
  {
    name: "mes",
    image: "assets/images/logo/mes-logo-beyaz.webp",
    hoverImage:"assets/images/logo/mes-logo-renkli.webp",

    path: "http://www.mesdokum.com.tr/",
  }
];
export default PartnerData;
