import React from 'react'
import './Preloader.css'

function Preloader() {
    return (
        <div className="preloader" style={{backgroundImage: "url(assets/images/preloader.gif)"}} />
    )
}

export default Preloader
