import { useEffect, useState } from 'react';
import i18n from './i18n'
function useLanguage() {
    
	const getLanguage=()=>{
        const languageString = localStorage.getItem('language')|| 'tr-TR';
		return languageString;
    }
    
	const [language, setLanguage] = useState(getLanguage())

	useEffect(() => {
        localStorage.setItem("language",language)
        i18n.changeLanguage(language);
     
	},[language])
	
	const changeLanguage = () => {
		if (language === 'tr-TR') {
			setLanguage('code-CODE');
			
		} else {
			setLanguage('tr-TR')
		}
	};
	return {
		setLanguage:changeLanguage,
		language
	};
}
export default useLanguage;