const navbarProducts = [
    {
      menuId: 101,
      menuFirstCategoryId: 201,
      menuName: "NB-PR-MN-SR-T",
      navbarId: 1,
    },
    {
      menuId: 102,
      menuFirstCategoryId: 301,
      menuName: "NB-PR-MN-HVY-T",
      navbarId: 1,
    },
    {
      menuId: 103,
      menuFirstCategoryId: 401,
      menuName: "NB-PR-MN-SEC-T",
      navbarId: 1,
    },

    {
      menuId: 104,
      menuFirstCategoryId: 501,
      menuName: "NB-PR-MN-AUT-T",
      navbarId: 1,
    },

    {
      menuId: 105,
      menuFirstCategoryId: 601,
      menuName: "NB-PR-MN-MOTOR",
      navbarId: 1,
    },

  
  ];
  export default navbarProducts;