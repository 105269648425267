import React from "react";
import GroupCompanies from "../components/Company/GroupCompanies";
import { Helmet } from "react-helmet";

const GroupCompaniesPage = () => {
  return (
    <>
      <Helmet>
        <title>Şirketlerimiz - YILMAZ REDÜKTÖR San. ve Tic. A.S</title>
        <link rel="canonical" href="https://yr.com.tr/sirketlerimiz" />
        <meta
          name="keywords"
          content="yılmaz reduktor redüktör, reduktor,dişli kutusu,disli kutusu,gearbox,gearunit,getriebe,getriebemotoren"
        />
     
      </Helmet>
      <GroupCompanies />
    </>
  );
};

export default GroupCompaniesPage;
