import React, { useState, useRef, useEffect } from 'react';
import './Slider.css';
import { motion } from 'framer-motion';
import { useMediaPredicate } from "react-media-hook";
import {Link} from 'react-router-dom' 
import { fetchSliderAll, fetchSliderBySizeAll } from '../../../api/agent';
import { useTranslation } from 'react-i18next';

function Slider({ slides }) {
	const isTablet = useMediaPredicate("(max-width: 1024px)");
	const isMobile = useMediaPredicate("(max-width: 480px)");
	const { t } = useTranslation();
	
	const [filterSlider , setFilterSlider] = useState([]);
	var sliderType = 0;

	if(isMobile){
		sliderType = 2;
	}
	else if(isTablet){
		sliderType = 1;
	}
	else{
		sliderType = 0;
	}
	
	useEffect(() => {
		const getAllSliderSize = async() => {
		  const allSliderSize = await fetchSliderBySizeAll(sliderType);
		  var result = allSliderSize.data;
		  setFilterSlider(result);
		 
		}
		getAllSliderSize();
	  }, [sliderType])

	slides = filterSlider;

    const [ current, setCurrent ] = useState(0);
	const length = slides.length;
	const timeout = useRef(null);

	useEffect(
		() => {
			const nextSlide = () => {
				setCurrent((current) => (current === length - 1 ? 0 : current + 1));
			};
			timeout.current = setTimeout(nextSlide, 10000);
			return function() {
				if (timeout.current) {
					clearTimeout(timeout.current);
				}
			};
		},
		[current, length]
	);
	const nextSlide = () => {
		if (timeout.current) {
			clearTimeout(timeout.current);
		}
		setCurrent(current === length - 1 ? 0 : current + 1);
	};
	const prevSlide = () => {
		if (timeout.current) {
			clearTimeout(timeout.current);
		}
		setCurrent(current === 0 ? length - 1 : current - 1);
	};
	if (!Array.isArray(slides) || slides.length <= 0) {
		return null;
	}
	const heroSlideVariants = {
		hidden: { opacity: 0.8 },
		visible: {
			opacity: 1,
			transition: { duration: 0.5 }
		}
	};
	const heroH1Variants = {
		hidden: { opacity: 0, scale: 0.8 },
		visible: {
			opacity: 1,
			scale: 1,
			transition: { duration: 0.6 }
		}
	};
	const heroPVariants = {
		hidden: { opacity: 0, y: -25 },
		visible: {
			opacity: 1,
			y: 0,
			transition: { duration: 0.9, while: 'heroH1Variants', delay: 1 }
		}
	};
	const heroButtonVariants = {
		hidden: { opacity: 0, y: 25 },
		visible: {
			opacity: 1,
			y: 0,
			transition: { duration: 0.6, while: 'heroH1Variants', delay: 0.5 }
		}
	};
	return (
		<section className="hero-section" >   
		{/* onClick={nextSlide} */}
			<div className="hero-wrapper">
				{slides.map((data, index) => {
					var slideType;
					if(data.sliderOptionsEnum == 0){
						var slideType = 0;
					}
					else if (data.sliderOptionsEnum == 1){
						var slideType = 1;
					}
					else{
						var slideType = 2;
					}
					var result = ''
					if(isMobile && slideType == 2){
	
						result =  data.imagePath;
					}
					else if (isTablet && slideType == 1){
					

						result = data.imagePath;
					}
					else if(slideType == 0){


						result = data.imagePath;
					}
					return (
						<div className="hero-slide" key={index}>
							{index === current && (
								<motion.div
									variants={heroSlideVariants}
									initial="hidden"
									animate="visible"
									className="hero-slider"
								>
								 {result == '' ? <></> : 	<img className="hero-image"  src={result} alt="" /> }
									<div
										className="hero-content"
										style={isMobile?({
											right: data.xCoordinate + "%",
											bottom: data.yCoordinate+ "%"
										}):({
											right: data.xCoordinate + "%",
											bottom: data.yCoordinate+ "%"

										})}
									>
									 <>
											<motion.h1 variants={heroH1Variants} style={isMobile?({fontSize: "6.5vw",color:data.colorText , textAlign:data.textAlign}):({fontSize:"3.2vw" ,color:data.colorText , textAlign:data.textAlign})}>
											{t(data.textOptions)}
											<br />
											{t(data.textOptions2)}
											<br />
											{data.textOptions3 == "null" || null || '' ? <></> : <> {t(data.textOptions3)} </> }  
										</motion.h1>
										{/* <motion.p variants={heroPVariants} style={isMobile?({fontSize: "4vw"}):({fontSize:"1vw"})}>
											{data.textOne}
											<br />
											{data.textTwo}
										</motion.p> */}
										<Link to={data.urlLink}>
										<motion.button
											variants={heroButtonVariants}
											whileHover={{ scale: 1.1 }}
											className="hero-button"
										>
											{t(data.buttonText)}
										</motion.button>
										</Link>
										</> 
										
									</div>
								</motion.div>
							)}
						</div>
					);
				})}
				<div className="arrow r">
					<img alt="r" src="assets/images/slider/r.webp" onClick={nextSlide} />
				</div>
				<div className="arrow l">
					<img alt="l" src="assets/images/slider/l.webp" onClick={prevSlide} />
				</div>
			</div>
		</section>
	);
}


export default Slider;
