import React from "react";
import BreadCrumb from "../Shared/BreadCrumb/BreadCrumb";
import { useHistory } from "react-router-dom";
import Timeline from 'react-image-timeline';
import 'react-image-timeline/dist/timeline.css';
import { useMediaPredicate } from "react-media-hook"; 
import "./History.css";
import { useTranslation } from 'react-i18next';

const History = () => {
  const isMobile = useMediaPredicate("(max-width: 1024px)");
  const { t } = useTranslation();

  const events = [
    {
        date: new Date(1958, 1, 1),
        text: t("HS-H-1958-1") + " " + t("HS-H-1958-2") ,
        imageUrl: "assets/images/history/1.png",
        
    },
    {
      date: new Date(1963, 1, 1),
      text: t("HS-H-1963-1") + " " + t("HS-H-1963-2")  + " " + t("HS-H-1963-3"),
      imageUrl: "assets/images/history/2.png",
      
    },
    {
      date: new Date(1970, 1, 1),
      text: t("HS-H-1970-1") + " " + t("HS-H-1970-2") + " " + t("HS-H-1970-3") + " " + t("HS-H-1970-4") + " " + t("HS-H-1970-5"),
      imageUrl: "assets/images/history/3.png",
      
    },
    {
      date: new Date(1987, 1, 1),
      text: t("HS-H-1987-1")   + " " +  t("HS-H-1987-2") + " " +  t("HS-H-1987-3") ,
      imageUrl: "assets/images/history/4.png",

      
    },
    {
      date: new Date(2002, 1, 1),
      text: t("HS-H-2002-1")   + " " +  t("HS-H-2002-2") + " " +  t("HS-H-2002-3") + " " +  t("HS-H-2002-4") + " " +  t("HS-H-2002-5"),
      imageUrl: "assets/images/history/5.png",

      
    },
    {
      date: new Date(2007, 1, 1),
      text: t("HS-H-2007-1")   + " " +  t("HS-H-2007-2") + " " +  t("HS-H-2007-3"),
      imageUrl: "assets/images/history/H.png",

      
    },
    {
      date: new Date(2011, 1, 1),
      text: t("HS-H-2011-1")   + " " +  t("HS-H-2011-2") + " " +  t("HS-H-2011-3") + " " +  t("HS-H-2011-4") + " " +  t("HS-H-2011-5"),
      imageUrl: "assets/images/history/P.png",

      
    },
    {
      date: new Date(2012, 1, 1),
      text: t("HS-H-2012-1")   + " " +  t("HS-H-2012-2") + " " +  t("HS-H-2012-3") + " " +  t("HS-H-2012-4") + " " +  t("HS-H-2012-5"),
      imageUrl: "assets/images/history/V.png",

      
    },

    {
      date: new Date(2013, 1, 1),
      text: t("HS-H-2013-1")   + " " +  t("HS-H-2013-2") + " " +  t("HS-H-2013-3") + " " +  t("HS-H-2013-4") + " " +  t("HS-H-2013-5"),
      imageUrl: "assets/images/history/7.png",

      
    },

    {
      date: new Date(2017, 1, 1),
      text: t("HS-H-2017-1")   + " " +  t("HS-H-2017-2") + " " +  t("HS-H-2017-3") + " " +  t("HS-H-2017-4") + " " +  t("HS-H-2017-5"),
      imageUrl: "assets/images/history/6.png",

      
    },
];
  let history = useHistory();

  function handleClick() {
    history.push("/tarihce");
    window.location.reload();
  }
  return (
    <>
    
      <BreadCrumb location={t("HS-B-T")} />
      <div>
        <img src="assets/images/kurucu.png" />
      </div>

      <div className="secpadd">
        <div className="container">
      
            <h2 className="history-header-text">{t("HS-B-T")}</h2>
            <h2 style={{fontSize:'24px'}} className="history-header-text">{t("HS-CL-T")}</h2>
            <div className="history-content-text">
              <span>{t("HS-CL-D1")}</span>
              <span style={{marginTop:"15px"}}>
              {t("HS-CL-D2")}

              </span>

              <span style={{marginTop:"15px"}}>
              {t("HS-CL-D3")}

              </span>

              <span style={{marginTop:"15px"}}>
            {t("HS-CL-D4")}


              </span>


              <span style={{marginTop:"15px",marginBottom:"30px"}}>
              {t("HS-CL-D5")}




              </span>
            </div>

            {isMobile ? 
            
            <>
            
            <Timeline events={events} />
            
            </>
            
            
            : 
            
            
            
            <>
            
          
                <div className="container-left">
                <div id="rectangle-left">
                    <div id="circle-left">
                    <span className="year-left">1958</span>
                    </div>

                    <div className="text-container-left">
                    <span className="text-span-left">{t("HS-H-1958-1")}</span>
                    <span className="text-span-left">{t("HS-H-1958-2")} </span>
                    <span className="text-span-left">{t("HS-H-1958-3")} </span>  
                    <span className="text-span-left">{t("HS-H-1958-4")} </span> 
                    <span className="text-span-left">{t("HS-H-1958-5")} </span>
                    <span className="text-span-left">{t("HS-H-1958-6")} </span>
                    <span className="text-span-left">{t("HS-H-1958-7")} </span>
                   
                    </div>
                </div>

                <img className="history-img" src="assets/images/history/1.png" />
                </div>
 

           
           
            <div className="container-right">
              <img className="history-img" src="assets/images/history/2.png" />

              <div id="rectangle-right">
                <div id="circle-right">
                  <span className="year-right"> 1963</span>
                </div>

                <div className="text-container-right">
                  <span className="text-span-right">     {t("HS-H-1963-1")}</span>
                  <span className="text-span-right">     {t("HS-H-1963-2")}</span>
                  <span className="text-span-right">     {t("HS-H-1963-3")}</span>
                  <span className="text-span-right">     {t("HS-H-1963-4")}</span>
                  <span className="text-span-right">     {t("HS-H-1963-5")}</span>
                  <span className="text-span-right">     {t("HS-H-1963-6")}</span>
                  <span className="text-span-right">     {t("HS-H-1963-7")}</span>
                 
                </div>
              </div>
            </div>
           
           

            {/* başlangıç */}

           
            <div className="container-left">
              <div id="rectangle-left">
                <div id="circle-left">
                  <span className="year-left"> 1970</span>
                </div>

                <div className="text-container-left">
                <span className="text-span-left">  {t("HS-H-1970-1")}</span>
                <span className="text-span-left">  {t("HS-H-1970-2")}</span>
                <span className="text-span-left">  {t("HS-H-1970-3")}</span>
                <span className="text-span-left">  {t("HS-H-1970-4")}</span>
                <span className="text-span-left">  {t("HS-H-1970-5")}</span>
                <span className="text-span-left">  {t("HS-H-1970-6")}</span>
                <span className="text-span-left">  {t("HS-H-1970-7")}</span>
                </div>
              </div>

              <img className="history-img" src="assets/images/history/3.png" />
            </div>
             
           
            {/* bitiş */}

            <div className="container-right">
              <img  className="history-img" src="assets/images/history/4.png" />

              <div id="rectangle-right">
                <div id="circle-right">
                  <span className="year-right"> 1987</span>
                </div>

                <div className="text-container-right">
                <span className="text-span-right">{t("HS-H-1987-1")}</span>
                <span className="text-span-right">{t("HS-H-1987-2")}</span>
                <span className="text-span-right">{t("HS-H-1987-3")}</span>
                <span className="text-span-right">{t("HS-H-1987-4")}</span>
                <span className="text-span-right">{t("HS-H-1987-5")}</span>
                <span className="text-span-right">{t("HS-H-1987-6")}</span>
                <span className="text-span-right">{t("HS-H-1987-7")}</span>
                </div>
              </div>
            </div>
          
           
               
                <div className="container-left">
              <div id="rectangle-left">
                <div id="circle-left">
                  <span className="year-left"> 2002</span>
                </div>

                <div className="text-container-left">
                  <span className="text-span-left"> {t("HS-H-2002-1")}</span>
                  <span className="text-span-left"> {t("HS-H-2002-2")}</span>
                  <span className="text-span-left"> {t("HS-H-2002-3")}</span>
                  <span className="text-span-left"> {t("HS-H-2002-4")}</span>
                  <span className="text-span-left"> {t("HS-H-2002-5")}</span>
                  <span className="text-span-left"> {t("HS-H-2002-6")}</span>
                  <span className="text-span-left"> {t("HS-H-2002-7")}</span>
                </div>
              </div>

              <img className="history-img" src="assets/images/history/5.png" />
            </div>
              
            


               
                <div className="container-right">

<img className="history-img" src="assets/images/history/H.png"/>

<div id="rectangle-right">
  <div id="circle-right">
    <span class="year-right"> 2007</span>
  </div>

  <div class="text-container-right">
  <span class="text-span-right">{t("HS-H-2007-1")}</span>
  <span class="text-span-right">{t("HS-H-2007-2")}</span>
  <span class="text-span-right">{t("HS-H-2007-3")}</span>
  <span class="text-span-right">{t("HS-H-2007-4")}</span>
  <span class="text-span-right">{t("HS-H-2007-5")}</span>
  <span class="text-span-right">{t("HS-H-2007-6")}</span>
  <span class="text-span-right">{t("HS-H-2007-7")}</span>
  </div>
</div>
</div>
             


                
                <div className="container-left">
              <div id="rectangle-left">
                <div id="circle-left">
                  <span className="year-left"> 2011</span>
                </div>

                <div className="text-container-left">
                <span className="text-span-left">{t("HS-H-2011-1")}</span>
                <span className="text-span-left">{t("HS-H-2011-2")}</span>
                <span className="text-span-left">{t("HS-H-2011-3")}</span>
                <span className="text-span-left">{t("HS-H-2011-4")}</span>
                <span className="text-span-left">{t("HS-H-2011-5")}</span>
                <span className="text-span-left">{t("HS-H-2011-6")}</span>
                <span className="text-span-left">{t("HS-H-2011-7")}</span>
                </div>
              </div>

              <img className="history-img" src="assets/images/history/P.png" />
            </div>
      


               
                <div className="container-right">

<img className="history-img" src="assets/images/history/V.png" />

<div id="rectangle-right">
  <div id="circle-right">
    <span className="year-right"> 2012</span>
  </div>

  <div className="text-container-right">
  <span className="text-span-right">{t("HS-H-2012-1")}</span>
  <span className="text-span-right">{t("HS-H-2012-2")}</span>
  <span className="text-span-right">{t("HS-H-2012-3")}</span>
  <span className="text-span-right">{t("HS-H-2012-4")}</span>
  <span className="text-span-right">{t("HS-H-2012-5")}</span>
  <span className="text-span-right">{t("HS-H-2012-6")}</span>
  <span className="text-span-right">{t("HS-H-2012-7")}</span>
  </div>
</div>
</div>
           


                <div className="container-left">



<div id="rectangle-left">
  <div id="circle-left">
    <span className="year-left"> 2013</span>
  </div>

  <div className="text-container-left">
    <span className="text-span-left">{t("HS-H-2013-1")}</span>
    <span className="text-span-left">
    {t("HS-H-2013-2")}
    </span>
    <span className="text-span-left">{t("HS-H-2013-3")}</span>
    <span className="text-span-left">{t("HS-H-2013-4")}</span>
    <span className="text-span-left">{t("HS-H-2013-5")}</span>
    <span className="text-span-left">{t("HS-H-2013-6")}</span>
    <span className="text-span-left">{t("HS-H-2013-7")}</span>
  </div>
</div>
<img className="history-img" src="assets/images/history/7.png" />
</div>
               

           
               
                <div className="container-right">
                <img className="history-img" src="assets/images/history/6.png" />
              <div id="rectangle-right">
                <div id="circle-right">
                  <span className="year-right"> 2017</span>
                </div>

                <div className="text-container-right">
                  <span className="text-span-right">{t("HS-H-2017-1")}</span>
                  <span className="text-span-right">{t("HS-H-2017-2")}</span>
                  <span className="text-span-right">
                  {t("HS-H-2017-3")}
                  </span>
                  <span className="text-span-right">{t("HS-H-2017-4")}</span>
                  <span className="text-span-right">
                  {t("HS-H-2017-5")}
                  </span>
                  <span className="text-span-right">{t("HS-H-2017-6")}</span>
                  <span className="text-span-right">{t("HS-H-2017-7")}</span>
                </div>
              </div>

             
            </div>
           
            
            
            </>
            
            
            
            
            
            }
           
           
          </div>
        </div>
 

      {/* <div style={{display : 'flex',justifyContent: 'center' ,alignItems:'center',padding : '4em 0'}}>
                <button onClick={handleClick} style={{backgroundColor : '#252525',filter: 'contrast(0.5)'}}>Tarihimize Göz Gezdir</button>
            </div> */}
    </>
  );
};

export default History;
