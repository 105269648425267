import React, { useState, useEffect } from "react";
import "../Footer/Footer.css";
import { Link, useLocation, useHistory } from "react-router-dom";
import { useMediaPredicate } from "react-media-hook";
import { useTranslation } from "react-i18next";
import { useSelectedCountry } from "../../Services/SelectedCountryContext";
import { fetchDealersAll } from "../../../api/agent";

function Footer() {
  const isLargeScreen = useMediaPredicate("(max-width: 991px)");
  let history = useHistory();
  const { selectedOption, handleSet, countryList, setOptionsList, handleSetCity, selectedOptionCity, setClickedWherePro } =
    useSelectedCountry();

  const [pathName, setPathName] = useState();
  const { t } = useTranslation();
  const { pathname } = useLocation();
  useEffect(() => {
    setPathName(pathname);
  }, [pathname]);

  const cookieStorage = {
    getItem: (key) => {
      const cookies = document.cookie
        .split(";")
        .map((cookie) => cookie.split("="))
        .reduce((acc, [key, value]) => ({ ...acc, [key.trim()]: value }), {});
      return cookies[key];
    },
    setItem: (key, value) => {
      document.cookie = `${key}=${value}`;
    },
  };

  const storageType = cookieStorage;
  const consentPropertyName = "jdc_consent";

  const shouldShowPopup = () => !storageType.getItem(consentPropertyName);
  const saveToStorage = () => storageType.setItem(consentPropertyName, true);

  window.onload = () => {
    const consentPopup = document.getElementById("gdpr-cookie-message");
    const acceptBtn = document.getElementById("gdpr-cookie-accept");

    const acceptFn = (event) => {
      saveToStorage(storageType);

      consentPopup.classList.add("hidden");
    };

    acceptBtn.addEventListener("click", acceptFn);

    if (shouldShowPopup(storageType)) {
      setTimeout(() => {
        consentPopup.classList.remove("hidden");
      }, 2000);
    }
  };

  const getCountryList = async () => {
    var filtered = [];
    const allCountryList = await fetchDealersAll();

    var result = allCountryList.data;
    filtered = [...new Set(result.map((item) => item.country))];
    setOptionsList(filtered);
  };

  useEffect(() => {
    getCountryList();
  }, [selectedOption.value]);

  const filtered = countryList.map((item) => {
    return {
      label: t(item),
      value: item,
    };
  });

  const setSelectedOptionToContext = (comdat) => {
    handleSet(JSON.parse(comdat));
  };

  return (
    <>
      <div id="footer-widgets" className="footer-widgets widgets-area">
        <div className="container">
          <div className="row row-footer">
            <div
              id="goContact"
              className="footer-sidebar footer-1 col-xs-12 col-sm-6 col-md-4"
            >
              <div id="nav_menu-2" className="widget widget_nav_menu">
                <h4 className="widget-title">{t("FT-WF-T")}</h4>
                <div className="list-post">
                  <div className="latest-post clearfix">
                    <div
                      className="post-title"
                      href="#"
                      style={{ color: "#fff" }}
                    >
                      <p className="post-title-description">{t("FT-WF-D")}</p>
                    </div>

                    <div className="footer-icon">
                    <a href="https://www.instagram.com/yilmazreduktor" aria-label="Yılmaz Redüktör Instagram Sayfası" style={{ float: "left", marginLeft: "10px" }} ><i className="fab fa-instagram fa-2x"></i></a>
                    <a href="https://linkedin.com/company/yilmaz-reduktor-as" aria-label="Yılmaz Redüktör Linkedin Sayfası" style={{ float: "left", marginLeft: "10px" }} ><i className="fab fa-linkedin-in fa-2x"></i></a>
                    <a href="https://facebook.com/yilmazreduktor/" aria-label="Yılmaz Redüktör Facebook Sayfası" style={{ float: "left", marginLeft: "10px" }} ><i className="fab fa-facebook-f fa-2x"></i></a>
                 
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="footer-sidebar footer-2 col-xs-12 col-sm-6 col-md-4">
              <div
                id="latest-post-widget-2"
                className="widget latest-post-widget"
              >
                <h4 className="widget-title">
                  {t("FT-WC-T")}
                  <br></br>
                  <span className="widget-title-span">{t("FT-WC-ST")}</span>
                </h4>

                <div className="list-contact">
                  <div className="contact-item">
                    <div className="contact-item-icon">
                      <i className="fas fa-map-marked-alt fa-2x"></i>
                    </div>
                    <div className="contact-item-text">
                      <span>{t("FT-WC-AD-1")}</span>
                      <span>{t("FT-WC-AD-2")}</span>
                    </div>
                  </div>

                  <div className="contact-item">
                    <div className="contact-item-icon">
                      <i className="fas fa-phone-alt fa-2x"></i>
                    </div>
                    <div className="contact-item-text">
                      <span>{t("FT-WC-TEL")}</span>
                      <span>{t("FT-WC-TEL1")}</span>
                      <span>{t("FT-WC-TEL2")}</span>
                    </div>
                  </div>

                  

                  <div className="contact-item">
                    <div className="contact-item-icon">
                      <i className="fas fa-envelope fa-2x"></i>
                    </div>
                    <div className="contact-item-text">
                      <span>{t("FT-EMAIL")}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {isLargeScreen ? (
              <>
              <div className="footer-sidebar footer-4 col-xs-12 col-sm-6 col-md-4 col-lg-4">
                <div
                  id="latest-post-widget-2"
                  className="widget latest-post-widget"
                >
                  <h4 className="widget-title">{t("FT-WC-ADL")}</h4>
                  <div className="denemeselect">
                    <select
                      aria-label="Country" 
                      name="one"
                      className="dropdown-select"
                      onChange={(event) => {
                        setSelectedOptionToContext(event.target.value);
                      }}
                    >
                      {filtered.sort(
                        
                        function(a, b) {
                          var atitle = a.label;
                          var btitle = b.label;
                          var alfabe = "AaBbCcÇçDdEeFfGgĞğHhIıİiJjKkLlMmNnOoÖöPpQqRrSsŞşTtUuÜüVvWwXxYyZz0123456789";
                          if (atitle.length === 0 || btitle.length === 0) {
                              return atitle.length - btitle.length;
                          }
                          for(var i=0;i<atitle.length && i<btitle.length;i++){
                              var ai = alfabe.indexOf(atitle[i]);
                              var bi = alfabe.indexOf(btitle[i]);
                              if (ai !== bi) {
                                  return ai - bi;
                              }
                          }
 }
 
 
 ).map((item, index) => {


                        return (
                          <option key={index} value={JSON.stringify(item)}>
                            {item.label}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <button
                    className="list-dropdown-button"
                    onClick={() => {
                      history.push({
                        pathname: "/satis-ve-servisler"
                      });
                      setClickedWherePro('abroad');
                    }}
                    
                  >
                    <>{t("FT-BTN-LIST")}</>
                  </button>

                  <h4 className="widget-title">{t("FT-WC-DDL")}</h4>
                  <div className="denemeselect">
                    <select aria-label="City" onChange={(event) => {handleSetCity("footer", event.target.value)}} name="one" className="dropdown-select">
                      <option value={t("FOOTER-YI-COUNTRY-1")}>{t("FOOTER-YI-COUNTRY-1")}</option>
                      <option value={t("FOOTER-YI-COUNTRY-2")}>{t("FOOTER-YI-COUNTRY-2")}</option>
                      <option value={t("FOOTER-YI-COUNTRY-3")}>{t("FOOTER-YI-COUNTRY-3")}</option>
                      <option value={t("FOOTER-YI-COUNTRY-4")}>{t("FOOTER-YI-COUNTRY-4")}</option>
                      <option value={t("FOOTER-YI-COUNTRY-5")}>{t("FOOTER-YI-COUNTRY-5")}</option>
                      <option value={t("FOOTER-YI-COUNTRY-6")}>{t("FOOTER-YI-COUNTRY-6")}</option>
                      <option value={t("FOOTER-YI-COUNTRY-7")}>{t("FOOTER-YI-COUNTRY-7")}</option>
                      <option value={t("FOOTER-YI-COUNTRY-8")}>{t("FOOTER-YI-COUNTRY-8")}</option>
                      <option value={t("FOOTER-YI-COUNTRY-9")}>{t("FOOTER-YI-COUNTRY-9")}</option>
                      <option value={t("FOOTER-YI-COUNTRY-10")}>{t("FOOTER-YI-COUNTRY-10")}</option>
                      <option value={t("FOOTER-YI-COUNTRY-11")}>{t("FOOTER-YI-COUNTRY-11")}</option>
                      <option value={t("FOOTER-YI-COUNTRY-12")}>{t("FOOTER-YI-COUNTRY-12")}</option>
                      <option value={t("FOOTER-YI-COUNTRY-13")}>{t("FOOTER-YI-COUNTRY-13")}</option>
                      <option value={t("FOOTER-YI-COUNTRY-14")}>{t("FOOTER-YI-COUNTRY-14")}</option>
                      <option value={t("FOOTER-YI-COUNTRY-15")}>{t("FOOTER-YI-COUNTRY-15")}</option>
                      <option value={t("FOOTER-YI-COUNTRY-16")}>{t("FOOTER-YI-COUNTRY-16")}</option>
                      <option value={t("FOOTER-YI-COUNTRY-17")}>{t("FOOTER-YI-COUNTRY-17")}</option>
                    </select>
                  </div>
                  <button
                    className="list-dropdown-button"
                    onClick={() => {
                      history.push({
                        pathname: "/satis-ve-servisler"
                      });
                      handleSet({
                        label: "Türkiye",
                        value: "COUNTRY-TURKEY",
                      });
                      setClickedWherePro('domestic');
                    }}
                  >
                    <>{t("FT-BTN-LIST")}</>
                  </button>
                </div>
              </div>
            </>
            ) : (
              <>
                <div className="footer-sidebar footer-4 col-xs-12 col-sm-6 col-md-4 col-lg-4">
                  <div
                    id="latest-post-widget-2"
                    className="widget latest-post-widget"
                  >
                    <h4 className="widget-title">{t("FT-WC-ADL")}</h4>
                    <div className="denemeselect">
                      <select
                        id="country-select"
                        aria-label="Country" 
                        name="one"
                        className="dropdown-select"
                        onChange={(event) => {
                          setSelectedOptionToContext(event.target.value);
                        }}
                      >
                        {filtered.sort(  function(a, b) {
                          var atitle = a.label;
                          var btitle = b.label;
                          var alfabe = "AaBbCcÇçDdEeFfGgĞğHhIıİiJjKkLlMmNnOoÖöPpQqRrSsŞşTtUuÜüVvWwXxYyZz0123456789";
                          if (atitle.length === 0 || btitle.length === 0) {
                              return atitle.length - btitle.length;
                          }
                          for(var i=0;i<atitle.length && i<btitle.length;i++){
                              var ai = alfabe.indexOf(atitle[i]);
                              var bi = alfabe.indexOf(btitle[i]);
                              if (ai !== bi) {
                                  return ai - bi;
                              }
                          }
 }).map((item, index) => {
                          
                          return (
                            
                            <option key={index} value={JSON.stringify(item)}>
                              {item.label}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <button
                      className="list-dropdown-button"
                      onClick={() => {
                        history.push({
                          pathname: "/satis-ve-servisler"
                        });
                        setClickedWherePro('abroad');
                      }}
                      
                    >
                      <>{t("FT-BTN-LIST")}</>
                    </button>

                    <h4 className="widget-title">{t("FT-WC-DDL")}</h4>
                    <div className="denemeselect">
                      <select id="city-select" aria-label="City"  onChange={(event) => {handleSetCity("footer", event.target.value)}} name="one" className="dropdown-select">
                        <option value={t("FOOTER-YI-COUNTRY-1")}>{t("FOOTER-YI-COUNTRY-1")}</option>
                        <option value={t("FOOTER-YI-COUNTRY-2")}>{t("FOOTER-YI-COUNTRY-2")}</option>
                        <option value={t("FOOTER-YI-COUNTRY-3")}>{t("FOOTER-YI-COUNTRY-3")}</option>
                        <option value={t("FOOTER-YI-COUNTRY-4")}>{t("FOOTER-YI-COUNTRY-4")}</option>
                        <option value={t("FOOTER-YI-COUNTRY-5")}>{t("FOOTER-YI-COUNTRY-5")}</option>
                        <option value={t("FOOTER-YI-COUNTRY-6")}>{t("FOOTER-YI-COUNTRY-6")}</option>
                        <option value={t("FOOTER-YI-COUNTRY-7")}>{t("FOOTER-YI-COUNTRY-7")}</option>
                        <option value={t("FOOTER-YI-COUNTRY-8")}>{t("FOOTER-YI-COUNTRY-8")}</option>
                        <option value={t("FOOTER-YI-COUNTRY-9")}>{t("FOOTER-YI-COUNTRY-9")}</option>
                        <option value={t("FOOTER-YI-COUNTRY-10")}>{t("FOOTER-YI-COUNTRY-10")}</option>
                        <option value={t("FOOTER-YI-COUNTRY-11")}>{t("FOOTER-YI-COUNTRY-11")}</option>
                        <option value={t("FOOTER-YI-COUNTRY-12")}>{t("FOOTER-YI-COUNTRY-12")}</option>
                        <option value={t("FOOTER-YI-COUNTRY-13")}>{t("FOOTER-YI-COUNTRY-13")}</option>
                        <option value={t("FOOTER-YI-COUNTRY-14")}>{t("FOOTER-YI-COUNTRY-14")}</option>
                        <option value={t("FOOTER-YI-COUNTRY-15")}>{t("FOOTER-YI-COUNTRY-15")}</option>
                        <option value={t("FOOTER-YI-COUNTRY-16")}>{t("FOOTER-YI-COUNTRY-16")}</option>
                        <option value={t("FOOTER-YI-COUNTRY-17")}>{t("FOOTER-YI-COUNTRY-17")}</option>
                      </select>
                    </div>
                    <button
                      className="list-dropdown-button"
                      onClick={() => {
                        history.push({
                          pathname: "/satis-ve-servisler"
                        });
                        handleSet({
                          label: "Türkiye",
                          value: "COUNTRY-TURKEY",
                        });
                        setClickedWherePro('domestic');
                      }}
                    >
                      <>{t("FT-BTN-LIST")}</>
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>

        <div className="footer-kvkk">
          <div className="container">
            <img
              className="footer-img"
              alt="logo-kvkk"
              src="/assets/images/logo/logo-kvkk.webp"
            />
            <ul className="footer-kvkk-list">
              <li>
                {" "}
                <a href="https://www.yr.com.tr/kvkk/kvkk-politika.pdf">{t("FT-KVKK-P")}</a>
              </li>
              <li>
                {" "}
                <a href="https://www.yr.com.tr/kvkk/gizlilik-politikasi.pdf">{t("FT-KVKK-S")}</a>
              </li>
              <li>
                {" "}
                <a href="https://e-sirket.mkk.com.tr/esir/Dashboard.jsp#/sirketbilgileri/13662">{t("FT-KVKK-I")}</a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div
        id="gdpr-cookie-message"
        style={{  }}
        className="hidden"
      >
        <h4> {t("COOKIE-TITLE")}</h4>
        <p>{t("COOKIE-DESCRIPTION")}</p>
        <p>
          <a href="/kvkk/gizlilik-politikasi.pdf">
            {" "}
            {t("COOKIE-BTN-1")}
          </a>
          <button id="gdpr-cookie-accept" type="button">
            {" "}
            {t("COOKIE-BTN-2")}
          </button>
        </p>
      </div>
    </>
  );
}

export default Footer;
