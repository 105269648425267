import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Slider from "react-slick";
import { fetchNewsById, fetchNewsAll } from "../api/agent";
import { useTranslation } from "react-i18next";
import BreadCrumb from "../components/Shared/BreadCrumb/BreadCrumb";
import "./NewsDetail.css";
import "../components/News/News.css";
import NewsLast from "../components/News/NewsItem/NewsLast";
import { useMediaPredicate } from "react-media-hook";
import { Helmet } from "react-helmet";

function NewsDetail() {
  let { slugTitle } = useParams();
  var slugTitleSplit = slugTitle.split("-");
  var id = slugTitleSplit[slugTitleSplit.length - 1];
  const { t } = useTranslation();
  const [news, setNews] = useState([]);
  const isMobile = useMediaPredicate("(max-width: 479px)");

  const [newsDetail, setNewsDetail] = useState();

  var countNews = news.length;

  var settingsMobile = {
    dots: true,
    nextArrow: <NextArrowMobile />,
    prevArrow: <PrevArrowMobile />,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: countNews > 4 ? 4 : countNews,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  function NextArrowMobile(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "none", right: "-10px", zIndex: "9999999" }}
        onClick={onClick}
      />
    );
  }

  function PrevArrowMobile(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "none", left: "-20px", zIndex: "9999999" }}
        onClick={onClick}
      />
    );
  }

  useEffect(() => {
    const getNewsDetail = async () => {
      const newsDetail = await fetchNewsById(id);
      var result = newsDetail.data;
      setNewsDetail(result);
    };
    getNewsDetail();
  }, [id]);

  useEffect(() => {
    const getAllNews = async () => {
      const allNews = await fetchNewsAll();
      var result = allNews.data;
      setNews(result.filter((x) => x.id != id));
    };
    getAllNews();
  }, [id]);

  if (newsDetail != undefined) {
    var newsDetailContent = (
      <>
        {" "}
        <h3 class="news-title"> {t("YR-NEWS-TITLE-"+id)}</h3>
        <div class="news-thumb">
          <img src={t("YR-NEWS-IMG-"+id)} aria-label="Yılmaz Redüktör Haberler" style={{width:'470px'}} />
        </div>
        <div class="news-content">
        <p>
        {t("YR-NEWS-DESC-"+id)}
</p>

          
        </div>
      </>
    );
  }

  return (
    <>
      <Helmet>
        <title>Haberler - YILMAZ REDÜKTÖR San. ve Tic. A.S</title>
        <meta
          name="keywords"
          content="yılmaz reduktor redüktör, reduktor,dişli kutusu,disli kutusu,gearbox,gearunit,getriebe,getriebemotoren"
        />
      
      </Helmet>
      <BreadCrumb location={t("NEWS-P-B")} />
      <div className="secpadd">
        <div className="container">
          <div class="news-container">{newsDetailContent}</div>

          <h2 style={{ marginTop: "30px" }} className="news-header">
            <span>{t("YR-NEWS-OTHER")}</span>
          </h2>
          <div className="container padding-slick-container">
            <div className="row">
              {isMobile ? (
                <>
                  {
                    <Slider {...settingsMobile}>
                    {news.map((data, key) => {
                      return (
                        <NewsLast
                          id={data.id}
                          key={key}
                          title={data.title}
                          image={t("YR-NEWS-IMG-"+slugTitle)}
                          description={data.description}
                        />
                      );
                    })}
                  </Slider>
                  }


 


                </>
              ) : (
                <>
                 {/*
                  <Slider {...settings}>
                    {news.map((data, key) => {
                      return (
                        <NewsLast
                          id={data.id}
                          key={key}
                          title={data.title}
                          image={t("YR-NEWS-IMG-"+slugTitle)}
                          description={data.description}
                        />
                      );
                    })}
                  </Slider>
*/}


                  <div className="row">
        <div className="slick-slider slick-initialized">
            <div className="slick-list">
                <div className="slick-track" style={{ opacity: 1, transform: "translate3d(0px, 0px, 0px)", width: "1120px" }}>
                    <div data-index={0} className="slick-slide slick-active slick-current" tabIndex={-1} aria-hidden="false" style={{ outline: "none", width: "280px" }}>
                        <div>
                            <a href="/haberler/p37-r37-planet-tip-reduktorler-11">
                                <div className="padding-slick">
                                    <img alt="P37 / R37 Planet Tip Redüktörler" src="https://www.yr.com.tr/images/News/02a80b69-8374-4384-851f-f879a5402807.png" />
                                    <span>{t("YR-NEWS-TITLE-11")}</span>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div data-index={1} className="slick-slide slick-active" tabIndex={-1} aria-hidden="false" style={{ outline: "none", width: "280px" }}>
                        <div>
                            <a href="/haberler/pvb29-kule-cevirme-reduktorleri-10">
                                <div className="padding-slick">
                                    <img alt="PVB29 Kule Çevirme Redüktörleri" src="https://www.yr.com.tr/images/News/a2c35f26-a7c0-47a6-9302-c5eef5f60526.png" />
                                    <span>{t("YR-NEWS-TITLE-10")}</span>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div data-index={2} className="slick-slide slick-active" tabIndex={-1} aria-hidden="false" style={{ outline: "none", width: "280px" }}>
                        <div>
                            <a href="/haberler/hte1622-ekstruder-reduktorler-7">
                                <div className="padding-slick">
                                    <img alt="HTE1622 Ekstrüder Redüktörler" src="https://www.yr.com.tr/images/News/dc7ea8ff-0235-4d8c-a0ee-3eb1f9e64f86.png" />
                                    <span>{t("YR-NEWS-TITLE-7")}</span>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div data-index={3} className="slick-slide slick-active" tabIndex={-1} aria-hidden="false" style={{ outline: "none", width: "280px" }}>
                        <div>
                            <a href="/haberler/ht1722-agir-hizmet-tipi-reduktorler-5">
                                <div className="padding-slick">
                                    <img alt="HT1722 Ağır Hizmet Tipi Redüktörler" src="https://www.yr.com.tr/images/News/194b177a-c525-4dd5-b7fe-25884e84afc1.png" />
                                    <span>{t("YR-NEWS-TITLE-5")}</span>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default NewsDetail;
