import React from 'react'
import { useHistory } from "react-router-dom";
import { useMediaPredicate } from "react-media-hook";
import { useTranslation } from 'react-i18next';
function Error404() {
    const isMobile = useMediaPredicate("(max-width: 479px)");
    const { t } = useTranslation();
    let history = useHistory();

    function handleClick() {
        history.push("/");
        window.location.reload();
    }

    return (
        <div>
            <img style={{width:'100vw' , height:'100vh'}} src={isMobile ? '/assets/images/Error404/404mobile.png' : '/assets/images/Error404/404.png'} />
            <div style={{position : 'absolute' , top:isMobile ? '80%' : '90%' , width : isMobile ? "100%" : '100%' , left:'50%' , transform: isMobile ? "translate(-50%, -80%)" : "translate(-50%, -90%)",textAlign: 'center'}} >
                <p style={{fontSize:'16px' , color:'white' , transform:'none' , fontWeight:'700' ,textShadow: '3px 3px rgb(0 0 0 / 40%)'}}>{t("ERROR-PAGE-D-1")}</p>
                <p style={{fontSize:'16px' , color:'white' , lineHeight:'0px', transform:'none' , fontWeight:'700' ,textShadow: '3px 3px rgb(0 0 0 / 40%)'}}>{t("ERROR-PAGE-D-2")}</p>
                <button onClick={handleClick} style={{fontWeight:'700' , marginBottom:'2em', padding:'1.5em 7em' ,borderRadius:'3em' , boxShadow: '0 19px 38px rgb(0 0 0 / 30%), 0 15px 12px rgb(0 0 0 / 22%)' , background:'#be057e' , marginTop:'2em'}}>{t("ERROR-PAGE-BTN")}</button>
                <div>
                    <img style={{height:'64px' }}
                        alt="Logo"
                        
                        src= {"/assets/images/logo/logo.webp"} 

                        />
                </div>
            </div>
    </div>
    )
}

export default Error404


/*
        font-weight: 700;
    padding: 1.5em 7em;
    border-radius: 3em;
    box-shadow: 0 19px 38px rgb(0 0 0 / 30%), 0 15px 12px rgb(0 0 0 / 22%);
    background: #be057e;
    margin-top: 2em;

*/