import React from "react";
import BreadCrumb from "../Shared/BreadCrumb/BreadCrumb";
import "./Mission.css";
import { useMediaPredicate } from "react-media-hook";
import { useTranslation } from 'react-i18next';

const Mission = () => {
  const { t } = useTranslation();

  const isMobile = useMediaPredicate("(max-width: 768px)");
  return (
    <>
      <BreadCrumb location={t("G-OUR-MISSION")} />
      <div className="page-Wrapper">
        <div className="page-Title-Wrapper">
          <div className="page-Title">
            <div className="container">
              <h1>{t("G-OUR-MISSION-T")}</h1>
            </div>
          </div>
        </div>

        <div className="page-Content-Wrapper">
          <div className="container">
            <div className="image-list-wrapper">
              <div className="image-list-box right-box">
                <div className="image-list-content image-content">
                  <img
                    alt="Vizyon"
                    className="img-responsive"
                    src={"/assets/images/vizyon-misyon/vizyon.png"}
                    
                  />
                </div>

                <div className="image-list-content text-content">
                  <h2>{t("G-VISION")}</h2>
                  <p>
                    <span>
                      {t("G-VISION-D")}
                    </span>
                    &nbsp;
                  </p>
                </div>
              </div>

              <div className="image-list-box left-box">

                
                <div className="image-list-content image-content">
                  <img
                    alt="Misyon"
                    className="img-responsive"
                    src={"/assets/images/vizyon-misyon/misyon.png"}
                  />
                </div>

                <div className="image-list-content text-content">
                  <h2>{t("G-MISSION")}</h2>

                  <p>
                    <span>
                    {t("G-MISSION-D")}

                    </span>
                  </p>
                </div>
              </div>
            

              <div style={{marginBottom : '80px'}} className="image-list-box right-box">
                <div className="image-list-content image-content">
                  <img
                    alt="Değerlerimiz"
                    className="img-responsive"
                     src={"/assets/images/vizyon-misyon/değerlerimiz.png"}

                  />
                </div>

                <div className="image-list-content text-content">
                  <h2>{t("G-OUR-VALUES")}</h2>
                  <p>
                    <span>
                      {t("G-OUR-VALUES-D")}
                    </span>
                    &nbsp;
                  </p>
                </div>
              </div>
            </div>
            

        

          </div>
        </div>
      </div>
    </>
  );
};

export default Mission;
