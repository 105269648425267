const NavbarData = [
	{
		navbarId:1,
		name: 'NB-PR',
		path: '/urunler'
	},
	{
		navbarId:2,
		name: 'NB-SL',
		path: '/cozumler'
	},
	{
		navbarId:3,
		name: 'NB-SS',
		path: '/satis-ve-servisler'
	},
	{
		navbarId:4,
        name: 'NB-DC',
        path:'/dokuman'
	},
	{
		navbarId:5,
        name: 'NB-NP',
        path:'/haberler'
	},
	{
		navbarId:6,
        name: 'NB-CP',
        path:'/tarihce'
	},
	{
		navbarId:8,
        name: 'NEWS-P-V-T',
        path:'/videolar'
	},
	{
		navbarId:7,
        name: 'NB-CT',
        path:'/musteri-hizmetleri'
	}
];
export default NavbarData;
