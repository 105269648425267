import React from "react";
import GST from "../components/GST/GST";
import { Helmet } from "react-helmet";

function Gst() {
  return (
    <>
      <Helmet>
        <title>Redüktör Seçim - YILMAZ REDÜKTÖR San. ve Tic. A.S</title>
        <link rel="canonical" href="https://yr.com.tr/reduktor-secim" />
        <meta
          name="keywords"
          content="yılmaz reduktor redüktör, reduktor,dişli kutusu,disli kutusu,gearbox,gearunit,getriebe,getriebemotoren"
        />
       
      </Helmet>
      <GST />
    </>
  );
}

export default Gst;
