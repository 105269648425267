import React from "react";
import { Link } from "react-router-dom";
import "../Box.css";
function BoxItem(props) {

  return (
      
      <Link to={props.path} className="box">

        <div to="/" className="box-icon-container box-icon ">
        <img alt={props.header} src={props.icon} className={props.icon} style={{color:"white"}} aria-hidden="true" tabindex="-1"/>
        </div>
        <div to="/" className="box-text-container">
          <div className="box-textPrimary">{props.header}</div>
          <div className="box-textSecondary">{props.text}</div>
        </div>
      </Link>
      
    
  );
}

export default BoxItem;
