import React from "react";
import Iframe from "react-iframe";
import BreadCrumb from "../Shared/BreadCrumb/BreadCrumb";
import { useTranslation } from "react-i18next";
import "./gst.css";
function GST() {
  const { t } = useTranslation();
  

  console.log("local", localStorage.getItem("language"));
  

  return (
    <>
      <BreadCrumb location={t("REDUKTOR-SECIM-BREAD")} />

      <div style={{ background: "#e8e8e8" }}>
        {localStorage.getItem("language") === "tr-TR"
          ?  <Iframe
          url="https://test.yr.com.tr/yrgst/WEB/gst/gstsecim.html"
          width="100%"
          height="800px"
          id="myId"
          className="gstFrame"
          display="initial"
          position="relative"
        />
          : localStorage.getItem("language") === "de-DE"
          ?  <Iframe
          url="https://test.yr.com.tr/yrgst/WEB/gst/gstsecimde.html"
          width="100%"
          height="800px"
          id="myId"
          className="gstFrame"
          display="initial"
          position="relative"
        />
        : localStorage.getItem("language") === "fr-FR"
          ?  <Iframe
          url="https://test.yr.com.tr/yrgst/WEB/gst/gstsecimfr.html"
          width="100%"
          height="800px"
          id="myId"
          className="gstFrame"
          display="initial"
          position="relative"
        />
          : localStorage.getItem("language") === "en-US"
          ?  <Iframe
          url="https://test.yr.com.tr/yrgst/WEB/gst/gstsecimen.html"
          width="100%"
          height="800px"
          id="myId"
          className="gstFrame"
          display="initial"
          position="relative"
        />
          : ""}

       
      </div>
    </>
  );
}

export default GST;
