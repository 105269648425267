const BoxData = [
  {
    header: "HP-BX-PR-T",
    text: "HP-BX-PR-D",
    path: "/urunler",
    icon: "assets/images/icons/Ürünler.png",
  },
  {
    header: "HP-BX-QP-T",
    text: "HP-BX-QP-D",
    path: "/hizli-resim",
    icon: "assets/images/icons/hızlı resim.png",
  },
  {
    header: "HP-BX-GST-T",
    text: "HP-BX-GST-D",
    path: "/reduktor-secim",
    icon: "assets/images/icons/gst.png",
  },
  {
    header: "HP-BX-QD-T",
    text: "HP-BX-QD-D",
    path: "/dokuman",
    icon: "assets/images/icons/d.png",
  },
  {
    header: "HP-BX-PCC-T",
    text: "HP-BX-PCC-D",
    path: "/uygulamalar",
    icon: "assets/images/icons/gd.png",
  }
];
export default BoxData;
