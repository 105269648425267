const DealersCityPoint = [
 

    
    {
        id : 1,
        pos : { lat: parseFloat('41.0495118'), lng: parseFloat('29.0395684') },
        city : "İstanbul",
        zoom: 10

    },
    {
        id : 2,
        pos : { lat: parseFloat('39.9263335'), lng: parseFloat('32.7198448') },
        city : "Ankara",
        zoom: 11

    },
    {
        id : 3,
        pos : { lat: parseFloat('40.209059'), lng: parseFloat('28.936797') },
        city : "Bursa",
        zoom: 17

    },
    {
        id : 4,
        pos : { lat: parseFloat('37.9208105'), lng: parseFloat('32.5416898') },
        city : "Konya",
        zoom: 15

    },
    {
        id : 5,
        pos : { lat: parseFloat('40.737772'), lng: parseFloat('30.399846') },
        city : "Sakarya",
        zoom: 16

    },
    {
        id : 6,
        pos : { lat: parseFloat('38.4313821'), lng: parseFloat('27.1839368') },
        city : "İzmir",
        zoom: 15

    },
    {
        id : 7,
        pos : { lat: parseFloat('40.521146'), lng: parseFloat('34.940412') },
        city : "Çorum",
        zoom: 15

    },
    {
        id : 8,
        pos : { lat: parseFloat('37.789294'), lng: parseFloat('29.085147') },
        city : "Denizli",
        zoom: 17

    },
    {
        id : 9,
        pos : { lat: parseFloat('39.776732'), lng: parseFloat('30.526702') },
        city : "Eskişehir",
        zoom: 16

    },
    {
        id : 10,
        pos : { lat: parseFloat('40.7819757'), lng: parseFloat('29.7172572') },
        city : "Kocaeli",
        zoom: 11

    },
    {
        id : 11,
        pos : { lat: parseFloat('38.738724'), lng: parseFloat('35.484621') },
        city : "Kayseri",
        zoom: 15

    },
    {
        id : 12,
        pos : { lat: parseFloat('37.0670805'), lng: parseFloat('37.3698896') },
        city : "Gaziantep",
        zoom: 15

    },
    {
        id : 13,
        pos : { lat: parseFloat('41.026179'), lng: parseFloat('40.51675') },
        city : "Rize",
        zoom: 16

    },
    {
        id : 14,
        pos : { lat: parseFloat('36.9860594'), lng: parseFloat('35.328084') },
        city : "Adana",
        zoom: 19

    },
    {
        id : 15,
        pos : { lat: parseFloat('36.201404'), lng: parseFloat('36.173728') },
        city : "Hatay",
        zoom: 15

    },
    {
        id : 16,
        pos : { lat: parseFloat('37.923626'), lng: parseFloat('40.220164') },
        city : "Diyarbakır",
        zoom: 15

    },
    {
        id : 17,
        pos : { lat: parseFloat('41.0684843'), lng: parseFloat('27.7956203') },
        city : "Tekirdağ",
        zoom: 14

    },
    {
        id : 18,
        pos : { lat: parseFloat('52.797793'), lng: parseFloat('7.896503') },
        city : "Lastrup",
        zoom: 15,
       

    },
    {
        id : 19,
        pos : { lat: parseFloat('52.899645'), lng: parseFloat('8.191471') },
        city : "Ahlhorn",
        zoom: 15,
        

    },
    {
        id : 20,
        pos : { lat: parseFloat('51.280730'), lng: parseFloat('6.616022') },
        city : "Leichlingen",
        zoom: 16,
       

    },
    {
        id : 21,
        pos : { lat: parseFloat('42.2'), lng: parseFloat('24.8') },
        city : "Plovdiv",
        zoom: 15

    },
    {
        id : 22,
        pos : { lat: parseFloat('45.801657'), lng: parseFloat('15.934797') },
        city : "Zagreb",
        zoom: 15

    },
    {
        id : 23,
        pos : { lat: parseFloat('46.067518'), lng: parseFloat('6.412048') },
        city : "Bonneville",
        zoom: 15

    },
    {
        id : 24,
        pos : { lat: parseFloat('48.8736801'), lng: parseFloat('2.7217707') },
        city : "Lagny-sur-Marne",
        zoom: 15

    },
    {
        id : 25,
        pos : { lat: parseFloat('37.949449'), lng: parseFloat('23.648709') },
        city : "Piraeus",
        zoom: 15

    },
    {
        id : 26,
        pos : { lat: parseFloat('37.523376'), lng: parseFloat('126.766795') },
        city : "Seul",
        zoom: 15

    },
    {
        id : 27,
        pos : { lat: parseFloat('54.693089'), lng: parseFloat('25.282566') },
        city : "Vilnius",
        zoom: 15

    },
    {
        id : 28,
        pos : { lat: parseFloat('56.668199'), lng: parseFloat('16.339598') },
        city : "Kalmar",
        zoom: 15

    },
    {
        id : 29,
        pos : { lat: parseFloat('33.332485'), lng: parseFloat('44.402479') },
        city : "Bağdat",
        zoom: 15

    },
    {
        id : 30,
        pos : { lat: parseFloat('36.155722'), lng: parseFloat('44.00391') },
        city : "Erbil",
        zoom: 15

    },
    {
        id : 31,
        pos : { lat: parseFloat('49.393679'), lng: parseFloat('32.055389') },
        city : "Cherkasi",
        zoom: 15

    },
    {
        id : 32,
        pos : { lat: parseFloat('53.699584'), lng: parseFloat('-1.608211') },
        city : "Leeds",
        zoom: 15

    },
    {
        id : 33,
        pos : { lat: parseFloat('42.187614'), lng: parseFloat('2.799719') },
        city : "Girona",
        zoom: 15

    },
    {
        id : 34,
        pos : { lat: parseFloat('51.2646962'), lng: parseFloat('5.5816695') },
        city : "Budel",
        zoom: 15

    },
    {
        id : 35,
        pos : { lat: parseFloat('29.949284'), lng: parseFloat('30.909142') },
        city : "Cairo",
        zoom: 15

    },
    {
        id : 36,
        pos : { lat: parseFloat('47.195631'), lng: parseFloat('27.410631') },
        city : "Iasi-Letcani",
        zoom: 15

    },
    {
        id : 37,
        pos : { lat: parseFloat('32.909881'), lng: parseFloat('35.182586') },
        city : "Ahihud",
        zoom: 15

    },
    {
        id : 38,
        pos : { lat: parseFloat('13.5584492'), lng: parseFloat('100.9359392') },
        city : "Chachoengsao",
        zoom: 15

    },
    {
        id : 39,
        pos : { lat: parseFloat('49.7736271'), lng: parseFloat('16.9305727') },
        city : "Mohelnice",
        zoom: 15

    },
    {
        id : 40,
        pos : { lat: parseFloat('49.7736271'), lng: parseFloat('16.9305727') },
        city : "Mohelnice",
        zoom: 15

    },
    {
        id : 41,
        pos : { lat: parseFloat('43.238987'), lng: parseFloat('76.9458306') },
        city : "Almaty",
        zoom: 15

    },
    {
        id : 42,
        pos : { lat: parseFloat('49.8241195'), lng: parseFloat('73.0288872') },
        city : "Karaganda",
        zoom: 15

    },
    {
        id : 43,
        pos : { lat: parseFloat('41.2362578'), lng: parseFloat('69.2102007') },
        city : "Tashkent",
        zoom: 11

    },
    {
        id : 44,
        pos : { lat: parseFloat('59.9485967'), lng: parseFloat('30.376892') },
        city : "Saint-Petersburg",
        zoom: 15

    },
    {
        id : 45,
        pos : { lat: parseFloat('55.8636254'), lng: parseFloat('37.5517158') },
        city : "Moskova",
        zoom: 15

    },
    {
        id : 46,
        pos : { lat: parseFloat('55.8149179'), lng: parseFloat('37.3238622') },
        city : "Krasnogorsk",
        zoom: 15

    },
    {
        id : 47,
        pos : { lat: parseFloat('40.7493001'), lng: parseFloat('30.4065346') },
        city : "Sakarya",
        zoom: 15

    },
    {
        id : 47,
        pos : { lat: parseFloat('55.69318796103757'), lng: parseFloat('52.34633101086929') },
        city : "Tatarstan",
        zoom: 15

    },
    {
        id : 48,
        pos : { lat: parseFloat('35.6913293'), lng: parseFloat('51.4245318') },
        city : "Tehran",
        zoom: 15

    }



    

  
  

  
  ];
  export default DealersCityPoint;
  