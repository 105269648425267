import { useState } from "react";
import axios from "axios";
import xml2js from "xml2js";
import { useTranslation } from "react-i18next";
import BreadCrumb from "../components/Shared/BreadCrumb/BreadCrumb";
import "../components/SeriNoSorgula/SeriNoSorgula.css"; // CSS dosyasını import ediyoruz
import { Helmet } from "react-helmet";

function SeriNoSorgula() {
  const [serialNumber, setSerialNumber] = useState("");
  const [items, setItems] = useState([]);
  const [configItems, setConfigItems] = useState([]);
  const { t } = useTranslation();

  const renderItems = items.map((item, i) => (
    <tr key={i}>
      <td>{item.shop_order}</td>
      <td>{item.rel_no}</td>
      <td>{item.seq_no}</td>
      <td>{item.part_no}</td>
      <td>{item.description}</td>
      <td>{item.notes}</td>
    </tr>
  ));

  const renderConfigItems = configItems.map((item, i) => (
    <tr key={i}>
      <td>{item.characteristic_id}</td>
      <td>{item.config_characteristic}</td>
      <td>{item.config_description}</td>
    </tr>
  ));

  const handleButtonClick = () => {
    console.log("Seri Numarası:", serialNumber);
    setConfigItems([]); 
    fetchData(serialNumber);
    fetchData2();
  };

  const fetchData = (serialNumber) => {
    let xml = `
      <soap:Envelope xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/">
        <soap:Body>
          <ReduktorSerialQuery xmlns="http://www.yr.com.tr/">
            <param1>${serialNumber}</param1>
            <Info>Fh+42!+Dgr!</Info>
          </ReduktorSerialQuery>
        </soap:Body>
      </soap:Envelope>`;

    axios
      .post("https://wsdl.yr.com.tr/yilmazwebservisleri.asmx", xml, {
        headers: {
          "Content-Type": "text/xml",
        },
      })
      .then((response) => {
        xml2js.parseString(response.data, (err, result) => {
          if (err) {
            console.error("XML parsing error:", err);
            return;
          }

          try {
            const customers = result["soap:Envelope"]["soap:Body"][0]["ReduktorSerialQueryResponse"][0]["ReduktorSerialQueryResult"][0]["diffgr:diffgram"][0]["DocumentElement"][0]["Customersx"];
            
            const parsedItems = customers.map((customer) => ({
              shop_order: customer["SHOP_ORDER"][0],
              rel_no: customer["REL_NO"][0],
              seq_no: customer["SEQ_NO"][0],
              part_no: customer["PART_NO"][0],
              description: customer["DESCRIPTION"][0],
              notes: customer["NOTES"] ? customer["NOTES"][0] : "",
            }));

            setItems(parsedItems);

            // İkinci SOAP isteğini burada yapıyoruz
            if (parsedItems.length > 0) {
              const { shop_order, rel_no, seq_no } = parsedItems[0];
              fetchData2(shop_order, rel_no, seq_no);
            }
          } catch (error) {
            console.error("Error processing data:", error);
          }
        });
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const fetchData2 = (shop_order, rel_no, seq_no) => {
    let xml = `
      <soap:Envelope xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/">
        <soap:Body>
          <ReduktorConSerialQuery xmlns="http://www.yr.com.tr/">
            <param1>${shop_order}</param1>
            <param2>${rel_no}</param2>
            <param3>${seq_no}</param3>
            <Info>cG26+bW3!r!1</Info>
          </ReduktorConSerialQuery>
        </soap:Body>
      </soap:Envelope>`;

    axios
      .post("https://wsdl.yr.com.tr/yilmazwebservisleri.asmx", xml, {
        headers: {
          "Content-Type": "text/xml",
        },
      })
      .then((response) => {
        xml2js.parseString(response.data, (err, result) => {
          if (err) {
            console.error("XML parsing error:", err);
            return;
          }

          try {
            const configCustomers = result["soap:Envelope"]["soap:Body"][0]["ReduktorConSerialQueryResponse"][0]["ReduktorConSerialQueryResult"][0]["diffgr:diffgram"][0]["DocumentElement"][0]["Customersx"];
            
            const parsedConfigItems = configCustomers.map((customer) => ({
              characteristic_id: customer["CHARACTERISTIC_ID"][0],
              config_characteristic: customer["CONFIG_CHARACTERISTIC"][0],
              config_description: customer["CONFIG_DESCRIPTION"][0],
            }));

            setConfigItems(parsedConfigItems);
          } catch (error) {
            console.error("Error processing config data:", error);
          }
        });
      })
      .catch((error) => {
        console.error("Error fetching config data:", error);
      });
  };

  return (
    <div>
      <Helmet>
        <title>YILMAZ REDÜKTÖR San. ve Tic. A.S</title>
        <meta
          name="keywords"
          content="yılmaz reduktor redüktör, reduktor,dişli kutusu,disli kutusu,gearbox,gearunit,getriebe,getriebemotoren"
        />
      </Helmet>
      <BreadCrumb location="Seri Numarası Sorgulama" />
      <div className="secpadd">
        <div className="services-choose-button-container centered">
          <input
            type="text"
            placeholder="Seri Numarasını Girin"
            value={serialNumber}
            onChange={(event) => {
              setSerialNumber(event.target.value);
            }}
            className="serial-input"
          />
          <button onClick={handleButtonClick} className="search-button">
            Sorgula
          </button>
        </div>
        <div className="row">
          <div className="col-12" style={{ marginLeft: "200px" }}>
            {items.length} {t("BS-T-KB")}
          </div>
        </div>
        <table className="result-table">
          <thead>
            <tr>
              <th>SHOP_ORDER</th>
              <th>REL_NO</th>
              <th>SEQ_NO</th>
              <th>PART_NO</th>
              <th>DESCRIPTION</th>
              <th>NOTES</th>
            </tr>
          </thead>
          <tbody>{renderItems}</tbody>
        </table>
        <table className="result-table table-spacing">
          <thead>
            <tr>
              <th>CHARACTERISTIC_ID</th>
              <th>CONFIG_CHARACTERISTIC</th>
              <th>CONFIG_DESCRIPTION</th>
            </tr>
          </thead>
          <tbody>{renderConfigItems}</tbody>
        </table>
      </div>
    </div>
  );
}

export default SeriNoSorgula;
