import React from "react";
import History from "../components/Company/History";
import { Helmet } from "react-helmet";

function Company() {
  return (
    <>
      <Helmet>
        <title>Tarihçe - YILMAZ REDÜKTÖR San. ve Tic. A.S</title>
        <link rel="canonical" href="https://yr.com.tr/tarihce" />
        <meta
          name="keywords"
          content="yılmaz reduktor redüktör, reduktor,dişli kutusu,disli kutusu,gearbox,gearunit,getriebe,getriebemotoren"
        />
       
      </Helmet>
      <History />
    </>
  );
}

export default Company;
