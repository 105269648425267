import React from "react";
import PartnerItem from "./PartnerItem/PartnerItem"
import OwlCarousel from "react-owl-carousel";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import PartnerData from "./PartnerData";
import { useMediaPredicate } from "react-media-hook";
import { useTranslation } from 'react-i18next';
function Partner() {
  const isMobile = useMediaPredicate("(max-width: 479px)"); // boyutlar düzenlenebilir
  const { t } = useTranslation();
  return (
    <div className="partener2 partner-dark">
      <div className="container">
        <span
          style={{
            textAlign: "center",
            display: "block",
            fontSize: "30px",
            color: "white",
            marginBottom: "30px",
            textTransform: "capitalize",
            fontWeight: "700"
          }}
        >
          {t("HP-GC-T")}
        </span>
        <div className="fh-partner clearfix">
          {isMobile && (
            <OwlCarousel
              items={1}
              margin={0}
              nav={false}
              dots={true}
              autoplay={true}
              autoplayTimeout={2000}
              loop={true}
            >
              {PartnerData.map((data, key) => {
                return (
                  <PartnerItem
                    key={key}
                    name={data.name}
                    image={data.image}
                    hoverImage={data.hoverImage}
                    path={data.path}
                  />
                );
              })}
            </OwlCarousel>
          )}
          {!isMobile &&
            PartnerData.map((data, key) => {
              return (
                <PartnerItem
                  key={key}
                  name={data.name}
                  image={data.image}
                  path={data.path}
                  hoverImage={data.hoverImage}

                />
              );
            })}
        </div>
      </div>
    </div>
  );
}

export default Partner;
