import React from 'react'
import Iframe from 'react-iframe'
import BreadCrumb from '../Shared/BreadCrumb/BreadCrumb'
import { useTranslation } from 'react-i18next';
import './quickPicture.css'
function QuickPictureComponent() {
  const { t } = useTranslation();

    return (
        <>
        <BreadCrumb location={t('HIZLI-RESIM-BREAD')} />
        <div  style={{background : '#e8e8e8'}}>


{localStorage.getItem("language") === "tr-TR"
          ?  <Iframe
          url="https://test.yr.com.tr/yrgst/WEB/TechnicalPicture.aspx?lang=tr"
          width="100%"
          height="800px"
          id="myId"
          className="gstFrame"
          display="initial"
          position="relative"
        />
          : localStorage.getItem("language") === "de-DE"
          ?  <Iframe
          url="https://test.yr.com.tr/yrgst/WEB/TechnicalPicture.aspx?lang=de"
          width="100%"
          height="800px"
          id="myId"
          className="gstFrame"
          display="initial"
          position="relative"
          />
          : localStorage.getItem("language") === "fr-FR"
          ?  <Iframe
          url="https://test.yr.com.tr/yrgst/WEB/TechnicalPictureFR.aspx?lang=fr"
          width="100%"
          height="800px"
          id="myId"
          className="gstFrame"
          display="initial"
          position="relative"
        
        />
          : localStorage.getItem("language") === "en-US"
          ?  <Iframe
          url="https://test.yr.com.tr/yrgst/WEB/TechnicalPicture.aspx?lang=en"
          width="100%"
          height="800px"
          id="myId"
          className="gstFrame"
          display="initial"
          position="relative"
        />
          : ""}


   
      </div>
       
        </>
    )
}

export default QuickPictureComponent
