
  const navbarCategories = [
    {
      menuId:101,
      categoryId: 201,
      categoryFirstItem: 1,
      categoryName: "NB-PR-MN-SR-SUB-1",
    },
    {
      menuId: 101,
      categoryId: 202,
      categoryFirstItem: 2,
      categoryName: "NB-PR-MN-SR-SUB-2",
    },
    {
      menuId: 101,
      categoryId: 203,
      categoryFirstItem: 3,
      categoryName: "NB-PR-MN-SR-SUB-3",
    },
    {
      menuId: 101,
      categoryId: 204,
      categoryFirstItem: 4,
      categoryName: "NB-PR-MN-SR-SUB-4",
    },
    {
      menuId: 101,
      categoryId: 205,
      categoryFirstItem: 5,
      categoryName: "NB-PR-MN-SR-SUB-5",
    },
/////////
    
    {
      menuId: 102,
      categoryId: 301,
      categoryFirstItem: 101,
      categoryName: "NB-PR-MN-HVY-SUB-1",
    },
    {
      menuId: 102,
      categoryId: 302,
      categoryFirstItem: 103,
      categoryName: "NB-PR-MN-HVY-SUB-2",
    },
    {
      menuId: 102,
      categoryId: 303,
      categoryFirstItem: 105,
      categoryName: "NB-PR-MN-HVY-SUB-3",
    },
    {
      menuId: 102,
      categoryId: 304,
      categoryFirstItem: 106,
      categoryName: "NB-PR-MN-HVY-SUB-4",
    },
   

    {
      menuId: 103,
      categoryId: 401,
      categoryFirstItem: 201,
      categoryName: "NB-PR-MN-SEC-SUB-1",
    },
    {
      menuId: 103,
      categoryId: 402,
      categoryFirstItem:202 ,
      categoryName: "NB-PR-MN-SEC-SUB-2",
    },{
      menuId: 103,
      categoryId: 403,
      categoryFirstItem: 205,
      categoryName:  "NB-PR-MN-SEC-SUB-3",
    },
    {
      menuId: 103,
      categoryId: 404,
      categoryFirstItem: 210,
      categoryName: "NB-PR-MN-SEC-SUB-4",
    },
  

    {
      menuId: 103,
      categoryId: 405,
      categoryFirstItem: 212,
      categoryName: "NB-PR-MN-SEC-SUB-5",
    },
    {
      menuId: 103,
      categoryId: 406,
      categoryFirstItem: 213,
      categoryName: "NB-PR-MN-SEC-SUB-6",
    },


    {
      menuId: 103,
      categoryId: 407,
      categoryFirstItem: 216,
      categoryName: "NB-PR-MN-SEC-SUB-7",
    },

    

    {
      menuId: 103,
      categoryId: 408,
      categoryFirstItem: 218,
      categoryName: "NB-PR-MN-SEC-SUB-8",
    },


    {
      menuId: 104,
      categoryId: 501,
      categoryFirstItem: 301,
      categoryName: "NB-PR-MN-AUT-SUB-1",
    },
/*
    {
      menuId: 104,
      categoryId: 502,
      categoryFirstItem: 306,
      categoryName: "NB-PR-MN-AUT-SUB-2",
    },*/
    {
      menuId : 105,
      categoryId : 601,
      categoryFirstItem: 401,
      categoryName:"ELK-MOTOR-ELECTRIC"


    }

    
  
  ];
  export default navbarCategories;